<template>
    <AddReferral />
</template>
<script>
import AddReferral from "../../components/AddReferral";
export default {
    components: {
        AddReferral
    }
}

</script>
