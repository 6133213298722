<template>
        <Modal :class="{show: modalVisible}" class="new-object__modal"
        @clickBtn="$emit('hideModal')">
          <template v-slot:header>
                <h5 class="new-object__modal-title">Підключити новий об'єкт</h5>
            </template>
              <template v-slot:body>
            <form class="new-object__modal-form" :class="{'new-object__modal-form--mobile': onMobile}">
                <label class="new-object__modal-label">
                    Ваше Ім'я  
                    <span class="new-object__modal-name">
                    <input type="text" class="new-object__modal-input" 
                    placeholder="Ім'я"
                    :value="leadInfo.name" 
                    readonly>
                    <i class="fas fa-check"></i>
                    </span>
                </label>
                <label class="new-object__modal-label">
                    Ваш номер телефону  
                    <span class="new-object__modal-phone">
                    <input type="text" class="new-object__modal-input" 
                    placeholder="номер телефону"
                    :value="leadInfo.phone" 
                    readonly>
                    <i class="fas fa-check"></i>
                    </span>
                </label>
                <label class="new-object__modal-label">
                  Місто
                <select class="new-object__modal-select"
                v-model="leadInfo.city">
                    <option disabled>Оберіть місто</option>
                    <option v-for="city in cities" :key="city" :value="city">{{city}}</option>
                </select>
                </label>
                <label class="new-object__modal-label">
                    Тип власності
                <select class="new-object__modal-select"
                v-model="leadInfo.objectType">
                    <option disabled>Оберіть тип власності</option>
                    <option v-for="object in objects" :key="object" :value="object"
                    >{{object}}</option>
                </select>
                </label>
                </form> 
                <Button class="new-object__modal-btn" @clickBtn="$emit('connectNewObject')"
                >Підключити</Button>
        </template>
        
        </Modal>
</template>
<script>
import Modal from "../Modals/DefaultModal.vue"
import Button from "../Buttons/YellowButton.vue";
export default{
    props: ["modalVisible", "leadInfo", "cities", "objects"],
    components: { Modal, Button}
}


</script>
<style scoped lang="scss">
.new-object {
     &__modal {
        &-form {
            display: grid;
            grid-template: repeat(2, 1fr) / repeat(2, 1fr);
            gap: 20px 20px;
            margin-bottom: 20px;
        &--mobile {
            grid-template: 1fr / 1fr;
        }
        }
        &-title {
            font-size: 20px;
            margin: 0;
        }
        &-input, &-select {
            display: block;
            width: 100%;
            padding: 10px;
            font-size: 14px;
            border-radius: 6px;
            background-color: rgb(242, 242, 242);
            border: none;
            font-family: "GothamPro";
            margin-top: 6px;
        }
         &-input {
           border: 1px solid  #1abd36;
        }
        &-select {
            border: 1px solid rgb(204, 204, 204);
            background-color: #fff;
        }
        &-label {
            // display: block;
            font-size: 13px;
            font-weight: 600;
            position: relative;
            display: inline-block;
            &:after {
                // position: absolute;
                // content: "*";
                // right: -10px;
                // top: 0;
                // color: rgb(255, 59, 48);
            }
        }
            &-name, &-phone {
            position: relative;
            display: block;
            & .fa-check {
                position: absolute;
                top: 35%;
                right: 10px;
                color: #1abd36;
            }
        }
       &-btn {
           border-radius: 100px;
           display: block;
           margin: 0 auto;
       }
    }
}
</style>
