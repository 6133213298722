<template>
	<div class="object__grid" :class="{'object__grid--mobile': onMobile}">
		<div class="object__grid-item" id="controlpanel">
			<span class="value">{{ objectInfo.pult_number }}</span>
			<span class="text">Пультовий номер</span>
			<i class="fas fa-fingerprint"></i>
		</div>
		<div class="object__grid-item" id="contract">
			<span class="value">{{ objectInfo.contract_docnumber }}</span>
			<span class="text">Номер договору</span>
			<i class="fas fa-handshake"></i>
		</div>
		<div class="object__grid-item" id="balance">
			<span class="value">{{ objectInfo.balance }}</span>
			<span class="thin">грн</span>
			<span class="text">Баланс об'єкту</span>
			<i class="fas fa-money-bill-wave-alt"></i>
		</div>
		<div class="object__grid-item" id="equipment">
			<span class="thin">{{ objectInfo.model_ppk }}</span>
			<span class="text">Модель ППК</span>
			<i class="fas fa-tablet-alt"></i>
		</div>
		<div class="object__help">
			<div class="object__help-header header">Служба підтримки</div>
			<div class="object__help-body">
				<img src="../../assets/icons/menu-item/callcenter.png" alt="" class="object__help-img">
			</div>
			<div class="object__help-footer">
				<div class="object__help-btns">
					<a href="https://t.me/SheriffOnline_bot?start=NTU1OTM" class="object__help-link" target="_blank">
						<span class="telegram">Telegram</span>
					</a>
					<a href="viber://pa?chatURI=ua_sheriff_bot" class="object__help-link">
						<span class="viber">Viber</span>
					</a>
				</div>
			</div>
		</div>
		<div class="object__right">
			<div class="object__grid-item" id="security" @click="$emit('techProtection')">
				<span class="value" :class="{ small: small, middle: middle, high: high }">
					{{ objectInfo.protection }}%
				</span>
				<span class="text" :class="{ small: small, middle: middle, high: high }">
					Рівень захищенності
				</span>
				<i class="fas fa-tachometer-alt"></i>
			</div>
			<div v-if="objectInfo.hasSubscription" id="subscriptionInfo" class="object__grid-item object__subscription" @click="$emit('subscriptionStatus')">
				<span class="object__subscription-title">
					Активна підписка
				</span>
				<span class="object__subscription-card">
					<i :class="getIconClasses"></i>
					{{ objectInfo.cardNumber }}
				</span>
				<i style="color: #fce500; opacity: 1;" class="fas fa-credit-card"></i>
			</div>
			<div class="object__payment">
				<div class="object__payment-header header">Абонентська плата</div>
				<div class="object__payment-body">
					<span>
						Розмір абонплати (згідно договору №{{ objectInfo.contract_docnumber }})
					</span>
					<span class="object__payment-value">
						{{ objectInfo.abonplata }}
					</span>
				</div>
				<ul class="object__payment-type" v-if="!objectInfo.hasSubscription">
					<li>
						<Subscription @getSubscription="getSubscription" :loadingCheckout="loadingCheckout" />
					</li>
					<li>
            <SinglePayment @getSinglePayment="getSinglePayment" :loadingSingleCheckout="loadingSingleCheckout" />
          </li>
					<li>
						<Payment />
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import Payment from "../Dashboard/Payment.vue";
	import Subscription from "../Subscription";
	import SinglePayment from "../SinglePayment";

	export default {
		components: {
			Payment, Subscription, SinglePayment
		},
		data() {
			return {
				componentName: "objectinfo",
				small: false,
				middle: false,
				high: false,
				loadingCheckout: false,
				loadingSingleCheckout: false,
			};
		},
		props: ["objectInfo"],
		mounted() {
			this.levelSecurity();
			console.log(new Date())
		},
		computed: {
			getCardName() {
				switch(this.objectInfo.cardNumber[0]) {
					case '4':
						return 'visa';
					case '5':
						return 'mastercard';
					default: 
						return 'default';
				}
			},
			getIconClasses() {
				return {
					'fab fa-cc-visa': this.getCardName === 'visa',
					'fab fa-cc-mastercard': this.getCardName === 'mastercard',
					'far fa-credit-card':	this.getCardName === 'default'
				}
			}
		},
		methods: {
			levelSecurity() {
				if (this.objectInfo.security < 30) {
					return (this.small = true);
				} else if (this.objectInfo.security > 70) {
					return (this.high = true);
				} else {
					return (this.middle = true);
				}
			},
			getSinglePayment() {
        this.loadingSingleCheckout = true;

        this.axios.post(
          `${this.$domain}/contracts/checkout`,
          {
            "contract_id": this.objectInfo.contract_docnumber,
            "type_payment": "single_payment",
						"object_id": this.objectInfo.contract_docnumber,
          },
          {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
          }
        )
        .then(({ data: { result, data: checkoutURL} }) => {
          if (result === "success") {
            this.loadingCheckout = false;
            document.location.assign(checkoutURL);
          }
        });
      },
			getSubscription() {
				this.loadingCheckout = true;

				this.axios.post(
					`${this.$domain}/contracts/checkout`,
					{
						"contract_id": this.objectInfo.contract_docnumber,
						"type_payment": "subscription_payment",
						"object_id": this.objectInfo.contract_docnumber,
					},
					{
						headers: {
							"Authorization": `Bearer ${localStorage.getItem("token")}`
						}
					}
				)
				.then(({data: {result, data: checkoutURL} }) => {
					if (result === 'success') {
						this.loadingCheckout = false;
						document.location.assign(checkoutURL);
					}
				});
			}
		}
	};
</script>

<style scoped lang="scss">
	$dark: rgb(28, 28, 28);

	.header {
		background-color: $dark;
		padding: 15px;
		border-radius: 6px 6px 0 0;
		font-weight: 600;
		text-align: center;
	}

	.object {
		&__grid {
			display: grid;
			grid-template: 88px 1fr / 1fr 1fr 1fr 1fr;
			gap: 15px;
			&--mobile {
				display: flex;
				flex-direction: column;
			}

			&-item {
				padding: 17px 15px;
				border-radius: 6px;
				background-color: rgb(52, 58, 64);
				position: relative;

				& .fas {
					position: absolute;
					right: 15px;
					top: 17px;
					opacity: 0.5;
					font-size: 55px;
				}
				& .value {
					font-size: 28px;
					font-weight: 700;
				}
				& .text {
					display: block;
					font-size: 13px;
					opacity: 0.5;
					margin-top: 5px;
				}
				& .thin {
					font-size: 22px;
					line-height: 32px;
				}
			}
		}
		&__subscription {
			margin-top: 15px;
			cursor: pointer;
			&-title {
				font-size: 16px;
				font-weight: 700;
			}
			&-card {
				display: block;
				opacity: 1;
				font-size: 24px;
				color: #fce500;
			}
		}
		&__right {
			grid-column: 3/5;
		}

		&__help {
			grid-column: 1/3;

			&-img {
				width: 95px;
			}
			&-body {
				padding: 23px 15px;
				text-align: center;
				background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
									url("../../assets/img/card.png");
				background-position: center;
				background-size: cover;
			}
			&-footer {
				background-color: $dark;
				padding: 18px 15px 20px;
				border-radius: 0 0 6px 6px;
			}
			&-btns {
				display: flex;
				justify-content: space-around;
			}
			&-link {
				display: block;
				color: #fff;
				text-decoration: none;
				background-color: #373737;
				border-radius: 10px;
				padding: 12px;
				width: 45%;
				text-align: center;
				font-size: 16px;

				& span {
					position: relative;
					margin-left: -40px;
					line-height: 30px;

					&:after {
						position: absolute;
						content: "";
						background-repeat: no-repeat;
						background-size: cover;
					}
				}
				& .telegram, & .viber {
					@media screen and (max-width: 1280px) {
						font-size: 12px;
					}
				}
				& .telegram {
					&:after {
						background-image: url("../../assets/icons/telegram_logo.png");
						width: 30px;
						height: 30px;
						right: -40px;
					}
				}

				& .viber {
					&:after {
						background-image: url("../../assets/icons/viber_logo.png");
						width: 30px;
						height: 30px;
						right: -50px;
					}
				}
			}
		}

		&__payment {
			margin-top: 15px;
			margin-bottom: 15px;

			&-body {
				background-color: #fff;
				border-radius: 0 0 6px 6px;
				padding: 18px 15px;
				color: #000;
				font-size: 13px;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			&-value {
				margin-left: 10px;
				background-color: #1c1c1c;
				color: #fbe301;
				padding: 10px 12px;
				border-radius: 6px;
			}
			&-type {
				padding: 0;
				list-style-type: none;
			}
		}
	}

	#security, #subscriptionInfo {
		cursor: pointer;
		border: 2px solid transparent;
		transition: all 0.3s ease-in-out;
	}
	#security:hover, #subscriptionInfo:hover {
		box-shadow: 0 0 14px #fce500;
	}
	.small {
		color: red;
	}
	.middle {
		color: #fbe301;
	}
	.high {
		color: green;
	}
</style>