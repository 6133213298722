<template>
<div class="referral-trigger" :class="{'referral-trigger--mobile': onMobile}">
    <h1 class="referral-trigger__title">Реферальна програма від «Шериф»</h1>
    <div class="referral-trigger__descr" :class="{'referral-trigger__descr--mobile': onMobile}">
        <p>Дає можливість не тільки отримати додаткові кошти на власний рахунок, але й допомогти друзям та знайомим подбати про свій спокій та безпеку. Для того, щоб заробити додаткові гроші вам достатньо лише підв’язати власну банківську карту*, на яку у разі підключення нових клієнтів будуть зараховані надходження. Ними ви зможете оплатити наші послуги або перевести на власну банківську карту. Копіюйте реферальне посилання, діліться ним з друзями, родичами або знайомими, у зручний для вас спосіб. За кожне вдале підключення з вашого посилання ви отримуєте 500 грн., а ваш друг 250 грн., якими він може також зможе перекрити частину місячного платежу за наші послуги.</p>
        <p>Більше друзів – більший заробіток. Лише за вісьмох друзів ви вже можете отримати 4 000 грн., а обмежень на підключення немає.</p>
    </div>
    <div class="referral-trigger__inner" :class="{'referral-trigger__inner--mobile': onMobile}">
        <div class="referral-trigger__link"
        :class="{done: done, 'referral-trigger__link--mobile': onMobile}"
        >{{link}}</div>
        <div class="referral-trigger__btns" :class="{'referral-trigger__btns--mobile': onMobile}">
            <YellowButton class="referral-trigger__copy" 
                v-clipboard:success="clipboardSuccessHandler"
                v-clipboard:error="clipboardErrorHandler"
                v-clipboard="link"
                :class="{'done-btn': doneBtn}">
            {{btnValue}}</YellowButton>
            <YellowButton class="referral-trigger__qr"
                @clickBtn="$emit('getQr')">
                <i class="fa fa-qrcode"></i>
                <span v-if="!onMobile">QR-код</span></YellowButton>
        </div>
        <div class="referral-trigger__socials" :class="{'referral-trigger__socials--mobile': onMobile}">
            <a :href="facebookLink" class="referral-trigger__socials-item"><i class="fab fa-facebook"></i></a>
            <a :href="telegramLink" class="referral-trigger__socials-item"><i class="fab fa-telegram"></i></a>
            <a :href="viberLink" class="referral-trigger__socials-item"><i class="fab fa-viber"></i></a>
        </div>
    </div>
    <span v-if="!onMobile" class="referral-trigger__info" title="Для детального ознайомлення з інструкцією натисніть кнопку"
    @click="$emit('visibleInstruction')"><span>Поширені запитання та відповіді
</span><i class="fas fa-exclamation-circle"></i></span>
</div>
    
</template>
<script>
import YellowButton from "../Buttons/YellowButton.vue"
export default {
    data() {
        return {
            userId: "",
            btnValue: "Скопіювати посилання",
            done: false,
            doneBtn: false
        }
    },
    computed: {
        link() { return `${window.origin}/referral/${this.userId}`},
        facebookLink() {
            return `https://www.facebook.com/dialog/share?app_id=302184011346950&display=popup&method=share&&quote=%D0%97%D1%80%D0%BE%D0%B1%D1%96%D1%82%D1%8C%20%D0%BA%D1%80%D0%BE%D0%BA%20%D0%BD%D0%B0%D0%B7%D1%83%D1%81%D1%82%D1%80%D1%96%D1%87%20%D0%B1%D0%B5%D0%B7%D0%BF%D0%B5%D1%86%D1%96%20-%20%D0%BE%D0%B1%D0%B8%D1%80%D0%B0%D0%B9%D1%82%D0%B5%20%D0%A8%D0%B5%D1%80%D0%B8%D1%84!%20%D0%97%D0%B0%D0%BB%D0%B8%D1%88%D0%B0%D0%B9%D1%82%D0%B5%20%D0%B7%D0%B0%D1%8F%D0%B2%D0%BA%D1%83%20%D1%96%20%D0%B3%D0%B0%D1%80%D0%B0%D0%BD%D1%82%D0%BE%D0%B2%D0%B0%D0%BD%D0%BE%20%D0%BE%D1%82%D1%80%D0%B8%D0%BC%D0%B0%D0%B9%D1%82%D0%B5%20%D0%B2%D0%B8%D0%BF%D0%BB%D0%B0%D1%82%D1%83%20250%20%D0%B3%D1%80%D0%BD%20%D0%BF%D1%80%D0%B8%20%D0%BF%D1%96%D0%B4%D0%BA%D0%BB%D1%8E%D1%87%D0%B5%D0%BD%D0%BD%D1%96%20%D0%B4%D0%BE%20%D0%BE%D1%85%D0%BE%D1%80%D0%BE%D0%BD%D0%B8&caption=${window.origin}/referral/${this.userId}&href=${window.origin}/referral/${this.userId}&redirect_uri=${window.origin}/referral/${this.userId}`
        },
        telegramLink() { return `https://telegram.me/share/url?url=${window.origin}/referral/${this.userId}&amp;text=Зробіть крок назустріч безпеці - обирайте Шериф! Залишайте заявку і гарантовано отримайте виплату 250 грн при підключенні до охорони`},
        viberLink() { return `viber://forward?text=${window.origin}/referral/${this.userId}. Зробіть крок назустріч безпеці - обирайте Шериф! Залишайте заявку і гарантовано отримайте виплату 250 грн при підключенні до охорони.`},
    },
    methods: {
        clipboardSuccessHandler() {
            this.done = this.doneBtn = true;
            this.btnValue = "Готово";
        },
        clipboardErrorHandler(data) {
            console.log("error", data)
        }
    },
    created() {
        this.userId = JSON.parse(localStorage.getItem("user")).crm_id;
    },
    components: {YellowButton}
}
</script>

<style scoped lang="scss">
.referral-trigger {
    padding: 40px;
    background-color: rgba(68, 67, 67, 0.65);
    border-radius: 10px;
    margin-bottom: 15px;
    position: relative;
    &--mobile {
        padding: 16px 0;
    }
    &__title{
        margin: 0 0 15px;
        text-align: center;
    }
    &__descr{
        margin-bottom: 25px;
        padding-left: 35px;
        padding-right: 35px;
        &--mobile {
            padding: 0 10px;
        }
        & p {
            margin: 0;
            font-size: 13px;
            line-height: 17px;
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
    &__inner {
        display: grid;
        grid-template: 1fr / 2fr auto 0.7fr;
        column-gap: 10px;
        align-items: center;
        &--mobile {
            display: flex;
            flex-direction: column;
        }
    }
    &__link {
        padding: 10px;
        background-color: #1a1a1a;
        color: #fff;
        border-radius: 6px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        font-size: 20px;
        border: 1px solid transparent;
        &--mobile {
            margin-bottom: 16px;
            width: 200px;
            font-size: 10px;
            overflow-x: scroll;
        }
    }
    &__btns {
        display: flex;
        &--mobile {
            margin-bottom: 16px;
        }
    }
    &__copy {
        border-radius: 6px;
        margin-right: 5px;
        min-width: auto;
    }
    &__qr {
        font-weight: 400;
        border-radius: 6px;
        padding-left: 10px;
        padding-right: 10px;
        min-width: auto;

    }
    &__socials {
        display: flex;justify-content: space-around;
        align-items: center;
        &--mobile {
            width: 200px;
        }
        &-item {
            transition: all 0.5s ease;
            & .fab {
               font-size: 39px; 
            }
            & .fa-facebook {
                color: #2965db;
            }
            & .fa-telegram {
                color: #00adfc;
            }
            & .fa-viber {
                color: #833ef5;
            }
            &:hover {
                transform: translateY(-2px);
                transition: all 0.5s ease;
            }  
        }
        }
    &__info {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        color: #fae615;
        & i {
            margin-left: 5px;
        }
    }
}
.done {
    border-color: rgb(81, 150, 28);
}
.done-btn, .done-btn:hover {
    background-color: rgb(81, 150, 28);
    color: #fff;
}

</style>