<template>
    <main class="onboarding">
        <div class="onboarding__wrapper">
            <h1 class="onboarding__title">Реферальна програма</h1>
                <div class="onboarding__inner">
                    <div class="onboarding__img">
                        <img src="../../assets/icons/affiliate/refarral-new-1.png" class="onboarding__img-item" alt="">
                        <span class="onboarding__img-descr">Ви залучаєте потенційних клієнтів</span>
                    </div>
                    <div class="onboarding__img">
                        <img src="../../assets/icons/affiliate/refarral-new-2.png" class="onboarding__img-item" alt="">
                        <span class="onboarding__img-descr">Клієнти підключають послугу</span>
                    </div>
                    <div class="onboarding__img">
                        <img src="../../assets/icons/affiliate/refarral-new-3.png" class="onboarding__img-item" alt="">
                        <span class="onboarding__img-descr">Ви отримуєте свій прибуток</span>
                    </div>
                </div>
                <div class="onboarding__instruction">
                    <ul class="onboarding__instruction-list">
                        <li class="onboarding__instruction-item">
                            1. Реєструйтеся у реферальній програмі від “Шериф”.
                            </li>
                        <li class="onboarding__instruction-item">
                            2. Додавайте власну банківську карту.
                            </li>
                        <li class="onboarding__instruction-item">
                            3. Діліться реферальним посиланням з рідними, друзями і знайомими.
                            </li>
                        <li class="onboarding__instruction-item">
                            4. Оформлюйте виплату коштів на власну карту.
                            </li>
                        <li class="onboarding__instruction-item">
                            5. Заробляйте 500 грн на власний рахунок за кожного підключеного клієнта.
                            </li>
                    </ul>
                    <Button class="onboarding__btn" @clickBtn="$router.push({ path: '/referral' })">Розпочати</Button>
                </div>
        </div>
    </main>

</template>
<script>
import Button from "../Buttons/YellowButton.vue";
export default {
    components: { Button }
}
</script>

<style scoped lang="scss">
.onboarding {
    &__wrapper {
        background: rgb(68 67 67 / 65%);
        border-radius: 10px;
        padding: 20px 50px 25px;
    }
    &__title {
        margin: 0 0 45px;
        font-size: 28px;
        text-align: center;
    }
    &__inner {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 70px;
    }
    &__img {
       height: 320px;
       width: 320px;
        @media screen and (max-width: 1535px) {
            height: 250px;
            width: 250px;
        }
        &-item {
            object-fit: cover;
           height: 100%;
           width: 100%;
           border-radius: 10px;
           margin-bottom: 10px;
        }
        &-descr {
            display: block;
            text-align: center;
            font-size: 18px;
            @media screen and (max-width: 1535px) {
            font-size: 16px;
            }
        }
    }
    &__instruction {
        font-size: 15px;
        text-align: center;
        &-list {
            list-style-type: none;
            margin-bottom: 20px;
        }
        &-item {
            margin-bottom: 5px;
        }
    }
    &__btn {
        border-radius: 100px;
        padding-left: 40px;
        padding-right: 40px;
    }
}

</style>