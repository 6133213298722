<template>
  <div class="nlogin">
    <div class="promo-wrapper">
      <div class="side-login">
        <div class="side-login__logo">
          <LogoSVG />
        </div>
        <p class="intro">
          Для того щоб розпочати роботу увійдіть в особистий кабінет
        </p>
        <YellowButton
          @clickBtn="$router.push('/login')"
          class="radius side-login__button"
        >
          Увійти в особистий кабінет
        </YellowButton>
      </div>
    </div>
    <div class="promo-wrapper">
      <div class="side-feature">
        <div>
          <p class="side-feature__title">MySheriff</p>
          <p class="side-feature__description">
            Скануй QR-код та підключай нову послугу
            <span class="side-feature__name">Sheriff SOS</span>
          </p>
          <img
            class="side-feature__code"
            :src="require('@/assets/img/sos_deeplink.svg')"
            alt="Sheriff SOS deeplink"
          />
        </div>
        <div>
          <img
            class="side-feature__phone"
            :src="require('@/assets/img/sos_phone.svg')"
            alt="Sheriff SOS"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YellowButton from "@/components/Buttons/YellowButton.vue";
import LogoSVG from "@/components/SVG/Logo.vue";

export default {
  created() {
    console.log(this.$route);
  },
  components: {
    YellowButton,
    LogoSVG,
  },
};
</script>

<style scoped lang="scss">
.nlogin {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: grey;
  display: flex;
  justify-content: center;
  & .promo-wrapper {
    min-height: 100vh;
    width: 100%;
    background-color: blue;
    &:nth-child(1) {
      background-color: #131313;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &:nth-child(2) {
      background-color: #fce500;
      margin-right: 0;
    }
  }
  & .side-login {
    &__logo {
      display: flex;
      justify-content: center;
      margin-bottom: 56px;
    }
    &__button {
      width: 100%;
      height: 58px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0.32px;
    }
    & .intro {
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 330px;
      width: 100%;
      margin-bottom: 32px;
    }
  }
  & .side-feature {
    height: 100vh;
    padding-top: 88px;
    padding-left: 40px;
    display: flex;
    &__title {
      margin-top: 0;
      margin-bottom: 64px;
      color: #000;
      text-align: left;
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &__description {
      margin: 0;
      margin-bottom: 56px;
      max-width: 346px;
      width: 100%;
      color: #000;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    &__name {
      color: #000;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
    &__code {
      width: 285px;
      height: 285px;
    }
    &__phone {
      height: 680px;
      position: absolute;
      top: 40px;
      right: 40px;
    }
  }
}
</style>