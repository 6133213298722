<template>
  <main class="dashboard">
    <section
      class="dashboard__account"
      :class="{ 'dashboard__account--mobile': onMobile }"
    >
      <h1 class="dashboard__title">Особистий кабінет</h1>

      <Balance :name="user.first_name" :balance="user.balance" />
      <div v-if="onMobile" class="account account--mobile">
        <div class="account__body">
          <span class="account__body-title">
            <router-link to="/referral" class="nav__link">
              Отримай 500 грн за&nbsp;
              <span class="click">1&nbsp;клік</span>
            </router-link>
          </span>
        </div>
      </div>
      <Objects :projects="projects" />
      <Payment :phone="user.phone" />
      <Account
        class="finance__account"
        :name="user.first_name"
        :namelast="user.last_name"
        :phone="user.phone"
        :objects="projects"
        @addBill="getBillByProject"
      />
      <Services :user="user" :services="services" />
      <Contacts />
    </section>
    <section v-if="!onMobile" class="dashboard__news">
      <News class="answers">
        <template v-slot:header>
          <p class="answers__text">
            <b>Поради щодо стабільної роботи обладнання під час блекауту</b>
          </p>
          <p>
            Щоб уникнути втрати зв'язку з охоронним обладнанням, через можливий
            блекаут чи проблеми зі звʼязком у мобільних операторів, рекомендуємо
            провести його модернізацію для стабільної роботи.
          </p>
          <p>Для цього необхідно:</p>
          <ul>
            <li>
              <span>1.</span>
              використовувати домашній оптиковолоконний інтернет як
              основний/резервний канал зв'язку, замість використання лише
              сім-карти.
            </li>
            <li>
              <span>2.</span>
              Встановити підсилені елементи резервного живлення
            </li>
            <li>
              <span>3.</span>
              встановити додаткову сім-карту (якщо це технічно можливо для
              вашого обладнання)
            </li>
          </ul>
          <p>
            Залишайте заявку на сайті або зателефонуйте нам, та фіксуйте
            абонплату на увесь рік.
          </p>

          <b>
            ❗️ВАЖЛИВО: якщо нещодавно у вас зникав зв’язок з охороним
            обладнанням, необхідна заміна елементів живлення або консультація
            щодо використання дротового інтернету - заповніть форму за
            посиланням, наш спеціаліст вас проконсультує та, за необхідності,
            організує заміну обладнання.
          </b>
          <a
            href="https://sheriff.promo/checkup-bo?utm_source=OK_mySheriff&utm_medium=cpc"
            class="news__link"
            >Залишити заявку</a
          >
        </template>
      </News>
      <News class="answers" :videoLinks="answersLinks">
        <template v-slot:header>
          <p class="answers__text">
            Ми постійно думаємо про те, як зробити нашу співпрацю максимально
            зручною для вас. І знаємо, що часом у наших таких різних клієнтів
            виникають одні й ті ж питання. Проте письмовий формат не завжди є
            оптимальною формою надання відповідей на них – краще один раз
            побачити, як-то кажуть. Тому ми підготували невеличкий плейлист із
            <b> відповідями на найпоширеніші питання щодо послуг охорони:</b>
          </p>
        </template>
      </News>
    </section>
    <TechProtectionNotification
      v-if="notification && notification.type === 'tech_protection'"
      @confirmed="techProtectionConfirmed = true"
      :notification="notification"
    />
    <DownloadNotification v-if="techProtectionConfirmed" />
  </main>
</template>

<script>
import Balance from "./Balance.vue";
import Objects from "./Objects.vue";
import Payment from "./Payment.vue";
import Account from "./Account.vue";
import Services from "./Services.vue";
import Contacts from "./Contacts.vue";
import News from "./News.vue";
import TechProtectionNotification from "../Notifications/TechProtectionNotification.vue";
import DownloadNotification from "../Notifications/DownloadNotification.vue";

export default {
  components: {
    Balance,
    Objects,
    Payment,
    Account,
    Services,
    Contacts,
    News,
    TechProtectionNotification,
    DownloadNotification,
  },
  data() {
    return {
      user: "",
      contracts: {},
      projects: [],
      services: [],
      bills: [],
      notification: [],
      newBill: {
        id: "",
        name: "",
        path: "",
      },
      newsLinks: [
        "https://www.youtube.com/embed/NVFKUwxFNME?showinfo=0",
        "https://www.youtube.com/embed/8luympry3yk?showinfo=0",
        "https://www.youtube.com/embed/qKy6Mt_g1ug?showinfo=0",
      ],
      answersLinks: [
        "https://www.youtube.com/embed/videoseries?list=PLlyd-VCtkay1r_LKI8s6Cbb6Kikt09-m_",
        "https://www.youtube.com/embed/Yon0Se_CJPo",
        "https://www.youtube.com/embed/RDvvzD9ahVo",
      ],
      techProtectionConfirmed: false,
    };
  },
  created() {
    if (localStorage.getItem("user") !== null) {
      this.user = JSON.parse(localStorage.getItem("user"));
      this.getUserById(this.user.crm_id);
      this.getServices();

      if (localStorage.getItem("bills") === null) {
        this.getBillsByUser(this.user.crm_id);
      }
      this.bills = JSON.parse(localStorage.getItem("bills"));

      this.objectsByUser(this.user.crm_id);

      this.notificationByUser(this.user.crm_id);
    }
  },
  methods: {
    getUserById(id) {
      this.axios
        .get(`${this.$domain}/users/${id}`)
        .then(
          (response) => (this.user = { ...this.user, ...response.data.user })
        );
    },
    contractsByUser(id) {
      this.axios
        .get(`${this.$domain}/users/${id}/contracts`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.contracts = response.data.contracts;
          localStorage.setItem(
            "contracts",
            JSON.stringify(response.data.contracts)
          );
        })
        .catch((e) => console.log(e.message));
    },
    objectsByUser(id) {
      this.axios
        .get(`${this.$domain}/users/${id}/projects`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.projects = response.data.data;
          localStorage.setItem("projects", JSON.stringify(this.projects));
        })
        .catch((e) => console.log(e.message));
    },
    getBillsByUser(id) {
      this.axios
        .get(`${this.$domain}/users/${id}/bills`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) =>
          localStorage.setItem("bills", JSON.stringify(response.data.data))
        )
        .catch((e) => console.log(e.message));
    },
    getBillByProject(id) {
      this.axios
        .get(`${this.$domain}/projects/${id}/bill`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => this.addNewBill(response.data))
        .catch((e) => console.log(e.message));
    },
    addNewBill(data) {
      this.bills.unshift({
        id: Date.now(),
        filename: data.filename,
        filepath: data.filepath,
      });
      localStorage.setItem("bills", JSON.stringify(this.bills));

      this.$router.push("/finance");
    },
    notificationByUser(id) {
      this.axios
        .get(`${this.$domain}/users/${id}/notification?web=true`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.notification = response.data.message;
        })
        .catch((e) => console.log(e));
    },
    getServices() {
      this.axios
        .get(`${this.$domain}/services`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.result === "success") {
            this.services = response.data.services;
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard {
  display: flex;
  justify-content: space-between;

  &__title {
    margin: 0 0 10px;
    font-size: 13px;
  }
  &__account {
    width: 32%;
    &--mobile {
      width: 100% !important;
    }

    @media screen and (max-width: 1439px) {
      width: 37%;
    }
  }
  &__news {
    padding-top: 25px;
    color: #000;
    width: 67%;

    @media screen and (max-width: 1439px) {
      width: 62%;
    }
  }
}
.news {
  &__youtube {
    color: #007aff;
    font-weight: 700;
    text-decoration: none;
    display: block;
  }
  &__date {
    color: rgb(128, 128, 128);
    font-size: 12px;
    display: block;
    margin-bottom: 15px;
  }
}
.answers {
  margin-bottom: 16px;

  &__text {
    margin: 0 0 15px;
  }
}

ul {
  list-style-type: disc;
}
li {
  margin: 16px 0;
}
</style>
