<template>
    <main class="finance">
        <h1 class="finance__title">Мої рахунки</h1>
        <Account class="finance__account"
        :namelast="user.last_name"
        :name="user.first_name"
        :phone="user.phone"
        :objects="projects"
        @addBill="getBillByProject"
        />
        <div class="finance__grid">
            <Bill v-for="bill in bills" :key="bill.id" 
            :bill-name="bill.filename"
            :bill-path="bill.filepath"
            />
        </div>
    </main>
</template>
<script>
import Account from "../Dashboard/Account.vue";
import Bill from "./Bill.vue";
export default {
    data() {
        return {
            user: "",
            projects: [],
            // contracts: {},   
            bills: [],
            newBill: {
                id: "",
                name: "",
                path: ""
            }
        }
    },
    methods: {
        getBillByProject(id) {
            console.log(id)
            this.axios.get(`${this.$domain}/projects/${id}/bill`, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            .then(response => this.addNewBill(response.data))
            .catch(e => console.log(e.message))
        },
        addNewBill(data) {
            this.bills.unshift({
            id: Date.now(),
            filename: data.filename,
            filepath: data.filepath,
            })
        localStorage.setItem("bills", JSON.stringify(this.bills))
        }
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem("user"));
        this.projects = JSON.parse(localStorage.getItem("projects"))
        // this.contracts = JSON.parse(localStorage.getItem("objects"))
        this.bills = JSON.parse(localStorage.getItem("bills"));
        this.bills && this.bills.reverse();
    },
    components: { Account, Bill }
}
</script>

<style scoped lang="scss">
.finance{
    &__title {
        margin: 0 0 25px;
        text-align: center;
        font-size: 24px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e6e6e6;
    }
    &__account {
        margin-bottom: 20px;
    }
    &__grid {
        display: grid;
        grid-template: 1fr /  repeat(3, 1fr);
        gap: 15px;
        @media screen and (max-width: 1679px) {
          grid-template-columns: repeat(2, 1fr);
        }
    }
}
</style>