<template> 
<div class="applications" v-if="onMobile">
    <div class="applications__inner">
    <h1 class="applications__title">Завантажуйте наш додаток MySheriff <br/> для iOS та Android</h1>
    <div class="applications__block">
      <div class="applications__item">
        <a href="https://apps.apple.com/ua/app/mysheriff-особистий-кабінет/id1589945728"><i class="fab fa-app-store"></i></a>
        <span>iOS</span>
      </div>
      <div class="applications__item">
        <a href="https://play.google.com/store/apps/details?id=com.sheriff.app"> <i class="fab fa-google-play"></i></a>
        <span>Android</span>
      </div>
    </div>
    <button class="applications__web" @click="onMobile = false">Продовжити у веб-версії</button>
    </div>
</div>
<div id="app" v-else>
  <router-view />
</div>
 
</template>

<script>
export default {
  name: "App",
}
</script>

<style lang="scss">
@font-face {
  font-family: "GothamPro";
  src: url("assets/fonts/GothamPro-Light.eot?#iefix") format("embedded-opentype"),
  url("assets/fonts/GothamPro-Light.woff") format("woff"),
      url("assets/fonts/GothamPro-Light.ttf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "GothamPro";
  src: url("assets/fonts/GothamPro.woff") format("woff"),
      url("assets/fonts/GothamPro.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "GothamPro";
  src: url("assets/fonts/GothamPro-Medium.woff") format("woff"),
      url("assets/fonts/GothamPro-Medium.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "GothamPro";
  src: url("assets/fonts/GothamPro-Bold.woff") format("woff"),
      url("assets/fonts/GothamPro-Bold.ttf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
*, *:before, *:after{
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
  font-family: "GothamPro";
  font-size: 14px;
  font-weight: 400;
  color: #fff
}
#app {
  position: relative;
}
.hide {
  display: none;
}
.applications {
    background-color: #000;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    &__web {
      display: block;
      margin: 0 auto;
      padding: 8px;
      margin-top: 16px;
      border: 1px solid transparent;
      border-radius: 57px;
      background: #fce500;
      color: #121212;
    }
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        padding-top: 24px;
      }
    }
    &__title {
      text-align: center;
      margin: 0 0 30px;
      font-size: 24px;
      @media screen and (max-width: 991px) {
        font-size: 20px;
      }
       @media screen and (max-width: 510px) {
        font-size: 18px;
        margin-bottom: 25px;
      }
    }
    &__block {
      display: flex;
      justify-content: center;
      align-items: center;
      & .fab {
        font-size: 40px;
        margin: 0 35px;
        color: rgb(250, 230, 21);
        cursor: pointer;
        @media screen and (max-width: 991px) {
          font-size: 35px;
        }
        @media screen and (max-width: 510px) {
        font-size: 30px;
        margin: 0 25px;
      }
      }
    }
}
.radius {
  border-radius: 100px;
}

</style>
