<template>
    <main class="faqs" v-if="faq">
    <h1 class="faqs__title">F.A.Q.</h1>
    <div class="faqs__list">
        <FaqBlock :faqs="contractFaq"/>
        <FaqBlock :faqs="infoFaq"/>
        <FaqBlock :faqs="userFaq"/>
        <FaqBlock :faqs="serviceFaq"/>
        <FaqBlock :faqs="technicalFaq"/>    
    </div>
    </main>
    <Spinner v-else />
</template>
<script>  
import FaqBlock from "./FaqBlock.vue";
import Spinner from "../Spinner"
export default {
    data() {
        return {
            faq: null,
        }
    },
    computed: {
        contractFaq() {
            let faqs = this.faq.filter(item => item.type === "Фінансові запитання");
            return faqs
        },
        infoFaq() {
            let faqs =  this.faq.filter(item => item.type === "Інформаційні запитання");
            return faqs
        },
        userFaq() {
            let faqs =  this.faq.filter(item => item.type === "Особистий кабінет");
            return faqs
        },
        serviceFaq() {
            let faqs =  this.faq.filter(item => item.type === "Послуги/Додаткові послуги");
            return faqs
        },
        technicalFaq() {
            let faqs =  this.faq.filter(item => item.type === "Технічні запитання");
            return faqs
        },
    },
    methods: {
        getFaq() {
        this.axios.get(`${this.$domain}/faqs`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
        .then(response => {
            this.faq = response.data.data;
        })
        .catch(e => console.log(e.message))
        }
    },
    mounted() {
        this.getFaq();
    },
    components: { FaqBlock, Spinner}
}
</script>

<style scoped lang="scss">
.faqs {
    &__title {
        margin: 0 0 25px;
        text-align: center;
        font-size: 24px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e6e6e6;

    }
}


</style>