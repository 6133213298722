<template>
    <div>
        <div class="account" @click="showBill = true">
        <div class="account__body">
            <span class="account__body-title">Отримати рахунок</span>
            <span class="account__body-text">Отримайте рахунок для сплати абонентської плати</span>
        </div> 
    </div>
    <Modal 
    @clickBtn="showBill = false"
    :class="{show: showBill }">
        <template v-slot:header>
            <h5 class="account__modal-title">Отримати рахунок</h5>
        </template>
        <template v-slot:body>
            <form class="account__modal-form">
                <label class="account__modal-label">
                    Контактні дані
                </label>
                <div class="account__modal-data">
                    <span class="account__modal-name">
                        <input type="text" class="account__modal-input account__modal-input--valid" 
                        placeholder="Ім'я"
                        v-model="fullName" 
                        readonly>
                        <i class="fas fa-check"></i>
                    </span>
                    <span class="account__modal-phone">
                        <input type="text" class="account__modal-input account__modal-input--valid" 
                        placeholder="Телефон"
                        :value="phone" 
                        readonly>
                        <i class="fas fa-check"></i>
                    </span>
                    </div>
                <label class="account__modal-label">
                    Об'єкт 
                </label>
                <select class="account__modal-select"
                v-model="address">
                    <option disabled>Оберіть об'єкт</option>
                    <option v-for="object in objects" :key="object.address" :value="object.address"
                    >{{object.address}}</option>
                </select>
                </form>    
                <Button class="account__modal-btn"
                @clickBtn="emitBill"
                >Отримати рахунок</Button>
        </template>
    </Modal>
    </div>
</template>
<script>
import Modal from "../Modals/DefaultModal.vue";
import Button from "../Buttons/YellowButton.vue";
export default {
    name: "Account",
    props: ["name", "namelast", "phone", "objects"],
    data() {
        return {
            showBill: false,
            address: "Оберіть об'єкт",
        } 
    },
    computed: {
        fullName() {
            return `${this.namelast} ${this.name}`
        },
        activeContract() {
            const currentObject= this.objects.find(object => object.address === this.address);
            return currentObject?.contract_docnumber
        }
    },
    methods: {
        emitBill(){
            if(this.address !== "Оберіть об'єкт") {
                this.$emit('addBill', this.activeContract);
                this.showBill = false;
            }
        }
    },
    components: { Modal, Button}
 
}
</script>
<style lang="scss">
$accent: rgb(251, 227, 1);
$dark: rgb(28, 28, 28);
.account {
    background-color: $dark;
    border-radius: 6px;
    color: $accent;
    padding: 40px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
    &__body {
        text-align: center;
        font-size: 13px;
        &-title {
            display: block;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 5px;
        }
    }
    &--mobile {
        padding: 16px;
    }
    &__modal{
        &-descr {
            margin-bottom: 24px;
            &--price {
                display: block;
                padding-top: 16px;
            }
        }
        &-title {
            font-size: 20px;
            margin: 0;
            width: 100%;
            text-align: left;
        }
        &-data {
            display: flex;
            margin-bottom: 15px;
        }
        &-input, &-select  {
            display: block;
            border: 1px solid transparent;
            width: 100%;
            padding: 10px;
            font-size: 14px;
            border-radius: 6px;
            background-color: rgb(242, 242, 242);
            font-family: "GothamPro";
        }
        &-input--valid {
           border: 1px solid  #1abd36;
        }
        &-select {
            border: 1px solid rgb(204, 204, 204);
            margin-bottom: 15px;
            background-color: #fff;
        }
        &-name, &-phone {
            position: relative;
            & .fa-check {
                position: absolute;
                top: 35%;
                right: 10px;
                color: #1abd36;
            }
        }
        &-phone{
            width: 38%;
        }
        &-name {
            width: 60%;
            margin-right: 2%;
        }
        &-label {
            display: block;
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 8px;
            position: relative;
            display: inline-block;
            &:after {
                position: absolute;
                content: "*";
                right: -10px;
                top: 0;
                color: rgb(255, 59, 48);
            }
        }
        &-btn {
            border-radius: 100px;
            display: block;
            margin: 0 auto;
        }
    }
}

</style>