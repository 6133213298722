<template> 

    <main class="payhistory" v-if="payHistory">
        <h1 class="payhistory__title">Історія взаєморозрахунків</h1>

        <table class="payhistory__table" cellspacing="0" cellpadding="10"  border="1" v-if="this.payHistory">
            <thead>
                  <tr class="">
                    <td colspan="5" class="payhistory__table-balance">Баланс {{payHistory.Balance}} грн</td>
                </tr>
                <tr class="payhistory__table-header">
                    <th>Дата</th>
                    <th>Операція</th>
                    <th>Отримано</th>
                    <th>Нараховано</th>
                    <th>Баланс</th>
                </tr>
            </thead>
                <ContractTable v-for="contract in payHistory.Contract" :key="contract.Description" :contract="contract"/>
        </table>
    </main>
   <Spinner v-else />

</template>
<script>
import ContractTable from "./ContractTable.vue";
import Spinner from "../Spinner"
export default{
    data() {
        return {
            userId: "",
            payHistory: null,
        }
    },
    methods: {
        getPayHistory(id) {
        this.axios.get(`${this.$domain}/users/${id}/paymentHistory`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
        .then(response => this.payHistory = response.data.paymentHistory)
        .catch(e => console.log(e.message))
        }
    },
    mounted() {
        this.userId = JSON.parse(localStorage.getItem("user")).crm_id;
        this.getPayHistory(this.userId);
    },
    components: {
        ContractTable, Spinner
    }
}
</script>

<style scoped lang="scss">
.payhistory {
    &__title {
        margin: 0 0 25px;
        text-align: center;
        font-size: 24px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e6e6e6;
    }
    &__table {
        width: 100%;
        border-color: #e6e6e6;
        &-balance {
            text-align: center;
            font-weight: 700;
            }
        }
}
tr {
    width: 100%;
}

</style>