import Axios from "axios";

const axios = Axios.create({
  baseURL: `http://10.210.10.18:8043/api`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    responseType: "json",
  },
});

axios.interceptors.request.use((config) => {
  const token = `Bearer ${localStorage.getItem("token")}`;

  if (!token) {
    return config;
  }

  config.headers.Authorization = token;

  return config;
});

// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       HANDLER
//       document.location.reload();
//       localStorage.clear();
//     }
//   }
// );

export default axios;
