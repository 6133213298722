<template>
    <div class="card">
        <div class="card__inner">
            <span class="card__user">{{fullName}}</span>
            <div class="card__balance">
                <span class="card__balance-title">Баланс 
                    <strong style="display: block"> {{user.referrer_balance ? user.referrer_balance: 0}} грн.</strong>
                </span>
                <span class="card__balance-cash"></span>
            </div>

            <button class="card__btn-payhistory"
            v-if="cardVisible &&  payHistoryLength > 0"
            @click="$router.push({path: 'referrerpayhistory'})"
            >Історія виплат</button>

            <Spinner v-if="!cardVisible" class="card__spinner" />
            <YellowButton class="card__btn-withdrawals"
            v-else-if="cardExist"
            @clickBtn="$emit('withdrawals')"
            >Замовити виплату</YellowButton>
            <div v-else class="card__add-wrapper">
            <YellowButton class="card__btn" 
            @clickBtn="$emit('addCard', 'Додати номер вашої картки')"
            >Додати картку</YellowButton>
            <span>Підв’яжіть банківську карту, на яку будуть зараховані надходження</span>
            </div>

            <div class="card__item" v-if="cardVisible" @click="$emit('addCard', 'Змінити номер вашої картки')">
                <div class="card__item-logos">
                    <div class="card__item-logo">
                        <img src="../../assets/icons/card-logo.png" alt=""></div>
                    <div class="card__item-logo">
                        <img src="../../assets/icons/card-security.png" alt=""></div>
                </div>
                <div class="card__item-grid">
                    <span class="card__item-text">Номер картки</span>
                    <div class="card__item-logo">
                        <img src="../../assets/icons/card-visa.png" alt="">
                    </div>
                    <span class="card__item-number">{{cardValue}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import YellowButton from "../Buttons/YellowButton.vue";
import Spinner from "../Spinner";
export default {
    props: ["cardVisible", "cardValue", "payHistoryLength", "user", "cardExist"],
    computed: {
        fullName() {
            return `${this.user.first_name} ${this.user.last_name}`
        }
    },
    components: { YellowButton, Spinner },
} 
</script>
<style scoped lang="scss">
.card {
    width: 30%;
    height: 100%;
    border: 3px solid #51961c;
    border-radius: 6px;
        @media screen and (max-width: 1535px) {
            width: 35%;
        }
        @media screen and (max-width: 1280px) {
            width: 100%;
        }
    &__inner{
        background-color: rgba(68, 67, 67, 0.65);
        border-radius: 4px;
        padding: 15px;
        margin: 3px;
        text-align: center;
    }
    &__spinner {
        display: flex;
    }
    &__user {
        display: inline-block;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    &__balance{
        &-title {
            display: block;
            line-height: 20px;
        }
        &-cash{
            display: inline-block;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 20px;
        }
    }
    &__btn {
        display: block;
        margin: 0 auto 15px;
        border-radius: 6px;
        font-weight: 400;
        padding: 10px 12px;
        &-payhistory {
            display: block;
            background-color: rgb(128, 128, 133);
            margin: 0 auto 15px;
            border-radius: 6px;
            font-weight: 400;
            padding: 10px 12px;
            color: #fff;
            border: none;
            cursor: pointer;
            font-family: "GothamPro";
            font-size: 14px;
        }
        &-withdrawals {
            border-radius: 6px;
            font-weight: 400;
            padding: 10px 12px;
            margin-bottom: 15px;
        }
    }
    &__item {
        background-image: linear-gradient( #88812e 36%, #ffeb00 146%);
        border-radius: 10px;
        padding: 28px 20px;
        cursor: pointer;
        max-width: 290px;
        margin: 0 auto;
        &-logos {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
        }
        &-grid {
            display: grid;
            grid-template: 1fr 1fr / 1fr 50px;
            text-align: left;
            color: rgba(256, 256, 256, 0.6);
            font-size: 16px;
            row-gap: 5px;
            & .card__item-logo {
                justify-self: end;
                grid-column: 2/3;
                grid-row: 1/3;
                align-self: center;
            }
        }
        &-text {
            grid-row: 1/2;
            grid-column: 1/2;
        }
        &-number {
            grid-row: 2/3;
            grid-column: 1/2;

        }
    }
}

</style>
