<template>
  <div class="single" @click="$emit('getSinglePayment')">
    <div class="single__wrapper">
      <div class="single__main">
        <span style="font-size: 36px" class="single__icon">
          <i class="fas fa-hryvnia"></i>
        </span>
        <strong class="single__title">Cплатити онлайн за допомогою Gpay, ApplePay</strong>
      </div>

      <div
        v-if="loadingSingleCheckout"
        class="loadingio-spinner-spin-v362e4u5paj"
      >
        <div class="ldio-a7ykqcvzp36">
          <div><div></div></div>
          <div><div></div></div>
          <div><div></div></div>
          <div><div></div></div>
          <div><div></div></div>
          <div><div></div></div>
          <div><div></div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loadingSingleCheckout: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
$accent: rgb(251, 227, 1);
.single {
  margin-top: 15px;
  background-color: $accent;
  border-radius: 6px;
  margin-bottom: 15px;
  padding: 15px;
  color: #000;
  cursor: pointer;
  min-height: 69px;
  &__icon {
    padding-left: 8px;
  }
  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 39px;
  }
  &__title {
    display: block;
    font-size: 20px;
    font-weight: 600;
    padding-left: 14px;
  }
}

@keyframes ldio-a7ykqcvzp36 {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.5, 1.5);
  }
  100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
  }
}
.ldio-a7ykqcvzp36 div > div {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #1c1c1c;
  animation: ldio-a7ykqcvzp36 1.0526315789473684s linear infinite;
}
.ldio-a7ykqcvzp36 div:nth-child(1) > div {
  left: 74px;
  top: 44px;
  animation-delay: -0.9022556390977443s;
}
.ldio-a7ykqcvzp36 > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 80px 50px;
}
.ldio-a7ykqcvzp36 div:nth-child(2) > div {
  left: 63px;
  top: 67px;
  animation-delay: -0.7518796992481203s;
}
.ldio-a7ykqcvzp36 > div:nth-child(2) {
  transform: rotate(51.42857142857143deg);
  transform-origin: 69px 73px;
}
.ldio-a7ykqcvzp36 div:nth-child(3) > div {
  left: 37px;
  top: 73px;
  animation-delay: -0.6015037593984962s;
}
.ldio-a7ykqcvzp36 > div:nth-child(3) {
  transform: rotate(102.85714285714286deg);
  transform-origin: 43px 79px;
}
.ldio-a7ykqcvzp36 div:nth-child(4) > div {
  left: 17px;
  top: 57px;
  animation-delay: -0.45112781954887216s;
}
.ldio-a7ykqcvzp36 > div:nth-child(4) {
  transform: rotate(154.28571428571428deg);
  transform-origin: 23px 63px;
}
.ldio-a7ykqcvzp36 div:nth-child(5) > div {
  left: 17px;
  top: 31px;
  animation-delay: -0.3007518796992481s;
}
.ldio-a7ykqcvzp36 > div:nth-child(5) {
  transform: rotate(205.71428571428572deg);
  transform-origin: 23px 37px;
}
.ldio-a7ykqcvzp36 div:nth-child(6) > div {
  left: 37px;
  top: 15px;
  animation-delay: -0.15037593984962405s;
}
.ldio-a7ykqcvzp36 > div:nth-child(6) {
  transform: rotate(257.14285714285717deg);
  transform-origin: 43px 21px;
}
.ldio-a7ykqcvzp36 div:nth-child(7) > div {
  left: 63px;
  top: 21px;
  animation-delay: 0s;
}
.ldio-a7ykqcvzp36 > div:nth-child(7) {
  transform: rotate(308.57142857142856deg);
  transform-origin: 69px 27px;
}
.loadingio-spinner-spin-v362e4u5paj {
  width: 41px;
  height: 41px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-a7ykqcvzp36 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.41);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-a7ykqcvzp36 div {
  box-sizing: content-box;
}
</style>