<template>
    <div></div>
</template>

<script>
    export default {
        props: ["notification"],
        mounted() {
			console.log(this.notification);

            if (this.notification.type === 'tech_protection') {
                this.$swal.fire({
                    icon: 'info',
                    width: 800,
                    title: '<strong style="color: #fff;">Ми хвилюємося за вашу безпеку!</strong>',
                    html: `<span style="color: #fff;">Рівень технічної укріпленості вашого об'єкту нижче 75%, а отже, зберігається шанс проникнення зловмисниками на нього. Щоб усунути недоліки в безпеці об’єкта – замовляйте підвищення технічної укріпленості.</span>`,
                    confirmButtonText: '<i class="fa fa-thumbs-up"></i> Зрозумiв'
                })
                .then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        console.log(result);

						this.axios.put(
							`${this.$domain}/notification/${this.notification.id}/update`,
							{
								status: 'inactive'
							},
							{
                                headers: {
                                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                                }
                            }
						)
						.then((response) => console.log(response))
						.catch((e) => console.log(e));
					}

                    this.$emit('confirmed')
				});
            }
        }
    }
</script>
