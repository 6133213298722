<template>
  <main class="connect" v-if="connectedObjects.length">
      <h1 class="connect__title">
          Заявки на підключення
      </h1>
      <div class="connect__grid">
        <ConnectItem v-for="object in connectedObjects" :key="object.id" :object="object"/>
      </div>
  </main>
  <Spinner v-else/>
</template>
<script>
import ConnectItem from "./ConnectItem.vue";
import Spinner from "../Spinner"
export default {
    data() {
        return {
            userId: "",
            connectedObjects: []
        }
    },
    methods: {
        getConnectedObjects(id) {
            this.axios.get(`${this.$domain}/users/${id}/leads`, {
                headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            .then(response => this.connectedObjects = response.data.data)
        }
    },
    mounted() {
        this.userId = JSON.parse(localStorage.getItem("user")).crm_id;
        this.getConnectedObjects(this.userId)
    },
    components: { ConnectItem, Spinner }
}
</script>
<style scoped lang="scss">
.connect {
    &__grid {
        display: grid;
        grid-template: 1fr / repeat(3, 1fr);
        gap: 30px;
    }
    &__title {
        margin: 0 0 30px;
        text-align: center;
        font-size: 24px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e6e6e6;
    }
}
</style>
