<template>
    <div class="new-object">
        <div class="new-object__header">
         Підключити новий об'єкт
        </div>
        <div class="new-object__body">
            <img src="" alt="">
        </div>
        <div class="new-object__footer" @click="modalVisible = true">
            <div class="new-object__footer-trigger">Підключити новий об'єкт
            <i class="fas fa-angle-right"></i>
            </div>
        </div>
        <ConnectModal :modalVisible="modalVisible"
        @hideModal="hideModal"
        :leadInfo="leadInfo"
        :cities="cities"
        :objects="objects"
        @connectNewObject="connectNewObject(leadInfo.city, leadInfo.objectType)"
        />
    </div>
    
</template>

<script>
import ConnectModal from "./ConnectModal.vue"
export default {
    data() {
        return {
           modalVisible: false, 
           leadInfo: {
               name: "",
               phone: "",
               city: "Оберіть місто",
               objectType: "Оберіть тип власності"
           },
           cities: ["Київ", "Рівне", "Мелітополь", "Львів", "Інші"],
           objects: ["Приватна власність", "Комерційна власність", "Інший тип власності"]
        }
    },
    methods: {
        showModal() {
            this.modalVisible = true
        },
        hideModal() {
            this.modalVisible = false
        },
        connectNewObject(city, type_object) {
            if(this.leadInfo.city !== "Оберіть місто" && this.leadInfo.objectType !== "Оберіть тип власності") {
                this.axios.post(`${this.$domain}/users/${this.userId}/leads`, {
                    city, type_object
            },
            {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}` 
            }})
            .then(response => (response.data.result === "success") ?  this.$router.push("/objects/create") : "")
            .catch(e => console.log(e.message))
            }
        }
    },
    mounted() {
        this.userId = JSON.parse(localStorage.getItem("user")).crm_id;
        this.leadInfo.name = JSON.parse(localStorage.getItem("user")).first_name;
        this.leadInfo.phone = JSON.parse(localStorage.getItem("user")).phone
    },
    components: { ConnectModal} 
}
</script>

<style scoped lang="scss">
$dark: rgb(28, 28, 28);
.new-object {
     &__header {
        background-color: $dark;
        padding: 15px;
        border-radius: 6px 6px 0 0;
        font-weight: 600;
    }
    &__body {
        background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../assets/img/card-installer.jpg");
        background-repeat: no-repeat;
        background-size: cover; 
        background-position: center;
        height: 80px;
    }
    &__footer{
        background-color: #fff;
        color: #000;
        border-radius: 0 0 6px 6px;
        padding: 14px;
        cursor: pointer;
        &-trigger {
            padding-left: 55px;
            line-height: 40px;
            position: relative;
            font-weight: 700;
            &:before {
                position: absolute;
                content: "";
                width: 40px;
                height: 40px;
                background-image: url("../../assets/icons/menu-item/add.png");
                background-size: 40px 40px;
                left: 0;
            }
            & .fa-angle-right {
                position: absolute;
                color: #e6e6e6;
                top: calc(50% - 10px);
                right: 0;
                font-size: 18px;
            }
        }

    }
    &__modal {
        &-form {
            display: grid;
            grid-template: repeat(2, 1fr) / repeat(2, 1fr);
            gap: 20px 20px;
            margin-bottom: 20px;
        }
        &-title {
            font-size: 20px;
            margin: 0;
        }
        &-input, &-select {
            display: block;
            width: 100%;
            padding: 10px;
            font-size: 14px;
            border-radius: 6px;
            background-color: rgb(242, 242, 242);
            border: none;
            font-family: "GothamPro";
            margin-top: 6px;
        }
         &-input {
           border: 1px solid  #1abd36;
        }
        &-select {
            border: 1px solid rgb(204, 204, 204);
            background-color: #fff;
        }
        &-label {
            // display: block;
            font-size: 13px;
            font-weight: 600;
            position: relative;
            display: inline-block;
            &:after {
                // position: absolute;
                // content: "*";
                // right: -10px;
                // top: 0;
                // color: rgb(255, 59, 48);
            }
        }
            &-name, &-phone {
            position: relative;
            display: block;
            & .fa-check {
                position: absolute;
                top: 35%;
                right: 10px;
                color: #1abd36;
            }
        }
       &-btn {
           border-radius: 100px;
           display: block;
           margin: 0 auto;
       }
    }
}
.show {
    display: block;
}
</style>