<template>
<main>
    <section class="main">
    <div class="container">
        <a href="https://my.sheriff.com.ua/" class="main__logo">
            <img src="../../assets/icons/logo_sheriff.webp" alt="">
        </a>
        <div class="main__grid">
            <h1 class="main__title">Реферальна програма <br />від "Шериф" вже в дії!</h1>
            <div class="main__img">
                <img src="../../assets/icons/affiliate/first-screen.webp" alt="">
            </div>

            <div class="main__descr">Відтепер за кожного підключенного знайомого, друга або родича, який перейшов за вашим посиланням, ви гарантовано отримаєте  <span class="bold">500&nbsp;гривень на власний рахунок</span>, а ваш друг  <span class="bold">250&nbsp;гривень</span>
               
            </div>
            <div class="main__links">
                <AccountLink href="https://my.sheriff.com.ua/referral" class="main__link arears">особистий кабінет</AccountLink>
                <OutlineBtn class="main__link registration" @clickBtn="showModal">зареєструватися</OutlineBtn>
            </div>
        </div>
    </div>
    </section>

    <section class="work">
    <div class="container">
        <h2 class="work__title">Як все працює?</h2>
        <div class="work__grid">
            <div class="work__item">
                <div class="work__item-img">
                    <img src="../../assets/icons/affiliate/01.webp" alt="">
                </div>
                <p class="work__item-text">Переходьте за посиланням&nbsp;/ особистий кабінет, та реєструйтеся у реферальній програмі.</p>
            </div>
            <div class="work__item">
                <div class="work__item-img">
                    <img src="../../assets/icons/affiliate/02.webp" alt="">
                </div>
                <p class="work__item-text">Додайте власну банківську карту для зручності операції.</p>
            </div>
            <div class="work__item">
                <div class="work__item-img">
                    <img src="../../assets/icons/affiliate/03.webp" alt="">
                </div>
                <p class="work__item-text">Діліться власним реферальним посиланням з рідними, друзями і знайомими.</p>
            </div>
            <div class="work__item">
                <div class="work__item-img">
                    <img src="../../assets/icons/affiliate/04.webp" alt="">
                </div>
                <p class="work__item-text">Отримуйте 500&nbsp;грн на власний рахунок за кожного підключеного клієнта.</p>
            </div>
            <div class="work__item">
                <div class="work__item-img">
                    <img src="../../assets/icons/affiliate/05.webp" alt="">
                </div>
                <p class="work__item-text">Оформлюйте виплати на власну карту.</p>
            </div>
        </div>
        <div class="work__trigger">
            <p class="work__trigger-text">
                Ви можете використовувати власний бонус у зручний для вас час та спосіб. Запрошуючи друзів, рідних та знайомих до нашої реферально програми, ви не тільки дбаєте про їх спокій та безпеку, але й отримуєте <span class="bold">бонус 500&nbsp;грн</span> cобі і <span class="bold">250&nbsp;грн</span> для друга
            </p>
            <div class="work__links">
                <AccountLink href="https://my.sheriff.com.ua/referral" class="main__link arears">особистий кабінет</AccountLink>
                <OutlineBtn class="main__link registration" @clickBtn="showModal">зареєструватися</OutlineBtn>
            </div>
        </div>
        <div class="work__bottom-picture">
            <img src="../../assets/icons/affiliate/friends_vs_logo.webp" alt="">
        </div>
    </div>
    </section>
 
    <footer class="footer">
    <div class="container">
        <div class="footer__grid">
            <div class="footer__logo">
                <img class="footer__logo-img" src="../../assets/icons/affiliate/sheriff_footer-logo.svg" alt="">
            </div>
            <div class="footer__contacts">
                <a href="tel:0800309114" class="footer__contacts-tel">
                    <span>0 800</span> 309 114</a>
                <span class="footer__contacts-text">відділ продажу (безкоштовно)</span>
            </div>
            <div class="footer__address">
                <h2 class="footer__address-name">Головний офіс ХОП "Шериф"</h2>
                <address class="footer__adrees-street">Київ, вул. Сосницька 1/44</address>
                <span class="footer__address-time">Офіс: 9:00 - 18:00</span>
                <span class="footr__address-help"> Технічна служба: Цілодобово</span>

            </div>
            <div class="footer__copyright">
                <span>Copyright 2007-2021</span>
                <span>ХОП "Шериф" - Всі права захищені</span>
            </div>
            <div class="divider divider-1"></div>
            <div class="divider divider-2"></div>
        </div>
    </div>
    </footer>

    <RegistrationModal :class="{show: modalVisible}" @closeModal="modalVisible = false">
        <template v-slot:body>
            <form class="form" name="form" action="">
                <ModalInput placeholder="Ваше ім`я" type="text" class="name" required
                v-model="registrationInfo.name"
                />
                <ModalInput placeholder="Ваша електронна пошта" type="email" class="email" required
                 v-model="registrationInfo.email"
                />
                <masked-input class="form-control phone" type="text" placeholder="Ваш номер телефону" 
                v-model.trim="registrationInfo.phone" required
                :mask="['38', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
                :guide="true"
                placeholderChar=" "> 
                </masked-input>

                <masked-input v-if="codeField" class="form-code" type="text" placeholder="Введіть код" 
                v-model.trim="registrationInfo.code" required
                :mask="[/\d/, /\d/, /\d/, /\d/]"
                :guide="true"
                placeholderChar=" "> 
                </masked-input>

                <span v-if="codeError" class="badge">Код невірний</span>

                <span v-if="messageVisible" class="message">Ми щойно надіслали вам SMS-код</span>
                <button type="button" id="defaultBtn" class="btn"
                @click="checkMethod"
                >{{btnValue}}</button>
                <div class="note">Дані не будуть передаватись 3-м особам</div>
            </form>
        </template>
    </RegistrationModal>


    <div class="result-modal" v-if="registrationDone">
        <div class="result-modal__wrapper">
            <div class="result-modal__body">
                <div class="result-modal__close">&times;</div>
            <div class="result-modal__text"> Код підтверджено <span class="icon-checkbox-checked"></span>
            </div>
             <router-link :to="{name: 'referral'}" class="result-modal__link">Перейти до сторінки реферала</router-link>
        </div>
    </div>
    </div> 

</main>
</template>

<script>
import OutlineBtn from "./OutlineBtn.vue";
import AccountLink from "./AccountLink.vue";
import ModalInput from "./ModalInput.vue"
import RegistrationModal from "../Modals/RegistrationModal.vue"

export default {
    data() {
        return {
            registrationInfo: {
                name: "",
                email: "",
                phone: "",
                code: ""
            },
            btnValue: "Зареєструватися",
            messageVisible: false,
            codeField: false,
            codeError: false,
            result: null,
            modalVisible: false,
            registrationDone: false
        }
    },
    methods: {
        showModal() {
            this.modalVisible = true;
        },

        checkMethod() {
            if(this.btnValue ===  "Зареєструватися") {
                this.getCode()
            } else if(this.btnValue ===  "Підтвердити код") {
                this.addAffiliate()
            }
        },

        addAffiliate() {
            this.axios.post(`${this.$domain}/affiliate`, {
                "phone": this.registrationInfo.phone,
                "name": this.registrationInfo.name,
                "email": this.registrationInfo.email,
                "code": this.registrationInfo.code
            },
            {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}` 
            }})
            .then(response => {
                if(response.data.message === "Wrong auth code") {
                    this.codeError = true;
                } else if(response.data.result === "success"){
                    this.registrationDone = true;
                    this.modalVisible = false;
                    localStorage.setItem("token", response.data.token);
                }
            })   
        },

        getCode() {
            this.axios.post(`${this.$domain}/sendAuthSms`, {
                phone: this.registrationInfo.phone
            })
            .then(response => {
                this.messageVisible = true;
                this.codeField = true;
                this.btnValue = "Підтвердити код";
                this.result = response.data.result
            })
            .catch(e => console.log(e))
            },
    },
    components: { OutlineBtn, AccountLink, ModalInput, RegistrationModal }
}
</script>


<style scoped>
@font-face {
  font-family: "GothamPro";
  src: url("../../assets/fonts/GothamPro-Light.eot?#iefix") format("embedded-opentype"),
  url("../../assets/fonts/GothamPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "GothamPro";
  src: url("../../assets/fonts/GothamPro.woff") format("woff"),
      url("../../assets/fonts/GothamPro.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "GothamPro";
  src: url("../../assets/fonts/GothamPro-Medium.woff") format("woff"),
      url("../../assets/fonts/GothamPro-Medium.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "GothamPro";
  src: url("../../assets/fonts/GothamPro-Bold.woff") format("woff"),
      url("../../assets/fonts/GothamPro-Bold.ttf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
    body {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }
    *{
        box-sizing: border-box;
        font-family: "GothamPro";
    }
    *:before, *:after {
        box-sizing: border-box;
    }
    .show {
        display: block;
    }
    .container{
        max-width: 1160px;
        padding: 0 30px;
        margin: 0 auto;
    }
    .main {
        padding-top: 45px;
        padding-bottom: 200px;
    }
    .main__logo {
        display: block;
        margin-bottom: 60px;
    }
    .main__logo img {
        max-width: 160px;
    }
    .main__grid {
        display: grid;
        grid-template: 0.8fr 90px 0.8fr/ 480px 1fr ;
        gap: 20px 70px;
    }
    .main__title {
        font-size: 40px;
        line-height: 48px;
        margin: 0;
        color: rgb(173, 196, 69);
        font-weight: 700;
        align-self: end;
    }
    .main__img {
        grid-row: 1/4;
    }
    .main__img img {
        max-width: 460px;
    }
    .main__descr {
        color: #fff;
        grid-row: 2/3;
        grid-column: 2/3;
        line-height: 21px;
        max-width: 510px;
        font-size: 18px;
    }
    .bold{
        color: rgb(173, 196, 69);
        font-weight: 700;
    }
    .main__links, .work__links {
        grid-row: 3/4;
        align-self: center;
        display: flex;
    }
    .main__link, .work__link {
        display: block;
        background-color: rgb(173, 196, 69);
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        border-radius: 30px;
        padding: 15px 50px;
        border: 2px solid rgb(173, 196, 69);
        cursor: pointer;
        transition: all linear .3s;
    }
    .main__link:hover, .work__link:hover {
        transform: scale(1.03);
        transition: all linear .3s;
    }
    .main__link.registration,  .work__link.registration{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 66px;
        background-color: transparent;
        color: rgb(173, 196, 69);
        margin-left: 15px;
    }
    .work__links {
        justify-content: center;
    }
    .work {
        padding-top: 65px;
        padding-bottom: 50px;
        background-color: #000;
    }
    .work__title {
        color: #fff;
        font-size: 40px;
        font-weight: 400;
        text-align: center;
        margin: 0 0 40px;
    }
    .work__grid {
        display: grid;
        grid-template: 1fr 1fr / 1fr 1fr 1fr;
        gap: 10px 50px;
        margin-bottom: 120px;
    }
    .work__item:nth-child(1), .work__item:nth-child(2), .work__item:nth-child(4) {
        position: relative;
    }

    .work__item:nth-child(1):after, .work__item:nth-child(2):after, .work__item:nth-child(4):after {
        position: absolute;
        content: "";
        width: 15px;
        height: 30px;
        background-image: url("../../assets/icons/affiliate/arrow.webp");
        background-repeat: no-repeat;
        top: 40%;
        right: -34px;
    }
    .work__item:last-child {
        grid-column: 3/4;
        position: relative;
        left: -160px;
    }
    .work__item:nth-child(4):after {
        right: -60px;
    }
    .work__item:nth-child(4) {
        position: relative;
        right: -160px;
    }
    .work__item-img {
        text-align: center;
        max-width: 100%;
    }
    .work__item-text {
        text-align: center;
        margin: 0;
        font-size: 16px;
        color: #fff;
    }
    .work__trigger {
        max-width: 600px;
        margin: 0 auto 85px;
    }
    .work__trigger-text {
        color: #fff;
        text-align: center;
        margin: 0 0 30px;
        line-height: 22px;
        font-size: 18px;
    }
    .work__bottom-picture {
        text-align: center;
    }
    .footer{
        background-color: rgb(29, 29, 29);
        padding-top: 50px;
        padding-bottom: 30px;
        color: rgb(158, 158, 156);
        font-size: 18px;
    }
    .footer__logo-img {
        max-width: 160px;
    }
    .footer__grid {
        display: grid;
        grid-template: 1fr 28px / 0.65fr 1.1fr 1fr;
        row-gap: 30px;
    }
    .footer__contacts-tel{
        display: block;
        color: #fff;
        text-decoration: none;
        font-size: 40px;
        margin-bottom: 20px;
    }
    .footer__contacts-tel span {
        font-weight: 700;
    }
    .footer__address-name {
        margin: 0 0 20px;
        font-size: 18px;
        color: rgb(251, 229, 23);
        font-weight: 700;
    }
    .footer__adrees-street {
        font-style: normal;
    }
    .footer__address-time {
        display: block;
    }
    .footer__copyright {
        font-size: 12px;
    }
    .footer__copyright span {
        display: block;
    }
    .divider {
        width: 22px;
        height: 3px;
        background-color: rgb(251, 229, 23);
        align-self: end;
    }
    #referral-modal {
        display: none;
    }
    #referral-modal.show {
        display: block;
    }
    .form-control {
        display: block;
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #726f6f;
        color: #000;
        font-size: 18px;
        margin-bottom: 15px;
    }
    .form-control::placeholder, .form-code::placeholder {
        /* font-family:"Gotham Pro"; */
        font-size: 16px;
        line-height: 1;
}
    .form-code {
        /* display: none; */
        max-width: 160px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #726f6f;
        color: #000;
        font-size: 18px;
        margin-bottom: 15px;
    }
    .form-code.show {
        display: inline-block;
    }
    .btn {
        background-color: #000;
        color: #fff;
        border-radius: 5px;
        width: 100%;
        padding: 10px;
        font-size: 18px;
        cursor: pointer;
        border: none;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    .note {
        color: #000;
        text-align: center;
        font-size: 12px;
    }
    .badge {
        /* display: none; */
        /* font-family: "Gotham Pro"; */
        font-weight: 300;
        font-size: 14px;
        color: red;
        font-weight: 700;
        margin-left: 10px;
    }
    .message {
        color: green; 
        display: block;
        font-weight: 700;
    }
    .result-modal {
        /* display: none; */
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
        color: #000;
    }
    .result-modal__wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        right: 0;
        bottom: 0;
        width: 400px;
        min-height: 150px;
        height: 150px;
        transform: translate(-50%, -50%);
        background-color: #fff;
        padding: 15px;
        border: 1px solid #000;
    }

    .result-modal__body {
        padding-top: 25px;
    }

    .result-modal__text {
        text-align: center;
        margin-bottom: 10px;
        position: relative;
        left: -7px;
    }

    .result-modal__link {
        display: block;
        text-align: center;
        color: #000;
        text-decoration: none;
        font-size: 15px;
        font-weight: 300;
        transition: font-size .5s ease;
    }

    .result-modal__link:hover {
        color: #000;
        font-size: 16px;
        transition: font-size .5s ease;
    }

    .result-modal__close {
        position: absolute;
        cursor: pointer;
        top: 2px;
        right: 10px;
        font-size: 25px;
    }
    .result-modal.show {
        display: block;
    }

    @media screen and (max-width: 1120px) {
        .main__img img {
            max-width: 410px;
        }
        .main__grid {
            column-gap: 45px;
            grid-template: 0.8fr 90px 0.8fr/ 400px 1fr;

        }
    }
    @media screen and (max-width: 991px) {
        .container {
            padding: 0 100px;
        }
        .main {
            padding-bottom: 107px;
        }
        .main__grid {
            display: block;
        }
        .main__logo {
            margin-bottom: 38px;
        }
        .main__title {
            margin-bottom: 30px;
        }
        .main__logo, .main__title, .main__img, .main__descr{
            text-align: center;
        }
        .main__img {
            margin-bottom: 30px;
        }
        .main__img img {
            max-width: 375px;
        }
        .main__descr {
            margin-bottom: 50px;
        }
        .main__descr, .main__links  {
            margin-left: auto;
            margin-right: auto;
        }
        .main__links {
            justify-content: center;
        }
        .work__title {
            margin-bottom: 30px;
        }
        .work__grid {
            grid-template: 1fr 1fr 1fr / 1fr 1fr;
            margin-bottom: 25px;
        }
        .work__item:last-child {
            grid-column: 1/2;
            left: 55%
        }
        .work__item:nth-child(2):after, .work__item:nth-child(4):after {
            display: none;
        }
        .work__item:nth-child(2), .work__item:nth-child(4){
            position: static;
        }
        .work__item:nth-child(3) {
            position: relative;
        }
        .work__item:nth-child(3):after{
            position: absolute;
            content: "";
            width: 15px;
            height: 30px;
            background-image: url("../../assets/icons/affiliate/arrow.webp");
            background-repeat: no-repeat;
            top: 37%;
            right: -30px;
        }
        .work__trigger-text {
            margin-bottom: 45px;
        }
        .footer__grid {
            grid-template: 51px 1fr 5px 20px / 1fr 1fr;
            gap: 25px 20px;

        }
        .footer__logo{
            grid-column: 1/3;
        }
        .footer__logo-img{
            display: block;
            margin: 0 auto;
        }
        .footer__copyright {
            grid-row: 4/5;
            grid-column: 1/3;
            text-align: center;
        }
        .divider {
            grid-row: 3/4;
        }
        .form-control, .form-code {
            font-size: 16px;
        }
    }
    @media screen and (max-width: 810px)  {
        .container{
            padding: 0 30px;
        }
    }
    @media screen and (max-width: 675px) {
        .footer__grid {
            grid-template: 65px 110px 45px 120px 30px 1fr / 1fr;
            row-gap: 10px;
        }
        .footer__logo {
            grid-column: 1/2;
            grid-row: 1/2;
        }
        .footer__logo-img {
            margin-left: 0;
        }
        .footer__contacts {
            grid-column: 1/2;
            grid-row: 2/3;
        }
        .divider-1 {
            grid-column: 1/2;
            grid-row: 3/4;
            align-self: start;
        }
        .footer__address {
            grid-column: 1/2;
            grid-row: 4/5;
        }
        .divider-2 {
            grid-column: 1/2;
            grid-row: 5/6;
            align-self: start;
        }
        .footer__copyright {
            grid-column: 1/2;
            grid-row: 6/7;
            text-align: left;
        }
        .footer__contacts-tel {
            margin-bottom: 5px;
            font-size: 30px;
        }
        .footer__address-name {
            margin-bottom: 25px;
        }
    }
    @media screen and (max-width: 639px) {
        .work__item-text {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 550px) {
        .main__links, .work__links {
            max-width: 245px;
            flex-direction: column;
            align-items: center;
        }
        .main__link, .work__link  {
            width: 100%;
        }
        .work__links  {
            margin-left: auto;
            margin-right: auto;
        }
        .main__link.arears, .work__link.arears {
            display: block;
            margin-bottom: 15px;
        }
        .main__link.registration, .work__link.registration {
            margin-left: 0;
        }
        .form-control, .form-code {
            padding: 7px;
        }
        .form-code {
            max-width: 120px;
        }
        .form-control, .form-code {
            font-size: 14px;
        }
        .form-control::placeholder, .form-code::placeholder {
            font-family: "GothamPro";
            font-size: 13px;
        }
        .badge {
            font-size: 12px;
        }
        .btn {
            margin-top: 10px;
            font-size: 15px;
            margin-bottom: 8px;
        }
    }
    @media screen and (max-width: 546px) {
        .main__title {
            font-size: 32px;
        }
    }
    @media screen and (max-width: 510px)  {
        .work__item-img img {
            max-width: 150px;
        }
    }
    @media screen and (max-width: 479px)  {
        .main {
            padding-top: 25px;
            padding-bottom: 55px;
        }
        .main__logo {
            margin-bottom: 25px;
        }
        .main__logo img {
            max-width: 100px;
        }
        .main__title {
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 20px;
        }
        .main__img img {
            max-width: 253px;
        }
        .main__descr {
            font-size: 16px;
            line-height: 20px;
            max-width: 360px;
            margin-bottom: 25px
        }
        .work {
            padding-top: 40px;
            padding-bottom: 60px;
        }
        .work .container {
            padding: 0 20px
        }
        .work__title {
            font-size: 26px;
            margin-bottom: 40px;
        }
        .work__item:nth-child(1):after, .work__item:nth-child(3):after {
            background-size: 12px 24px;
            top: 27%;
            right: -27px;
        }
        .work__item-text {
            line-height: 16px;
        }
        .work__grid {
            margin-bottom: 15px;
            column-gap: 30px;
        }
        .work__trigger {
            margin-bottom: 70px;
        }
        .work__trigger-text {
            font-size: 16px;
            line-height: 20px;
            max-width: 320px;
            margin-left: auto;
            margin-right: auto;
        }
        .work__bottom-picture img {
            max-width: 166px;
        }
    }
    @media screen and (max-width: 374px) {
        .container {
            padding: 0 15px;
        }
        .work__item-img img {
            max-width: 120px ;
        }
        .work__title {
            font-size: 22px;
        }
    }
</style>