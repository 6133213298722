<template>
  <div class="promo">
    <p
      class="intro__title line line--half-border"
      :class="{
        'line--smart': isServiceSmart,
        'line--sos': isServiceSOS,
        'line--transfer': service === 'transfer',
      }"
    >
      {{ getTitle }}
    </p>
    <p
      class="intro__subtitle line line--half-border"
      :class="{
        'line--smart-bottom': isServiceSmart,
        'line--sos-bottom': isServiceSOS,
        'line--transfer-bottom': service === 'transfer',
      }"
    >
      {{ getSubTitle }}
    </p>
    <div class="hero" :class="{ 'hero--transfer': service === 'transfer' }">
      <div>
        <p v-if="service == 'sos'" class="intro__description">
          тривожна кнопка в смартфоні
        </p>
        <p
          class="intro__slide-description intro__slide-description--desktop"
          v-if="service === 'sos'"
        >
          {{
            "Підключайтесь до особистої охорони та викликайте екстрену групу SHERIFF одним свайпом. Будьте впевнені у безпеці за себе та своїх рідних 24/7!"
          }}
        </p>
        <p
          class="intro__slide-description intro__slide-description--desktop"
          v-else-if="service === 'smart-security'"
        >
          {{
            "Підключайтесь до охорони без додаткового обладнання та викликайте патруль реагування SHERIFF на об'єкт в 1 свайп"
          }}
        </p>
        <p
          class="intro__slide-description intro__slide-description--desktop"
          v-else-if="service === 'transfer'"
        >
          {{
            "Послуга супроводу екіпажем SHERIFF з професійними охоронцями ЦІЛОДОБОВО для безпечного перевезення Вас та ваших цінностей"
          }}
        </p>
        <a
          :href="serviceDeeplink"
          class="intro__button intro__button--desktop"
          :class="{ 'intro__button--m1': service === 'smart-security' }"
          v-if="userAgent == 'mobile'"
        >
          Перейти у додаток
          <svg
            style="margin-left: 4px;"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12.329 5.20401C12.54 4.99333 12.8259 4.875 13.124 4.875C13.4222 4.875 13.7081 4.99333 13.919 5.20401L20.294 11.579C20.5047 11.7899 20.623 12.0759 20.623 12.374C20.623 12.6721 20.5047 12.9581 20.294 13.169L13.919 19.544C13.7056 19.7426 13.4236 19.8508 13.1321 19.8457C12.8406 19.8407 12.5624 19.7229 12.356 19.517C12.1502 19.3106 12.0323 19.0324 12.0273 18.741C12.0223 18.4495 12.1304 18.1674 12.329 17.954L16.784 13.499L5.62404 13.499C5.32567 13.499 5.03952 13.3805 4.82854 13.1695C4.61756 12.9585 4.49904 12.6724 4.49904 12.374C4.49904 12.0756 4.61756 11.7895 4.82854 11.5785C5.03952 11.3675 5.32567 11.249 5.62404 11.249L16.784 11.249L12.329 6.79401C12.1184 6.58307 12 6.29714 12 5.99901C12 5.70088 12.1184 5.41495 12.329 5.20401Z"
              fill="white"
            />
          </svg>
        </a>
        <div v-if="userAgent == 'desktop'" class="store">
          <p class="store__title">Завантажуйте зараз</p>
          <div class="store__links">
            <div>
              <a :href="getWebStoreLinks['appStore']"
                ><img
                  :src="require('@/assets/icons/promoService/appStore.svg')"
                  alt="appStore"
              /></a>
              <a :href="getWebStoreLinks['appStore']"
                ><img
                  :src="require('@/assets/icons/promoService/playMarket.svg')"
                  alt="appStore"
              /></a>
            </div>
            <qr-code
              v-if="userAgent == 'desktop'"
              :text="deeplink"
              class="qr qr--mobile"
              error-level="H"
            ></qr-code>
          </div>
        </div>
      </div>
      <qr-code
        v-if="userAgent == 'desktop'"
        :text="deeplink"
        class="qr qr--desktop"
      ></qr-code>
    </div>
    <div class="intro__slide-img intro__slide-img--desktop">
      <object
        v-if="service !== 'transfer'"
        :data="require(`@/assets/icons/promoService/${service}.svg`)"
        type="image/svg+xml"
      >
        <img
          :src="require(`@/assets/icons/promoService/${service}.svg`)"
          alt="Fast Reaction"
        />
      </object>
    </div>
    <div class="intro">
      <div
        class="intro__slide"
        :class="{ 'intro__slide--mt': service === 'smart-security' }"
      >
        <p
          class="intro__slide-description intro__slide-description--mobile"
          v-if="service === 'sos'"
        >
          {{
            "Підключайтесь до особистої охорони та викликайте екстрену групу SHERIFF одним свайпом. Будьте впевнені у безпеці за себе та своїх рідних 24/7!"
          }}
        </p>
        <p
          class="intro__slide-description intro__slide-description--mobile"
          v-else-if="service === 'smart-security'"
        >
          {{
            "Підключайтесь до охорони без додаткового обладнання та викликайте патруль реагування SHERIFF на об'єкт в 1 свайп"
          }}
        </p>
        <p
          class="intro__slide-description intro__slide-description--mobile"
          v-else-if="service === 'transfer'"
        >
          {{
            "Послуга супроводу екіпажем SHERIFF з професійними охоронцями ЦІЛОДОБОВО для безпечного перевезення Вас та ваших цінностей"
          }}
        </p>
        <div
          v-if="service !== 'transfer'"
          class="intro__slide-img intro__slide-img--mobile"
        >
          <object
            :data="require(`@/assets/icons/promoService/${service}.svg`)"
            type="image/svg+xml"
          >
            <img
              :src="require(`@/assets/icons/promoService/${service}.svg`)"
              alt="Fast Reaction"
            />
          </object>
        </div>
      </div>
      <a
        :href="serviceDeeplink"
        class="intro__button intro__button--mobile"
        :class="{ 'intro__button--m1': service === 'smart-security' }"
      >
        Перейти у додаток
        <svg
          style="margin-left: 4px;"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12.329 5.20401C12.54 4.99333 12.8259 4.875 13.124 4.875C13.4222 4.875 13.7081 4.99333 13.919 5.20401L20.294 11.579C20.5047 11.7899 20.623 12.0759 20.623 12.374C20.623 12.6721 20.5047 12.9581 20.294 13.169L13.919 19.544C13.7056 19.7426 13.4236 19.8508 13.1321 19.8457C12.8406 19.8407 12.5624 19.7229 12.356 19.517C12.1502 19.3106 12.0323 19.0324 12.0273 18.741C12.0223 18.4495 12.1304 18.1674 12.329 17.954L16.784 13.499L5.62404 13.499C5.32567 13.499 5.03952 13.3805 4.82854 13.1695C4.61756 12.9585 4.49904 12.6724 4.49904 12.374C4.49904 12.0756 4.61756 11.7895 4.82854 11.5785C5.03952 11.3675 5.32567 11.249 5.62404 11.249L16.784 11.249L12.329 6.79401C12.1184 6.58307 12 6.29714 12 5.99901C12 5.70088 12.1184 5.41495 12.329 5.20401Z"
            fill="white"
          />
        </svg>
      </a>
      <div
        v-if="service !== 'transfer'"
        class="scroll-circle"
        @click="scrollToSection"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M5 5L12 12L19 5"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5 12L12 19L19 12"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div
      class="guide"
      v-if="$route.params.service !== 'transfer'"
      ref="sectionToScroll"
    >
      <div class="guide__flex">
        <p class="guide__title line"><span>Відеоінструкція</span></p>
        <p class="guide__subtitle line">
          <span>з підключення</span>
        </p>
      </div>
      <div class="container">
        <img
          class="guide__arrow"
          :src="require('@/assets/icons/promoService/pig_arrow.svg')"
          alt="arrow"
        />
        <div class="guide__video">
          <div class="guide__video-wrapper">
            <video
              width="220"
              height="312"
              style="width: 100%;"
              controls
              :poster="
                isServiceSOS
                  ? require('@/pages/Promo/Services/sos_poster.png')
                  : require('@/pages/Promo/Services/smart_poster.png')
              "
            >
              <source
                :src="
                  isServiceSOS
                    ? require('@/pages/Promo/Services/sos_tutorial.mp4')
                    : require('@/pages/Promo/Services/smart_tutorial.mp4')
                "
                type="video/mp4"
              />
            </video>

            <!-- <iframe
              width="220"
              height="312"
              :src="serviceVideo"
              :class="{ fullscreen: clicked }"
              title="Інструкція послуги в додатку MySheriff"
              frameborder="0"
              allowfullscreen
            ></iframe> -->
            <div class="cirle"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="promo__footer-form promo__footer-form--desktop">
        <div class="promo__footer-form-wrapper">
          <h4 class="promo__footer-title">Залишились питання?</h4>
          <p class="promo__footer-description">
            Залиште свої контакти та отримайте безкоштовну консультацію від
            нашого спеціаліста
          </p>
        </div>
        <form @submit.prevent="submit">
          <div class="promo__footer-form-inputs">
            <input
              type="text"
              v-model="form.name"
              name="leadName"
              placeholder="Ваше ім'я"
              class="promo__footer-form-input"
            />
            <input
              type="tel"
              v-model="form.phone"
              name="leadPhone"
              placeholder="+380 (99) 999-99-99"
              v-mask="'38##########'"
              class="promo__footer-form-input"
            />
          </div>
          <button type="submit" class="promo__footer-form-submit">
            Надіслати
          </button>
        </form>
      </div>
    </div>

    <footer class="promo__footer">
      <div class="container">
        <div class="promo__footer-form promo__footer-form--mobile">
          <h4 class="promo__footer-title">Залишились питання?</h4>
          <p class="promo__footer-description">
            Залиште свої контакти та отримайте безкоштовну консультацію від
            нашого спеціаліста
          </p>
          <form @submit.prevent="submit">
            <div class="promo__footer-form-inputs">
              <input
                type="text"
                v-model="form.name"
                name="leadName"
                placeholder="Ваше ім'я"
                class="promo__footer-form-input"
              />
              <input
                type="tel"
                v-model="form.phone"
                name="leadPhone"
                placeholder="+380 (99) 999-99-99"
                v-mask="'38##########'"
                class="promo__footer-form-input"
              />
            </div>
            <button type="submit" class="promo__footer-form-submit">
              Надіслати
            </button>
          </form>
        </div>

        <div class="promo__footer-contacts">
          <div class="promo__footer-contacts-wrapper">
            <img
              class="promo__footer-contacts-logo"
              :src="require('@/assets/icons/promoService/logo.svg')"
              alt="Logo"
            />
            <a href="tel:0800309114" class="promo__footer-contacts-phone"
              >0 800 309 114</a
            >
            <p>відділ продажів (безкоштовно)</p>
          </div>
          <div class="promo__footer-contacts office">
            <h5 class="office__title">
              Головний офіс ХОП “Шериф”
            </h5>
            <p class="office__location">
              Київ, вул. Сосницька 1/44
              <br />
              Офіс: 9:00 - 18:00
            </p>
            <p class="office__support">
              Технічна служба: Цілодобово
            </p>
          </div>
        </div>
        <div class="promo__footer-copyright">
          <p>
            © 2007-{{ getCurrentYear }} ТОВ "SHERIFF" - Всі права захищені.
            <br />
            Копіювання матеріалів тільки з посиланням на сайт
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  props: ["service"],
  beforeCreate() {
    // GTM-KX9QMP7H - GTM WITHOUT PROMOCODE
    // GTM-W49DHD5P - GTM WITH PROMOCODE
    const GTM_SCRIPT = document.createElement("script");
    GTM_SCRIPT.type = "text/javascript";
    GTM_SCRIPT.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${
      this.$route.query.deeplink ? "GTM-W49DHD5P" : "GTM-KX9QMP7H"
    }');
`;
    document.head.appendChild(GTM_SCRIPT);
    const GTM_NOSCRIPT = document.createElement("noscript");
    const iframe = document.createElement("iframe");
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${
      this.$route.query.deeplink ? "GTM-W49DHD5P" : "GTM-KX9QMP7H"
    }`;
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    GTM_NOSCRIPT.appendChild(iframe);
    document.body.insertBefore(GTM_NOSCRIPT, document.body.firstChild);
  },
  data() {
    return {
      userAgent: null,
      deeplink: "",
      form: {
        name: "",
        phone: "",
      },
    };
  },
  created() {
    this.checkDeviceType();
    if (this.$route.query.deeplink) {
      this.deeplink = atob(this.$route.query.deeplink).padEnd(220);
      return;
    }
    this.deeplink =
      "https://mysheriffapp.page.link/?link=https%3A%2F%2Fmy.sheriff.com.ua%2F%3Fnavigate%3Dsos_feature&apn=com.sheriff.app&isi=1589945728&ibi=com.sheriff.MySheriff&efr=1&_imcp=1";
  },

  methods: {
    checkDeviceType() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.userAgent = "mobile";
        return;
      }
      this.userAgent = "desktop";
    },
    submit() {
      if (this.form.name && this.form.phone.length >= 11) {
        this.axios
          .post(`${this.$domain}/crm/handleQuestions`, {
            api_token: "uy3RCkMkWN8Mvp3BAhN8g94sq9",
            ...this.form,
            service: this.service,
          })
          .then(({ data: { result } }) => {
            if (result === "success") {
              this.$swal.fire({
                icon: "success",
                html: `<span style="color: #fff;">Дякуємо, ваша заявка прийнята!
<br/> Менеджер зв'яжеться з вами у найближчий час.</span>`,
                confirmButtonText: "Закрити",
              });
            }
          });
      }
    },
    scrollToSection() {
      const section = this.$refs.sectionToScroll;
      const topOffset = section.offsetTop;
      window.scrollTo({
        top: topOffset,
        behavior: "smooth",
      });
    },
  },
  computed: {
    getTitle() {
      switch (this.$route.params.service) {
        case "smart-security":
          return "Smart";
        default:
          return "Sheriff";
      }
    },
    getSubTitle() {
      switch (this.$route.params.service) {
        case "smart-security":
          return "Захист";
        case "sos":
          return "SOS";
        case "transfer":
          return "Transfer";
        default:
          return this.$route.params.service;
      }
    },
    getServiceName() {
      return 1;
    },
    getWebStoreLinks() {
      return {
        appStore:
          "https://apps.apple.com/ua/app/mysheriff-%D0%BF%D0%BE%D1%81%D0%BB%D1%83%D0%B3%D0%B8-%D0%BE%D1%85%D0%BE%D1%80%D0%BE%D0%BD%D0%B8-24-7/id1589945728",
        playMarket:
          "https://play.google.com/store/apps/details?id=com.sheriff.app",
      };
    },
    serviceDeeplink() {
      if (this.deeplink) {
        return this.deeplink;
      }
      switch (this.service) {
        case "smart-security":
          return "https://mysheriffapp.page.link/?link=https%3A%2F%2Fmy.sheriff.com.ua%2F%3Fnavigate%3Dsmart_security&apn=com.sheriff.app&isi=1589945728&ibi=com.sheriff.MySheriff&efr=1&_imcp=1";
        case "sos":
        default:
          return "https://mysheriffapp.page.link/?link=https%3A%2F%2Fmy.sheriff.com.ua%2F%3Fnavigate%3Dsos_feature&apn=com.sheriff.app&isi=1589945728&ibi=com.sheriff.MySheriff&efr=1&_imcp=1";
      }
    },
    // serviceVideo() {
    //   if (this.isServiceSOS) {
    //     return "https://www.youtube.com/embed/Jf9Npm0H2Fg";
    //   }
    //   return "https://www.youtube.com/embed/VCB8c6nIUOM";
    // },
    getCurrentYear() {
      return new Date().getFullYear();
    },
    isServiceSOS() {
      return this.service === "sos";
    },
    isServiceSmart() {
      return this.service === "smart-security";
    },
  },
};
</script>

<style scoped lang="scss">
@import "./promo.scss";
</style>
