<template>
    <div class="object">
        <div class="object__header">
            Об'єкт №{{object.pult_number}}
        </div>
        <div class="object__body">
            <div class="object__body-img">
                <img src="../../assets/icons/menu-item/object.png" alt="">
            </div>
            <address class="object__body-address">{{object.address}}</address>
            <div class="object__body-info">
                <div class="object__body-number">
                    <span> {{object.contract_docnumber}}</span>
                    Номер договору
                </div>
                <div class="object__body-controlpanel">
                    <span>{{object.pult_number}}</span>
                    Пультовий номер
                </div>
                 <div class="object__body-equipment">
                    <span>{{object.model_ppk}}</span>
                    Модель ППК
                </div>
            </div>
        </div>
        <router-link :to="{name: 'object', params:{ id: object.contract_docnumber }}" class="object__footer">
            <div class="object__footer-trigger">
                Переглянути
                <i class="fas fa-angle-right"></i>
                </div>
        </router-link>
    </div>
</template>
<script>    
export default {
    props: ["object"],
}
</script>
<style scoped lang="scss">

$dark: rgb(28, 28, 28);
.object {
    &__header {
        background-color: $dark;
        padding: 15px;
        border-radius: 6px 6px 0 0;
        font-weight: 600;
    }
    &__body { 
        padding: 15px;
        background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../assets/img/card-object.jpg");
        background-repeat: no-repeat;
        background-size: cover; 
        text-align: center;
        background-position: bottom;
        &-img {
           margin: 8px auto 10px;
            width: 85px;
          
            height: 85px; 
            & img {
                max-width: 100%;
                display: block;
            }
        }
        &-address {
           border-bottom: 1px solid rgba(255, 255, 255, 0.3);
           font-style: normal;
           font-weight: 700;
           padding-bottom: 20px;
        }
        &-info {
            padding-top: 15px;
            padding-right: 20px;
            padding-left: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &-number, &-controlpanel, &-equipment {
            font-size: 12px;
            color: rgb(179, 179, 179);
            span {
                display: block;
                color: #fff;
                font-weight: 700;
                font-size: 16px;
            }
        }
    }
    &__footer{
        display: block;
        text-decoration: none;
        background-color: #fff;
        color: #000;
        border-radius: 0 0 6px 6px;
        padding: 14px;
        &-trigger {
            padding-left: 55px;
            line-height: 40px;
            position: relative;
            font-weight: 700;
            &:before {
                position: absolute;
                content: "";
                width: 40px;
                height: 40px;
                background-image: url("../../assets/icons/menu-item/view.png");
                background-size: 40px 40px;
                left: 0;
            }
            & .fa-angle-right {
                position: absolute;
                color: #e6e6e6;
                top: calc(50% - 10px);
                right: 0;
                font-size: 18px;

            }
        }
    }
}
</style>
