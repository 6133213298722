<template>
  <div class="news">
    <slot name="header"></slot>
    <div class="news__grid">
      <iframe
        class="news__grid-item"
        v-for="(link, i) of videoLinks"
        :key="i"
        :src="link"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  props: ["videoLinks"],
};
</script>

<style scoped lang="scss">
.news {
  padding: 15px;
  background-color: #fff;
  border-radius: 6px;
  &__youtube {
    color: #007aff;
    font-weight: 700;
    text-decoration: none;
    display: block;
  }
  &__date {
    color: rgb(128, 128, 128);
    font-size: 12px;
    display: block;
    margin-bottom: 15px;
  }
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 8px;
    margin-top: 16px;
    font-size: 16px;
    text-decoration: none;
    color: #121212;
    background-color: #fce500;
    border-radius: 20px;
    transition: all 0.4s ease;
    &:hover {
      background-color: #d4c715;
    }
  }
  &__grid {
    display: grid;
    grid-template: 1fr 1fr / 1.8fr 1fr;
    gap: 3px;
    height: max-content;
    @media screen and (max-width: 1439px) {
      height: 300px;
    }
    &-item {
      border: none;
      &:first-child {
        grid-row: 1/3;
        width: 100%;
        height: 100%;
      }
      &:nth-child(2) {
        grid-row: 1/2;
        grid-column: 2/3;
        width: 100%;
        height: 100%;
      }
      &:nth-child(3) {
        grid-row: 2/3;
        grid-column: 2/3;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>