<template>
    <button type="button" class="yellow-btn" @click="$emit('clickBtn')"><slot></slot></button>
</template>

<script>
export default{
    props: { 
        authType: {
            type: String, 
            default: ""
        }
       
    },
    methods: {
        clickBtn() {
            this.$emit("clickBtn", this.authType)
        }
    }
}
</script>
<style scoped lang="scss">
.yellow-btn {
    color: #212529;
    background-color: #fae615;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    min-width: 120px;
    font-family: "GothamPro";
    font-size: 14px;
    font-weight: 700;
    &:hover {
        background-color: #d4c715;
    }
}
</style>

