<template>
  <div class="auth">
    <div class="auth__wrapper">
      <div class="auth__logo">
        <img
          class="auth__logo-img"
          src="@/assets/icons/logo.png"
          alt="sheriff logo"
        />
      </div>
      <h1 class="auth__title">Авторизація</h1>
      <form class="auth__form">
        <p class="auth__code-send" v-if="codeWasSend">{{ codeWasSend }}</p>
        <input
          class="auth__input"
          id="auth__input"
          placeholder="380_________"
          v-model.trim="authPhone"
        />
        <span
          class="auth__message"
          v-if="message"
          :class="{ warning: warning }"
          >{{ message }}</span
        >
        <input
          class="auth__input"
          :type="inputType"
          :placeholder="inputPlaceholder"
          v-model.number="authCodeOrPass"
          v-if="logIn"
          :maxlength="maxSmsLength"
          @keypress="isNumber($event)"
        />
        <div class="auth__btns" :class="authButtonsDisplay">
          <YellowButton
            class="radius"
            :class="authButtonsDisplay"
            @clickBtn="activeBtn('sms')"
            >Вхід по СМС</YellowButton
          >
          <YellowButton
            class="radius"
            :class="authButtonsDisplay"
            @clickBtn="activeBtn('password')"
            >Вхід за паролем</YellowButton
          >
        </div>
        <YellowButton
          class="radius"
          v-show="logIn"
          :authType="authType"
          @clickBtn="auth"
          >Вхід</YellowButton
        >
      </form>
      <div class="auth__additionally" v-if="logIn">
        <div>
          <span class="auth__additionally-title">Некоректний номер? </span>
          <span class="auth__additionally-trigger" @click="editPhone"
            >Натисніть тут</span
          >
        </div>
        <div class="auth__additionally-repeatcode" v-if="authType === 'sms'">
          <span class="auth__additionally-title">SMS не надійшла? </span>
          <span class="auth__additionally-trigger" @click="sendAuthSms"
            >Надіслати ще раз</span
          >
        </div>
      </div>
      <!--<router-link to="/registration" class="auth__to-registration">Зареєструватися</router-link> -->
      <!-- <span>Зареєструватися</span> -->
    </div>
  </div>
</template>

<script>
import YellowButton from "../../components/Buttons/YellowButton.vue";
import Inputmask from "inputmask";

export default {
  created() {
    if (this.$route.query.navigate === 'sos_feature') {
      this.$router.push('promo/sos');
    } 
  },
  components: {
    YellowButton,
  },
  data() {
    return {
      smsButton: false,
      passwordButton: false,
      inputType: "text",
      logIn: false,
      authPhone: "",
      authCodeOrPass: "",
      inputPlaceholder: "",
      token: "",
      authType: "",
      authButtonsDisplay: "",
      codeWasSend: "",
      message: "",
      warning: false,
      maxSmsLength: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (localStorage.getItem("user") !== null) {
        vm.$router.push("/dashboard");
      }
    });
  },
  mounted() {
    new Inputmask("380999999999").mask(document.getElementById("auth__input"));
  },
  methods: {
    activeBtn(authType) {
      this.authType = authType;

      if (this.authPhone) {
        if (authType === "sms") {
          this.inputPlaceholder = "SMS XXXX";
          this.inputType = "text";
          this.sendAuthSms();
        } else if (authType === "password") {
          this.inputPlaceholder = "пароль";
          this.inputType = "password";
          this.checkUser();
        }
      }
    },
    sendAuthSms() {
      this.axios
        .post(`${this.$domain}/sendAuthSms`, {
          phone: this.authPhone,
        })
        .then((response) => {
          if (response.data.result === "success") {
            this.message = "Ми щойно надіслали вам смс з кодом авторизації";
            this.logIn = true;
            this.authButtonsDisplay = "d-none";
            this.maxSmsLength = 4;
          } else if (response.data.result === "failed") {
            if (
              response.data.message ===
              "Less than two minutes have passed since the previous SMS"
            ) {
              this.codeWasSend =
                "SMS повідомлення вже відправлено. Наступний раз код підтвердження можливо відправити через 2 хвилини";
              this.logIn = true;
              this.authButtonsDisplay = "d-none";
              this.maxSmsLength = 4;
            } else if (response.data.message === "user not found") {
              this.message =
                "На жаль, у системі немає облікового запису з таким номером телефону";
            }
          }
        })
        .catch((e) => console.log(e));
    },
    authBySms() {
      this.axios
        .post(`${this.$domain}/authBySms`, {
          phone: this.authPhone,
          code: this.authCodeOrPass,
        })
        .then((response) => {
          if (response.data.result === "success") {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            this.$router.push({ path: "/dashboard" });
          } else if (response.data.result === "failed") {
            this.authCodeOrPass = "";

            if (response.data.message === "Code is not correct") {
              this.message = "Невірний код підтвердження";
              this.warning = true;
            }
          }
        })
        .catch((e) => console.log(e));
    },
    checkUser() {
      this.authButtonsDisplay = "d-none";
      this.logIn = true;

      this.axios
        .post(`${this.$domain}/checkUser`, {
          phone: this.authPhone,
        })
        .then((response) => {
          if (response.data.result === "success") {
            this.passwordButton = true;
          } else if (response.data.result === "failed") {
            if (response.data.message === "password is not set") {
              this.message =
                "Пароль не встановлено. Вам відправлено код підтвердження";
              this.authType = "sms";
              this.authButtonsDisplay = "d-none";
              this.inputPlaceholder = "SMS XXXX";
              this.logIn = true;
              this.sendAuthSms();
            } else if (response.data.message === "user not found") {
              this.message =
                "На жаль, у системі немає облікового запису з таким номером телефону";
              this.authPhone = this.authType = "";
            }
          }
        })
        .catch((e) => console.log(e));
    },
    authByPassword() {
      this.axios
        .post(`${this.$domain}/authByPassword`, {
          phone: this.authPhone,
          password: this.authCodeOrPass,
        })
        .then((response) => {
          if (response.data.result === "success") {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.user));

            this.$router.push({ path: "/dashboard" });
          } else if (response.data.result === "failed") {
            if (response.data.message === "Password is not correct") {
              this.message = "Пароль невірний";
              this.warning = true;
            }
          }
        })
        .catch((e) => console.log(e));
    },
    auth() {
      this.authType === "sms" ? this.authBySms() : this.authByPassword();
    },
    editPhone() {
      this.authPhone =
        this.authButtonsDisplay =
        this.codeWasSend =
        this.message =
        this.authCodeOrPass =
          "";
      this.logIn = this.warning = false;
    },
    isNumber(event) {
      if (this.authType === "sms") {
        if (event.keyCode >= 48 && event.keyCode <= 57) {
          return true;
        } else {
          event.preventDefault();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.auth {
  background-color: #000;
  color: #fff;
  width: 100%;
  height: 100vh;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__wrapper {
    margin: 0 auto 100px;
    max-width: 520px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__logo {
    width: 150px;
    margin-bottom: 10px;

    &-img {
      max-width: 100%;
    }
  }
  &__title {
    font-size: 26px;
    font-weight: 500;
    line-height: 34px;
    display: inline-block;
    margin: 0 0 15px;
    padding-bottom: 5px;
    border-bottom: 0.125rem solid rgba(255, 255, 255, 0.5);
  }
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__input {
    background-color: rgb(87, 86, 86);
    color: #fff;
    border: 2px solid transparent;
    border-radius: 6px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    display: block;
    outline: none;
    text-align: center;
    margin-bottom: 20px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.2);
    }
    &:focus {
      border-color: #fff;
    }
  }
  &__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 310px;
  }
  &__message {
    text-align: center;
    margin-bottom: 10px;
  }
  &__code-send {
    margin: 0 0 10px;
    text-align: center;
  }
  &__additionally {
    margin-top: 30px;

    &-title {
      color: rgba(255, 255, 255, 0.5);
    }
    &-trigger {
      text-decoration: underline;
      cursor: pointer;
    }
    &-repeatcode {
      margin-top: 10px;
    }
  }
  &__to-registration {
    display: block;
    margin-top: 20px;
    color: #fff;
  }
}
.d-none {
  display: none;
}
.warning {
  color: red;
}
</style>
