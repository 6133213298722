<template>
  <div class="form-referral">
    <div class="form-referral__wrapper">
      <div class="form-referral__content">
        <div class="form-referral__aside" v-if="!onMobile">
          <div class="form-referral__logo">
            <img src="https://sheriff.com.ua/wp-content/uploads/2019/05/sheriff_logo.svg" data-src="https://sheriff.com.ua/wp-content/uploads/2019/05/sheriff_logo.svg" alt="logo">
          </div>
          <span class="title">Виникли питання?</span>
          <a href="tel:0800211780" class="phone"><strong>0 800</strong> 211 780</a>
          <span class="text">безкоштовно</span>
        </div>
        <div v-if="referralAdded !== true" class="form-referral__main">
          <h5 class="form-referral__payment">Ваша гарантована виплата 250&nbsp;гривень</h5>
          <!-- <div id="zayavka_error">Виникла невідома помилка</div> -->
          
           <form id="zayavka_form" class="form-referral__form">
              <h4 class="form-referral__form-title">Вкажіть ваші контактні дані:</h4>
              <div class="form-referral__inner">
                <label for="inputName" class="form-referral__label">
                  <i class="fa fa-user"></i>
                </label>
                <ReferralInput class="form-referral__field" :class="{'form-referral__error': error.name}"  id="inputName" autocomplete="off"
                placeholder="Ваше ім'я" maxlength="32" name="name" v-model="referralInfo.name"/>
                <span v-if="error.name" :class="{'form-referral__error-text': error.name}">Будь ласка, вкажіть ім'я</span>
              </div>
              <div class="form-referral__inner">
                <label for="inputPhone" class="form-referral__label">
                  <i class="fa fa-phone"></i>
                </label>
                <masked-input class="form-referral__field"  id="inputPhone" type="text" placeholder="Ваш номер телефону" name="phone" 
                v-model.trim="referralInfo.phone" :class="{'form-referral__error': error.phone}"
                :mask="['38', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
                maxlength="12"
                :guide="true"
                placeholderChar=" ">
                </masked-input>
                <span v-if="error.phone" class="form-referral__error-text">Введіть коректний номер телефону</span>
              </div>
              <div class="form-referral__inner">
                <label for="inputCity" class="form-referral__label">
                  <i class="fa fa-map-marker-alt"></i>
                </label>
                <select class="form-referral__select" id="inputCity" name="city"   :class="{'form-referral__error': error.city}"
                v-model="referralInfo.city"> 
                 <option selected disabled value="Оберіть місто">Оберіть місто</option>
                 <option :value="city" v-for="city in cities" :key="city">{{city}}</option>
                </select>
                <span v-if="error.city" class="form-referral__error-text">Будь ласка, оберіть місто</span>
              </div>
              <div class="form-referral__inner">
                <label for="inputService" class="form-referral__label">
                  <i class="fa fa-city"></i>
                </label>
                <select class="form-referral__select" id="inputService" name="service"   :class="{'form-referral__error': error.service}"
                v-model="referralInfo.service"> 
                 <option selected disabled value="Оберіть послугу">Оберіть послугу</option>
                 <option :value="service" v-for="service in services" :key="service">{{service}}</option>
                </select>
                <span v-if="error.service" class="form-referral__error-text">Ви не обрали жодної послуги</span>
              </div>
              <div class="form-referral__agree">
                <input type="checkbox" v-model="agree">
                <label for="agree">Даю згоду на обробку персональних данних</label>
              </div>
                <div v-if="error.agreeText" class="form-referral__agree-text">Підтвердіть обробку данних для продовження</div>
              <button type="button" value="Залишити заявку" class="form-referral__btn"
              @click="addReferral"
              >Залишити заявку</button>
           </form>
        </div>
        <div v-else class="form-referral__main" :class="{'form-referral__main--secondary': referralAdded}">
          <strong class="form-referral__completed">Дякуємо, що обрали Sheriff</strong>
        </div>
      </div>
    </div>
  </div>  
</template>
<script>
import ReferralInput from "./ReferralInput.vue";
export default {
  data() {
    return {
      referralInfo: {
        // referrer_id: null,
        name: "",
        phone: "",
        city: "Оберіть місто",
        service: "Оберіть послугу",
      },
      error: {
        name: false,
        phone: false,
        city: false,
        service: false,
        agreeText: false,
      },
      referralAdded: false,
      agree: false,
      cities: ["Київ", "Львів", "Рівне", "Інші"],
      services: ["Охорона квартири", "Експрес охорона", "Охорона МАФів та магазинів", "Сервіс «VIP SHERIFF»", "Фізична охорона об'єктів"],
    }
  },
  methods: {
    addReferral() {
      if(this.agree && this.referralInfo.name.length >= 2 && this.referralInfo.city !== 'Оберіть місто' && this.referralInfo.service !== "Оберіть послугу") {
        this.axios.post(`${this.$domain}/referral`, {
          "referrer_id": this.$route.params.id,
          "name": this.referralInfo.name,
          "phone": this.referralInfo.phone,
          "city": this.referralInfo.city,
          "service": this.referralInfo.service
        })
          .then(response => {
            if(response.data.result === "success") {
              this.referralAdded = true;

              this.$swal.fire({
                icon: 'success',
                html: `<span style="color: #fff;">Заявка прийнята! <br/> З Вами зв'яжеться наш менеджер</span>`,
              })
              
            } else if (response.data.result === "failed") {
                this.$swal.fire({
                icon: 'error',
                html: `<span style="color: #fff;">На жаль, користувач <br/> з таким номером телефону вже існує</span>`,
              })
            }
            
            console.log(response)}
            )
      } else {
        if (!this.referralInfo.name.length) {
          this.error.name = true;
        }
        if (this.referralInfo.phone.length < 12) {
          this.error.phone = true;
        }
        if (this.referralInfo.city === "Оберіть місто") {
          this.error.city = true;
        }
        if (this.referralInfo.service === "Оберіть послугу") {
          this.error.service = true;
        }
        if (!this.agree) {
          this.error.agreeText = true;
        }
      }

    },
  },
  watch: {
    'referralInfo.name'() {
      if(!this.referralInfo.name.length) {
          this.error.name = true;
      } else {
        this.error.name = false;
      }

    },
    'referralInfo.phone'() {
      if(this.referralInfo.phone.length < 12) {
          this.error.phone = true;
      } else {
        this.error.phone = false;
      }

    },
    'referralInfo.city'() {
      if(this.referralInfo.city === 'Оберіть місто') {
          this.error.city = true;
      } else {
        this.error.city = false;
      }
    },
    'referralInfo.service'() {
      if(this.referralInfo.service === 'Оберіть послугу') {
          this.error.service = true;
      } else {
        this.error.service = false;
      }
    },
    agree() {
      if (this.agree) {
        this.error.agreeText = false;
      } else {
        this.error.agreeText = true;
      }
    },

  },
    created() {
      // this.referrer_id = this.$route && this.$route.params.id;
      // console.log(this.$route.params.id)
    },
    mounted() {
      // this.referrer_id = this.$route && this.$route.params.id;
      // console.log(this.$route.params.id)
    },
  components: { ReferralInput }
}
</script>

<style scoped lang="scss">
.form-referral {
  background:  linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7)), url("../../assets/img/card.png") ;
  color: rgb(137, 137, 137);
  height: 100vh;
  width: 100vw;
  &__wrapper {
    max-width: 900px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__content {
    padding: 0;
    border-radius: 15px;
    background-color: #fff;
    display: flex;
    @media screen and (max-width: 1280px) {
      justify-content: center;
    }
  }
  &__aside {
    flex: 0 0 35%;
    background-color: #313131;
    border-radius: 12px 0 0 12px;
    padding: 80px 0 60px;
    text-align: center;
    position: relative;
    & .title {
      display: block;
      color: #fff;
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 8px
    }
    & .phone {
      display: block;
      color: #fff;
      font-size: 39px;
      font-weight: 400;
      line-height: 44px;
      text-decoration: none;
      & strong {
        font-weight: 700;
        color: #ffe600
      }
    }
    & .text {
      color: #ffe600;
      font-size: 16px;
      font-weight: 400
    }
  }
  &__logo {
    width: 36%;
    height: auto;
    margin: 0 auto 80px auto;
    & img {
      display: block;
      width: 100%;
      height: auto
    }
    }
  &__completed {
    color: #fce500;
    text-align: center;
    font-size: 32px;
  }
  &__main {
    flex: 0 0 65%;
    padding: 30px 50px 40px;
    @media screen and (max-width: 1280px) {
      padding: 32px 0;      
      flex: 0 0 75%;
    }
    &--secondary {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #121212;
    }
    }
  &__payment {
      text-align: center;
      margin: 0 0 30px;
      padding-bottom: 8px;
      border-bottom: 2px solid rgba(137, 137, 137, 0.1);
      font-weight: 500;
      font-size: 20px;
    }
  &__form {
      &-title {
      color: #141414;
      font-size: 18px;
      font-weight: 400;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:before {
        display: inline-block;
        content: "";
        flex: 0 0 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #ffe600;
        color: #141414;
        font-size: 18px;
        font-weight: 400;
        margin-right: 19px;
        text-align: center;
        line-height: 32px
      }
    }
  }
  &__error {
    border: 1px solid #ff0000;
    outline: 1px solid #ff0000;
    &-text {
      display: block;
      margin-top: -10px;
      margin-bottom: 20px;
      color: #ff0000;
    }
  }
  &__inner {
    position: relative;
  }
  &__label {
    position: absolute;
    left: 20px;
    top: 36%;
    transform: translateY(-50%)
}
  &__field {
    font-family: "GothamPro";
    padding: 12px 0 12px 50px;
    width: 100%;
    margin: 0;
    border-radius: 10px;
    border: 5px solid #f2f0f0;
    background-color: #fff;
    color: #0f0f0f;
    margin-bottom: 20px;
      &::placeholder {
      color: #898989;
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      border: 5px solid transparent;
    }
  }
  &__select {
    font-family: "GothamPro";
    text-indent: 50px;
    width: 100%;
    margin: 0 0 20px;
    border-radius: 10px;
    border: 5px solid #f2f0f0;
    background-color: #fff;
    color: #898989;
    line-height: 1;
    height: 49px;
    font-size: 15px;
    &:focus {
      box-shadow: none;
      border-color: #ffe600;
    }
  }
  &__agree {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-text {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
      color: #ff0000;
      font-weight: bold;
    }
  }
  & label {
    margin-bottom: 0;
    margin-left: 5px;
  }
  &__btn {
    display: block;
    margin: 0 auto;
    box-shadow: 0 4px 0 #ceba00;
    border-radius: 39px;
    border: 1px solid #ffe600;
    background-image: linear-gradient(180deg, #ffe600 0%, #e6cf00 100%);
    padding: 14px 24px;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
  }
}
.message-result {
  text-align: center;
  margin-bottom: 20px;
  & span {
    background-color: green; 
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
  }
}
</style>