<template>
   <div class="modal"  @click="$emit('clickBtn')">
       <div class="modal__wrapper" 
       @click.stop 
       :style="{width: onMobile ? '300px': width + 'px', top: top + '%', 'border-color': borderColor}">
           <div class="modal__header"> 
               <slot name="header"></slot>
               <span class="modal__close" @click="$emit('clickBtn')">&times;</span>
           </div>
           <div class="modal__body" :style="{'background-color': background}">
               <slot name="body"></slot>
            </div>
            <slot></slot>
       </div>
   </div>
</template>
<script>
export default {
    props: {
        width: {
            type: Number,
            default: 600
        },
        top: {
            type: Number,
            default: 30
        },
        background: {
            type: String,
            default: '#fff'
        },
        borderColor: {
            type: String
        }
    }
}
</script>

<style scoped lang="scss">
.modal{
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: none;
    overflow-y: scroll;
    &.show {
        display: block;
    }
    &__wrapper {
        position: relative;
        margin: 0 auto 100px;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 9px;
        &--onMobile { 
            width: 300px !important;
        }
    }
    &__header {
        background-color: #1C1C1C;
        color: #fff;
        padding: 18px 15px;
        padding-right: 15px;
        border-radius: 9px 9px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    &__close {
        color: rgba(256, 256, 256, 0.5);
        font-size: 24px;
        font-weight: 700;
    }
    &__body {
       padding: 20px 15px 25px;
       color: #000;

    }
}

</style>