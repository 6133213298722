<template>
    <div class="referrals" v-if="referrals.length > 0">
        <h3 class="referrals__title">Мої реферали</h3>
        <div class="referrals__table">
            <div class="referrals__table-row" >
                <span class="referrals__table-col referrals__table-col--number">#</span>
                <span class="referrals__table-col">ІМ'Я</span>
                <span class="referrals__table-col">ТЕЛЕФОН</span>
                <span class="referrals__table-col">СТАТУС</span>
            </div>
            <div class="referrals__table-row" v-for="(referral, i) in referrals" :key="referral.phone">
                <span class="referrals__table-col referrals__table-col--number">{{i+1}}</span>
                <span class="referrals__table-col">{{referral.name}}</span>
                <span class="referrals__table-col">{{referral.phone}}</span>
                <span class="referrals__table-col"
                :class="referral.statusStyle"
                >{{referral.status}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    data() {
        return {
            user: "",
            referrals: [
                // {
                //     name: "Віталій", 
                //     phone: "3806722245255", 
                //     status_id: 1,
                // },
                // {
                //     name: "Віталій", 
                //     phone: "38067222005255", 
                //     status_id: 2,
                // }
            ],
        }
    },
    computed: {
    },
    methods: {
        getReferralsInfo(referrals) {
            this.referrals = referrals.map(referral => {
                referral.statusStyle; 
                if(referral.status_id === 1) {
                referral.statusStyle = "inwork"; 
                referral.status = "В роботі"; 
                }
                else if(referral.status_id === 2) {
                referral.statusStyle = "untargeted"
                referral.status  = "Нецільовий";
            } else if(referral.status_id === 3) {
                referral.statusStyle = "targeted";
                referral.status = "Успіх"
            } 
            return referral;
            })
        },
        getReferrals(id) {
            this.axios.get(`${this.$domain}/users/${id}/referrals`, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            .then(response => {
                console.log(response.data.data);
                this.getReferralsInfo(this.referrals)
                this.getReferralsInfo(response.data.data)
            })
        }
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem("user"));
        this.getReferrals(this.user.crm_id);
    }
}
</script>

<style scoped lang="scss">
.referrals {
    width: 67%;
        @media screen and (max-width: 1535px) {
            width: 62%;
        }
        @media screen and (max-width: 1280px) {
            width: 100%;
            margin-top: 16px;
        }
    &__title {
        margin: 0 0 10px;
        font-size: 14px;
    }
    &__table {
        background-color: rgba(68, 67, 67, 0.65);
        border-radius: 8px;
        padding: 10px 15px 15px;
        // margin: 3px;
        // text-align: center;
        &-row {
            display: grid;
            grid-template: 1fr /repeat(4, 1fr);
            column-gap: 5px; 
            align-items: center;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            &:first-child{
                margin-bottom: 20px;
            }
            @media screen and (max-width: 1280px) {
                display: flex;
                justify-content: space-between;
            }
           
        }
        &-col {
            justify-self: center;
            @media screen and (max-width: 1280px) {
                width: 60px;
                font-size: 10px;
                overflow-x: scroll;
                &--number {
                    width: 20px;
                }
            }
        }

    }
}
.inwork {
    background-color: #0d6efd;;
    border-radius: 20px;
    padding: 3px 7px;
    @media screen and (max-width: 1280px) {
        font-size: 8px;
    }
}
.targeted {
    background-color: #198754;;
    border-radius: 20px;
    padding: 3px 7px;
}
.untargeted {
    background-color: #dc3545;
    border-radius: 20px;
    padding: 3px 7px;

}
</style>
