<template>
  <table class="alarms__table" cellspacing="0" cellpadding="10" border="1" v-if="alarms.length > 0">
    <thead>
      <tr class="alarms__table-header">
        <th>Дата</th>
        <th>Час</th>
        <th>Екіпаж</th>
      </tr>
      <tr v-for="(alarm, i) in alarms" :key="i">
          <td>{{alarm.date_event}}</td>
          <td>{{alarm.time_event}}</td>
          <td>{{alarm.action_group_name}}</td>
      </tr>
    </thead>
  </table>
  <div class="alarms__absent" v-else-if="alarms.length === 0">
    <h3 class="alarms__absent-text">Оу, Вам пощастило, на об'єктi не було жодної тривоги!</h3>
    <div v-if="!onMobile" class="history-img">
         <img src="../../assets/img/sheriffs.gif" alt="">   
    </div>
  </div>
</template>
<script>
export default {
  props: ["alarms"],
};
</script>
<style scoped lang="scss">
.alarms {
  &__table {
    width: 100%;
    border-color: #e6e6e6;
    text-align: center;
  }
  &__absent {
      text-align: center;
      &-text {
          margin: 0 0 20px;
      }
  }
}
</style>