<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.$swal.fire({
      title:
        '<span style="color: #fce500; font-size: 24px;">Завантажуйте наш додаток MySheriff для iOS та Android</span>',
      icon: "info",
      html: `<div style="display: flex; justify-content: center; align-items: center;"> 
                <div style="display: flex; flex-direction: column; margin-right: 40px;"><a href="https://apps.apple.com/ua/app/mysheriff-особистий-кабінет/id1589945728"><i style="font-size: 40px; color: #fce500;" class="fab fa-app-store"></i></a> <span style="color: #fff; padding-top: 14px;">iOS</span></div> <div style="display: flex; flex-direction: column;"><a href="https://play.google.com/store/apps/details?id=com.sheriff.app"> <i style="font-size: 40px; color: #fce500;" class="fab fa-google-play"></i></a> <span style="color: #fff; padding-top: 14px;">Android</span> </div> </div>`,
      color: "#fce500",
      confirmButtonText: "Згорнути",
    });
  },
};
</script>
