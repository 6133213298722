<template>
  <input class="input" :type="type" :placeholder="placeholder" value="value" @input="$emit('input', $event.target.value)"/>
</template>
<script>
export default {
   props: {
        type: {
            type: String,
            default: "text"
        },
        placeholder: {
            type: String,
        }
    }
}
</script>
<style lang="scss" scoped>
 .input {
    background-color: rgb(87, 86, 86);
    color: #fff;
    border: 2px solid transparent;
    border-radius: 6px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    display: block;
    outline: none;
    &::placeholder {
      color: rgba(255, 255, 255, 0.2);
      // text-align: center;
    }
    &:focus {
      border-color: #fff;
    }
}
</style>
