<template>
    <main class="referrer-history" v-if="payHistory">
        <table class="referrer-history__table" cellspacing="0" cellpadding="10"  border="1" v-if="payHistory.length > 0">
            <thead class="referrer-history__table-header">
                <tr>
                    <th class="referrer-history__table-head">номер замовлення</th>
                    <th class="referrer-history__table-head">сума</th>
                    <th class="referrer-history__table-head">статус</th>
                    <th class="referrer-history__table-head">дата</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in payHistory" :key="item.order_id">
                    <td class="referrer-history__table-col">{{item.order_id}}</td>
                    <td class="referrer-history__table-col">{{item.amount}}</td>
                    <td class="referrer-history__table-col">{{item.status}}</td>
                    <td class="referrer-history__table-col">{{item.created_at.slice(0,10)}}</td>
                </tr>
            </tbody>
        </table>
    </main>
    <Spinner v-else-if="!payHistory"/> 
    <div class="empty-table" v-else-if="payHistory.length === 0">У вас ще немає запитів на виведення коштів</div>
</template>
<script>
import Spinner from "../Spinner"
export default {
    data() {
        return {
           userId: 55593,
           payHistory: null
        }
    },
    components: { Spinner },
    methods: {
        modifyPayHistory(data) {
            console.log(data)
            this.payHistory = data.map(item => {
                switch(item.status) {
                    case "requested":    
                        item.status = "Виплата в обробці"
                        return item;
                    case "transferred":
                        item.status = "Кошти зараховані";
                        return item
                    }
            }) 
        },
        getReferrerPayHistory(id) {
                this.axios.get(`${this.$domain}/users/${id}/referrerPayHistory`, {
                    headers: { "Authorization": `Bearer ${localStorage.getItem("token")}`}
                })
                .then(response => {
                    this.modifyPayHistory(response.data.data)
                    console.log(response.data.data);
                })
            .catch(e => console.log(e.message))
        }
    },
    mounted() {
        this.getReferrerPayHistory(this.userId);

    }
}
</script>

<style scoped lang="scss">
.referrer-history{
    &__table {
        width: 100%;
        &-head {
            text-transform: uppercase;
        }
        &-col {
            text-align: center;
        }
    }
}
.empty-table {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
}


</style>