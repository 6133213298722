<template>
<tbody>
     <tr class="payhistory__table-title">
        <td colspan="5">{{contract.Description}}</td>
    </tr> 
     <tr v-for="item in contract.Item" :key="item.Date">
         <td>{{item.Date.slice(0,10)}}</td>
         <td>{{item.Operation}}</td>
         <td>{{item.Debit}} грн</td>
         <td>{{item.Credit}} грн</td>
         <td>{{item.Balance}} грн</td>
    </tr>
</tbody>
</template>
<script>
export default {
    props: ["contract"]
}
</script>
<style scoped>
tr {
    width: 100%;
}
td {
    text-align: center;
}
.payhistory__table-title {
    text-align: center;
    color: #ff9500

}
</style>