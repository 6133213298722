<template>
 <div class="registration">
    <div class="registration__wrapper">
      <div class="registration__logo">
        <img
          class="registration__logo-img"
          src="@/assets/icons/logo.png"
          alt="sheriff logo"
        />
      </div>
      <h1 class="registration__title">Реєстрація</h1>
      <form class="registration__form" ref="form">
          <input class="registration__input" placeholder="ім'я" v-model="userData.name"/>
          <input class="registration__input" placeholder="email" v-model="userData.email"/>
          <input class="registration__input" type="email" 
              placeholder="email" id="registration__tel" v-model="userData.phone"/>
          <v-select :options="objectTypes" class="registration__select" v-model="userData.type_object"/>
          <v-select :options="cities" class="registration__select" v-model="userData.city"/>
         <span class="registration__message" :class="{done: successRegistration}">{{message}}</span>
          <!-- <YellowButton @clickBtn="makeRegisterLead(userData.name, userData.phone, userData.email, userData.type_object, userData.city)" 
              class="registration__btn radius">Зареєструватися</YellowButton> -->
      </form>
    </div>
  </div>   
</template>
<script>
import Inputmask from "inputmask";
// import YellowButton from "../../components/Buttons/YellowButton.vue"
export default {
    // components: {
    //     YellowButton 
    // },
    data() {
        return {
            cities: [],
            objectTypes: [],
            message: "",
            successRegistration: false,
            userData: {
                name: "",
                email: "",
                phone: "",
                type_object: "тип об'єкту",
                city: "місто"
            },
        }
    },
    methods: {
        getDefaultData() {
            this.axios.get(`${this.$domain}/getInfoData`)
            .then(response => {
                this.getDataList(response.data.cities, this.cities);
                this.getDataList(response.data.objectTypes, this.objectTypes)
            })
        },
        getDataList(responseArr, finalArr) {
            responseArr.map(item => finalArr.push(item.name))
        },
        makeRegisterLead(name, phone, email, type_object, city) {
          if(name !== "" || email !== "" || phone !== "" || type_object !== "тип об'єкту" || city !== "місто") {
            this.axios.post(`${this.$domain}/makeLead`, {name, phone, email, type_object, city})
            .then(response => {
              if(response.data.result === "failed") {
                 this.message = "Шось пішло не так...";
                 this.resetForm();
              } else {
                this.successRegistration = true;
                this.message = "З Вами зв'яжуться";
                this.resetForm();
              }
            })
          } else {
            this.message = "Не всі поля заповнені"
          }
        },
        resetForm() {
          this.userData.name = this.userData.email = this.userData.phone = "";
          this.userData.type_object = "тип об'єкту";
          this.userData.city = "місто"
        }
    },
    mounted() {
    this.getDefaultData();
    new Inputmask("380999999999").mask(document.getElementById("registration__tel"));
    //  localStorage.clear();
  },
}

</script>
<style lang="scss">
.registration {
background-color: #000;
color: #fff;
width: 100%;
height: 100vh;
padding: 0 15px;
display: flex;
flex-direction: column;
justify-content: center;
    &__wrapper {
    margin: 0 auto 100px;
    max-width: 520px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__logo {
    width: 150px;
    margin-bottom: 10px;
    &-img {
      max-width: 100%;
    }
  }
    &__title {
    font-size: 26px;
    font-weight: 500;
    line-height: 34px;
    display: inline-block;
    margin: 0 0 15px;
    padding-bottom: 5px;
    border-bottom: 0.125rem solid rgba(255, 255, 255, 0.5);
  }
  &__input {
    background-color: rgb(87, 86, 86);
    color: #fff;
    border: 2px solid transparent;
    border-radius: 6px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    display: block;
    outline: none;
      &::placeholder {
      color: rgba(255, 255, 255, 0.2);
      // text-align: center;
    }
    &:focus {
      border-color: #fff;
    }
  }
  &__input, &__select {
      margin-bottom: 14px;
  }
   &__input::placeholder {
     font-weight: 400;
   }
  &__form {
      text-align: center;
  }
  &__btn {
      margin-top: 10px;
  }
  &__select {
    &.v-select{
    background-color: rgb(75, 75, 75);
    color: #fff;
    border-radius: 6px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    display: block;
    outline: none;
    cursor: pointer;
    &.vs--open {
         border-radius: 6px 6px  0 0;
    }
    & .vs__dropdown-toggle {
        height: inherit;
        padding: 7px 5px 6px;
    }
    & .vs__search {
    // padding: 5px 5px 4px;
    }
    & .vs__dropdown-menu {
        top: calc(100% + 1px);
        background-color: rgb(87, 86, 86);  
    }  
    & .vs__dropdown-option {
        padding-top: 7px;
        padding-bottom: 7px;
        color: rgba(255, 255, 255, 0.2)
    }
    & .vs__dropdown-option--highlight {
        color: #fff;
       background-color: rgb(75, 75, 75);
    }
    & .vs__open-indicator {
        cursor: pointer;
    }
    & .vs__clear {
    display: none;
    }
    & .vs__selected {
        color: #fff;
    }
    & .vs__selected-options {
      font-weight: 40;
    }
    & .vs__open-indicator {
        fill: rgba(255, 255, 255, 0.2);
    }
  }
  }
  &__message {
    color: red;
    display: block;
    margin-top: 30px;
  }
}
.done {
  color: green;
  font-weight: 700;
}

</style>