<template>
<a :href="link" target="_blank" class="payment">
    <div class="payment__body">
        <span class="payment__online">Сплатити онлайн</span>
        <span class="payment__online-text">Сплачуйте за послуги охорони швидко та зручно</span>
    </div>
</a> 
</template>
<script>
export default {
    name: "Payment",
    props: ["phone"],
    computed: {
        link() {
            return `https://next.privat24.ua/payments/form/{"token":"1bb0469beb3ae473041824e9b80bd9cdys0gm031", "parameters":{"phonenumber": "${this.phone?.slice(2)}"}}`
        }
    }
}
</script>

<style scoped lang="scss">
$accent: rgb(251, 227, 1);
.payment {
    background-color: $accent;
    border-radius: 6px;
    padding: 15px;
    color: #000;
    display: block;
    text-decoration: none;
    margin-bottom: 15px;
    &__body {
        position: relative;
        padding-left: 50px;
        &:after {
            position: absolute;
            content: "";
            background-image: url("../../assets/icons/privat.png");
            background-size: 36px 36px;
            background-repeat: no-repeat;
            width: 36px;
            height: 36px;
            top: calc(50% - 18px);
            left: 0;
        }
    }
    &__online {
        display: block;
        font-size: 20px;
        font-weight: 600;  
        &-text {
            font-size: 12px;
        }

    }
}
</style>