<template>
    <input class="form-control" :type="type" :placeholder="placeholder" @input="$emit('input', $event.target.value)">
</template>
<script>
export default {
    props: ["placeholder", "type"]
}
</script>
<style scoped>
.form-control {
        display: block;
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #726f6f;
        color: #000;
        font-size: 18px;
        margin-bottom: 15px;
    }
.form-control::placeholder, .form-code::placeholder {
        /* font-family:"Gotham Pro"; */
        font-size: 16px;
        line-height: 1;
}
</style>
