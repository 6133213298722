<template>
   <main class="referral">
       <ReferTrigger 
       @visibleInstruction="instruction = true"
       @getQr="showQr = true"
       />
       
        <Modal :class="{show: instruction}" @clickBtn="instruction = false" :width="1200" :top="5">
            <template v-slot:header>
                <h5 class="referral__modal-title">Поширені запитання та відповіді</h5>
            </template>
            <template v-slot:body>
                <ul class="referral__modal-instruction">
                    <li class="referral__modal-item">
                        <span class="referral__modal-question">Які бонуси я отримаю, підключившись до «Шериф» за допомогою реферального посилання від друга?</span>
                        <span  class="referral__modal-text">Підключившись до «Шериф» за допомогою реферального посилання від друга ви отримуєте 250 грн на вашу картку, вказану при реєстрації для нарахування бонусів.</span>
                    </li>
                       <li class="referral__modal-item">
                        <span class="referral__modal-question">Чи можу я скористатися бонусами одразу при першому підключенні?</span>
                        <span  class="referral__modal-text">Отримавши бонуси, ви можете одразу ними скористатися та покрити частину оплати за підключення.</span>
                    </li>
                      <li class="referral__modal-item">
                        <span class="referral__modal-question">Можу я не використовувати бонуси одразу?</span>
                        <span  class="referral__modal-text">Бонуси, отримані за участі у реферальній програмі, є й накопичувальними в тому числі. Ви можете накопичувати бонуси на рахунку для абонплати за охоронні послуги або переказати гроші на окрему банківську картку.</span>
                    </li>
                    <li class="referral__modal-item">
                        <span class="referral__modal-question">Чи можу я використовувати бонуси для інших цілей?</span>
                        <span  class="referral__modal-text">Ви можете накопичувати бонуси на рахунку для абонплати за охоронні послуги або ж переказати гроші на окрему банківську карту. Ви також можете отримати бонуси готівкою.</span>
                    </li>
                    <li class="referral__modal-item">
                        <span class="referral__modal-question">Як швидко нараховуються бонуси?</span>
                        <span  class="referral__modal-text">Бонуси нараховуються одразу ж, після підключення друга до програми.</span>
                    </li>
                     <li class="referral__modal-item">
                        <span class="referral__modal-question">Як компанія зрозуміє, що мене привів друг?</span>
                        <span  class="referral__modal-text">У кожного клієнта є своє індивідуальне реферальне посилання. Отримуючи посилання від друга, ви переходите за ним, а система автоматично зчитує інформацію.</span>
                    </li>
                     <li class="referral__modal-item">
                        <span class="referral__modal-question">Яка мінімальна вартість охорони квартири на місяць?</span>
                        <span  class="referral__modal-text">Місячна абонплата за охорону квартири з базовим комплектом бездротового обладнання AJAX StarterKit становить 359 грн/міс. Даний пакет послуг називається «Охорона квартири | AJAX + SHERIFF».</span>
                    </li>
                       <li class="referral__modal-item">
                        <span class="referral__modal-question">Які послуги я отримую, обравши мінімальний пакет «Охорона квартири | AJAX + SHERIFF»?</span>
                        <span  class="referral__modal-text">У пакет «Охорона квартири | AJAX + SHERIFF» вартістю 359 грн/міс входить: базовий комплект бездротового обладнання AJAX StarterKit, додаткове обладнання у вигляді установки протипожежної сигналізації, датчиків задимлення та датчиків затоплення, а також виїзд групи швидкого реагування на сигнал тривоги.</span>
                    </li>
                     <li class="referral__modal-item">
                        <span class="referral__modal-question">Як довго чекати прибуття групи швидкого реагування?</span>
                        <span  class="referral__modal-text">Група швидкого реагування прибуває на сигнал тривоги в середньому за 8 хвилин. Дислокація екіпажів розміщена так, щоб оперативно відреагувати на виклик і своєчасно прибути на локацію.</span>
                    </li>
                     <li class="referral__modal-item">
                        <span class="referral__modal-question">Як швидко відбувається монтаж та підключення пожежної сигналізації?</span>
                        <span  class="referral__modal-text">Час установки пожежного обладнання – 40 хвилин. Монтаж і підключення здійснюється в день заявки.</span>
                    </li>
                     <li class="referral__modal-item">
                        <span class="referral__modal-question">Як працює пожежна сигналізація?</span>
                        <span  class="referral__modal-text">Пожежна сигналізація реагує на задимлення й зміну температури за допомогою спеціальних сповіщувачів. Сигнал тривоги моментально передається на пульт централізованого спостереження «Шериф».</span>
                    </li>
                    <li class="referral__modal-item">
                        <span class="referral__modal-question">Скільки бонусів я отримаю, під'єднавши друга до програми?</span>
                        <span  class="referral__modal-text">За кожного друга, що приєднається до програми за вашим посиланням та замовив послугу, ви отримуєте 500 грн.</span>
                    </li>
                     <li class="referral__modal-item">
                        <span class="referral__modal-question">Чи існують обмеження на кількість підключених друзів?</span>
                        <span  class="referral__modal-text">Реферальна програма не передбачає обмежень. Ви можете підключати стільки друзів, скільки бажаєте.</span>
                    </li>
                     <li class="referral__modal-item">
                        <span class="referral__modal-question">Як мені приєднати друга до реферальної програми?</span>
                        <span  class="referral__modal-text">Аби приєднати друга до реферальної програми, скопіюйте своє індивідуальне реферальне посилання та відправте його другові. Коли друг перейде за посиланням, система автоматично зафіксує дані.</span>
                    </li>
                    <li class="referral__modal-item">
                        <span class="referral__modal-question">Чи можу я бути впевнений в конфіденційності даних?</span>
                        <span  class="referral__modal-text">З кожним клієнтом компанія «Шериф» підписує договір, обговорюючи й пункт конфіденційності даних. Окрім цього, у компанії є власний захищений від зовнішнього впливу сервер, де зберігається вся інформація про об'єкти.</span>
                    </li>
                      <li class="referral__modal-item">
                        <span class="referral__modal-question">Чому саме «Шериф»?</span>
                        <span  class="referral__modal-text">Тому що ми перша охоронна ІТ-компанія - це значить з нами легко й зручно комунікувати, розв'язувати технічні питання та проводити оплату. Устаткування, яке ми використовуємо в роботі є передовим, що дає можливість аналізувати повномірно кожну із ситуацій й розв'язувати будь-яке питання, пов'язане з безпекою наших клієнтів.</span>
                        <span class="referral__modal-text">У нас найбільша кількість екіпажів в Україні (понад 100). Чим більше екіпажів, тим ближче вони до наших клієнтів, тим швидше ми приїздитимемо на тривогу. На тривогу реагують наші бійці (так-так, саме бійці), які пройшли спец. відбір й певну кваліфікацію, кожен день вони продовжують удосконалюватися: боротьба, спортивний зал, конфліктологія, тренінги з нішевими експертами, заняття зі стрільби та багато чого іншого. Екзамени проходить кожні пів року!</span>
                        <span class="referral__modal-text">Ми не дотримали обіцянку бренду та щось пішло не так? Ми готові спокутувати свою провину, виплативши матеріальну компенсацію! До слова наша відкрита позиція й хороша репутація в інтернеті не дасть збрехати.</span>
                    </li>
                    
                </ul>
            </template>
       </Modal>

       <div class="referral__inner" :class="{'referral__inner--mobile': onMobile}">
           <ReferralInfo  
           @addCard="showEditCardModal"
           @withdrawals="withdrawals = true"
           :user="user"
           :cardVisible="cardVisible"
           :cardValue="cardValue"
           :payHistoryLength="payHistoryLength"
           :cardExist="cardExist"
           />
           <Referrals/>
       </div>

       <Modal :class="{show: addCard}" @clickBtn="addCard = false">
          <template v-slot:header>
              <span class="referral__modal-title_left">{{modalTitle}}</span>
          </template>
          <template v-slot:body>
              <div class="referral__modal-value">
                   <input type="text" class="referral__modal-input" 
                   placeholder="xxxx xxxx xxxx xxxx" 
                   v-mask="'#### #### #### ####'"
                   v-model="cardValue">
              </div>
          </template>
          <template>
              <div class="modal__footer">
                <YellowButton class="modal__footer-btn"
                @clickBtn="addReferrerBankCard(userId)"
                
                >Підтвердити</YellowButton>
                </div>
          </template>
       </Modal>

       <Modal
            @clickBtn="showQr = false"
            :class="{show: showQr}">
            <template v-slot:header>
                <span class="referral__modal-title_left">Ваш реферальний QR-код</span>
            </template>
            <template v-slot:body> 
                <div class="referral__modal-qr">
                   <img :src="referralLink" alt=""> 
                </div>
            </template>
        </Modal>
        <Modal
            @clickBtn="withdrawals = false"
            :class="{show: withdrawals}">
            <template v-slot:header>
                <span class="referral__modal-title bold">Виведення коштів</span>
            </template>     
            <template v-slot:body>  
                <div class="withdrawals">
                    <div class="withdrawals__block card">
                        <input type="radio" id="card" 
                        class="withdrawals__input" name="method" value="Вивести на карту"
                        v-model="withdrawalsMethod">
                        <label for="card">Вивести на карту</label>                       
                    </div>
                    <div class="withdrawals__card" v-if="withdrawalsMethod === 'Вивести на карту'">
                        <label class="withdrawals__label" for="">Вкажіть суму</label>
                        <input type="text" placeholder="Сума" class="withdrawals__sum" v-model="withdrawalsSumCard">
                        <span class="withdrawals__card-note">* Податки будуть утримані відповідно до ст 167.1 ПКУ</span>
                        <YellowButton class="withdrawals__btn"
                        @clickBtn="addReferrerWithdrawals(userId, withdrawalsSumCard)"
                        >Вивести</YellowButton>
                    </div>
                    <div class="withdrawals__block abon">
                        <input type="radio" id="abon"
                        class="withdrawals__input" name="method" value="Вивести в рахунок абонплати"
                        v-model="withdrawalsMethod"
                        >
                        <label for="abon">Вивести в рахунок абонплати</label>                        
                    </div>
                    <div class="withdrawals__abon" v-if="withdrawalsMethod === 'Вивести в рахунок абонплати'">
                        <label class="withdrawals__label" for="">Виберіть договір</label>
                        <select class="withdrawals__abon-select" v-model="withdrawalsObject">
                            <option value="Виберіть проект">Виберіть проект</option>
                            <option v-for="address in projects" :key="address.contract_docnumber" :contractId="address.contract_docnumber">{{address.address}}</option>
                        </select>
                        <input type="text" placeholder="Сума" class="withdrawals__sum" v-model="withdrawalsSumAbon">
                        <YellowButton class="withdrawals__btn"
                        @clickBtn="addReferrerWithdrawals(userId, withdrawalsSumAbon, contractId)"
                        >Вивести</YellowButton> 
                    </div>

                </div>
            </template>     
        </Modal>
   </main>
</template>
<script>
import ReferTrigger from "./ReferTrigger.vue";
import ReferralInfo from "./ReferInfo.vue";
import Referrals from "./Referrals.vue";
import YellowButton from "../Buttons/YellowButton.vue"
import Modal from "../Modals/DefaultModal.vue";
export default {
    data() {
        return {
            user: "",
            userId: "",
            projects: null, 
            contractId: "",
            cardExist: false,
            cardVisible: false,
            cardValue: "",
            instruction: false,
            showQr: false,
            addCard: false,
            withdrawals: false,
            withdrawalsMethod: "",
            withdrawalsSumCard: "",
            withdrawalsSumAbon: "",
            // contracts: {},
            withdrawalsObject: "Виберіть проект",
            payHistoryLength: "", 
            modalTitle: "",
        }
    },
    computed: {
    referralLink() {
    return `https://api.qrserver.com/v1/create-qr-code/?data=${window.origin}/referral/${this.user.crm_id}&size=250x250"`
}
    },
    methods: {
        addReferrerWithdrawals(id, sum, docnumber) {
            if(docnumber) {
            this.axios.post(`${this.$domain}/users/${id}/referrerWithdrawals`, { 
                type: "abon", docnumber, sum },
            {
                headers: {
                   "Authorization": `Bearer ${localStorage.getItem("token")}` 
            }})
                .then(response => {
                    console.log(response.data)
                    this.$swal.fire({
                        title: 'Дякуємо, заявка відправлена на опрацювання',
                        icon: 'success',
                        confirmButtonText: "OK",
                    });
            })
            } else {
                this.axios.post(`${this.$domain}/users/${id}/referrerWithdrawals`, {
                    type: "card", sum },
            {
                headers: {
                   "Authorization": `Bearer ${localStorage.getItem("token")}` 
            }})
                .then(response => {
                    console.log(response.data);
                    this.$swal.fire({
                        title: 'Дякуємо, заявка відправлена на опрацювання',
                        icon: 'success',
                        confirmButtonText: "ОК",
                    });
                    
                })
            }  
        },
        addReferrerBankCard(id) {
            this.axios.post(`${this.$domain}/users/${id}/referrerBankCard`, {
                "card_number": this.cardValue,
            },
            {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}` 
            }}
            )
            .then(response => {
            if(response.data.result === "success") {
                this.cardVisible = true;
                this.addCard = false;
                this.cardValue = response.data.data;
                }
            })
        },
        getReferrerBankCard(id) {
            this.axios.get(`${this.$domain}/users/${id}/referrerBankCard`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
            }).then(response => {
                if (response.data.result === "success") {
                    this.cardVisible = true;
                    this.cardExist = true;
                    this.cardValue = response.data.data;
                }
            })
        },
        // getUserObjects(id) {
        //     this.axios.get(`${this.$domain}/users/${id}/projects`, {
        //         headers: {
        //             "Authorization": `Bearer ${localStorage.getItem("token")}`
        //         }
        //     })
        //     .then(response => {
        //         this.contracts = response.data.contracts;
        //         console.log(response.data.contracts)
        //     })
        //     .catch(e => console.log(e.message))
        // },     
        getReferrerPayHistory(id) {
            this.axios.get(`${this.$domain}/users/${id}/referrerPayHistory`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
            })
            .then(response => {
                console.log(response.data.data);
                this.payHistoryLength = response.data.data.length
            })
            .catch(e => console.log(e.message))
        },
        
        modifyPayHistory(data) {
            this.payHistory = data.map(item => {
                if(item.status === "requested") {
                    item.status = "Виплата в обробці"
                    return item
                } else if(item.status === "transferred") {
                    item.status = "Кошти зараховані"
                    return item
                }
            }) 
        },
        showEditCardModal(title) {
            this.addCard = true;
            this.modalTitle = title;
            this.cardValue = "";
        }
    },
    created() {
        this.user = JSON.parse(localStorage.getItem("user"));
        this.userId = this.user.crm_id;
        this.getReferrerBankCard(this.userId);
        this.projects = JSON.parse(localStorage.getItem("projects"));
        // this.getUserObjects(this.userId);
        this.getReferrerPayHistory(this.userId);
    },
    components: { ReferTrigger, ReferralInfo, Referrals, YellowButton, Modal}
}
</script>
<style scoped lang="scss">
.referral {
    &__inner {
    display: flex;
    justify-content: space-between;
    &--mobile {
        flex-direction: column;
    }
    }
    &__modal{
        &-title {
            font-size: 20px;
            margin: 0;
            width: 100%;
            text-align: center;
            &_left {
                font-size: 20px;
                font-weight: 700;
            }
            &.bold {
               font-weight: 700;
           }
        }
        &-instruction {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
        &-value {
            text-align: center;
            margin-top: 10px;
            margin-bottom: 20px;
        }
        &-input{
            width: 47%;
            padding: 10px;
            font-size: 20px;
            line-height: 1;
            border-color: transparent;
            outline: none;
            text-align: center;
            &::placeholder {
                text-align: center;
                text-transform: uppercase;
            }
        } 
        &-item:not(:last-child) {
            margin-bottom: 10px;
        }
        &-question {
            display: block;
            font-weight: 700;
        }
        &-qr {
            text-align: center;
        }
    }
}
.modal{
    &__footer {
        border-top: 1px solid rgb(233, 236, 239);
        padding: 15px 15px 25px;
        &-btn {
            display: block;
            border-radius: 100px;
            margin: 0 auto;
        }
    }
}
.withdrawals {
    padding: 10px 0;
    width: 55%;
    margin: 0 auto;
    &__block {
        display: flex;
        align-items: center;
        justify-content: center;
       &.abon {
           margin-top: 20px;
       }
    }
    &__input {
       margin: 0;
       margin-right: 5px;
    }
    &__label {
        display: block;
        font-weight: 700;
        margin-top: 25px;
        margin-bottom: 5px;
    }
    &__sum{
        font-family: "GothamPro";
        display: block;
        border: 1px solid #ccc;
        border-radius: 6px;
        padding: 8px 12px;
        margin: 0 auto;
        width: 90%;
        margin-bottom: 2px;
    }
    &__card {
        text-align: center;
        &-note {
            display: block;
            font-size: 11px;
        }
    }
    &__abon {
        text-align: center;
        &-select {
            background-color: #fff;
            font-family: "GothamPro";
        // display: block;
            border: 1px solid #ccc;
            border-radius: 6px;
            padding: 8px 8px;
        // margin: 0 auto;
            width: 90%;
            color: rgb(126, 126, 126);
            margin-bottom: 8px;
        }
    }
    &__btn {
        font-weight: 400;
        border-radius: 6px;
        min-width: auto;
        padding: 8px 13px;
        margin-top: 8px;
    }
}
</style>