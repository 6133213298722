<template>
    <div class="faq">
        <h6 class="faq__title">{{blockTitle}}</h6>
        <div class="faq__block">
            <div class="faq__block-item" v-for="(faq, i) in faqs" :key="faq.id">
                 <span class="faq__block-header" 
                 @click="showDescription(i+1)"
                 >{{faq.title}} <i class="fas fa-question-circle"></i></span>
            <div class="faq__block-body" 
            v-if="isActive === i+1"
            :class="{show: isActive}"
            >{{faq.text}}</div>
            </div>
        </div>
        
    </div>
</template>
<script>
export default{
    props: ["faqs"],
    data() {
        return {
            isActive: null,
        }
    },
    computed: {
        blockTitle() {
            return this.faqs.length > 0 ? this.faqs[0].type : "" 
        }
    },
    methods: {
        showDescription(id) {
           this.isActive = (this.isActive === id) ? null : id;
        }
    }
}
</script>
<style scoped lang="scss">
.faq {
    &__title {
        font-size: 20px;
        margin: 0 0 10px;
    }
    &__block {
        background-color: #fff;
        color: #000;
        border-radius: 6px;
        &-header{
            display: block;
            font-weight: 700;
            padding: 12px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            cursor: pointer;
            & .fa-question-circle {
                color: #1abd36;
            }
        }
        &-body {
            display: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        }
    }
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}
.show {
    display: block;
    text-align: center;
    padding: 30px 20px;
    background-color: rgb(241, 241, 241);
}
.active {
    color: red;
}

</style>

