<template>
    <a :href="billPath" class="bill">
        <div class="bill__header">{{billName}}</div>
        <div class="bill__body">
            <i class="fas fa-file-download"></i>
        </div>
    </a>
</template>
<script>
export default {
    props: ["billName", "billPath"]
}
</script>

<style scoped lang="scss">
.bill {
    border-radius: 6px;
    background-color: rgba(256, 256, 256, 0.2);
    cursor: pointer;
    color: #000;
    text-decoration: none;
    &__header {
        background-color: rgba(256, 256, 256, 0.7);
        border-radius: 6px 6px 0 0;
        padding: 10px;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
    }
    &__body {
        padding: 25px 10px;
        text-align: center;
        & .fa-file-download {
            color: #FBE301;
            font-size: 70px;

        }
    }
}

</style>