import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "@/axiosConfig";
import Clipboard from "v-clipboard";
import VueMask from "v-mask";
import MaskedInput from "vue-text-mask";
import vSelect from "vue-select";
import DefaultInput from "./components/UI/DefaultInput";
import SweetAlert2 from 'vue-sweetalert2';

import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

import { onMobile } from '@/mixins'
Vue.mixin(onMobile)

Vue.config.productionTip = false
Vue.prototype.axios = axios;
Vue.use(Clipboard);
Vue.use(VueMask);
Vue.use(SweetAlert2, {
	background: '#313131',
	iconColor: '#fce500',
	allowEscapeKey: false,
	allowOutsideClick: false,
	confirmButtonColor: '#121212',
});


Vue.component("masked-input", MaskedInput);
Vue.component("default-input", DefaultInput);
Vue.component("v-select", vSelect);

import "vue-select/src/scss/vue-select.scss";
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.prototype.$domain = 'https://my-back.sheriff.com.ua/api';

new Vue({
	router,
	render: h => h(App),
}).$mount('#app');



