<template>
<div class="balance">
        <div class="balance__top">
             <div class="balance__user">
                 <img src="../../assets/img/user-men.png" alt="">
             </div>
             <span class="balance__greeting" v-if="name">{{name}}, Вітаємо Вас в особистому кабінеті</span>
        </div>
        <span class="balance__value" v-if="balance" >Баланс: <span :class="{warning: 'warning'}">{{balance}} грн</span></span>
</div>
</template>
<script>
    export default {  
    props: ["name", "balance"], 
        data() {
            return {
                warning: false
            }
        },
        methods: {
            checkBalance() {
                if(this.balance < 0) {
                this.warning = true
            }
        },
        mounted() {
            this.checkBalance() 
        }
    }
}
</script>
<style scoped lang="scss">
.balance {
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: 15px;
        &__top {
            background: radial-gradient(127.32% 113.53% at -10.38% -13.44%, #FBE301 0%, rgba(0, 0, 0, 1) 100%);
            border-radius: 6px 6px 0 0;
            padding: 45px 15px 15px;
            display: flex;
            flex-direction: column;
            align-items: center;

        }
        &__user {
            max-width: 100px; 
            height: 100px; 
            margin-bottom: 10px;
            & img {
               width: 100%;
               max-height: 100%;  object-fit: cover;
               border-radius: 50%;  
            }
        }
        &__greeting {
            font-weight: 700;
            display: block;
            text-align: center;
        }
        &__value {
            display: block;
            font-size: 18px;
            font-weight: 700;
            color: #000;
            padding: 15px;
            text-align: center;
        }
    }
.warning {
    color: #ff3b30
}
</style>