<template>
<main class="user">
    <h1 class="user__title">Редагувати особисті дані</h1>
    <div class="user__info">
        <span class="user__info-col header">Контактна інформація</span>
        <span class="user__info-col name" v-if="fullname">{{fullname}}</span>
        <span class="user__info-col title">Email</span>
        <span class="user__info-col">{{user.email}}</span>
        <span class="user__info-col btn">
            <OutlineButton class="user__info-btn"
            @clickBtn="showModal('email', 'Редагувати Email', 'Email')"
            >Редагувати</OutlineButton>
        </span>
        <span class="user__info-col title">Дата народження</span>
        <span class="user__info-col">{{reverseBirthDay}}</span>
        <span class="user__info-col btn">
            <OutlineButton class="user__info-btn"
            @clickBtn="showModal('date', 'Редагувати Дату народження', 'Дата народження')"
            >Редагувати</OutlineButton>
        </span>
        <span class="user__info-col title">Телефон</span>
        <span class="user__info-col double">{{user.phone}}</span>
        <span class="user__info-col header">Налаштування мови</span>
        <span class="user__info-col title">Мова</span>
        <span class="user__info-col double">Українська</span>
        <span class="user__info-col header">Безпека</span>
        <span class="user__info-col title">
            Пароль
            <i class="fas fa-lock" v-if="existPassword"></i>
            <i class="fas fa-lock-open" v-else></i>
            </span>
        <span class="user__info-col">
            <span v-if="existPassword">*******</span>
            <span v-else>Будь ласка, встановіть пароль</span>
            
        </span>
        <span class="user__info-col btn">
            <OutlineButton class="user__info-btn"
             @clickBtn="showModal('password', 'Пароль', 'Новий пароль')"
            
            >Змінити</OutlineButton>
        </span>
    </div>
    <Modal 
    @clickBtn="modalVisible = false"
    :class="{show: modalVisible}">
    <template v-slot:header>
        <h5 class="user__modal-title">{{modalInfo.title}}</h5>
        </template>
    <template v-slot:body>
        <div v-if="activeModal === 'email'">
            <label class="user__modal-label"
            for="">{{modalInfo.label}}</label>
            <input type="text" 
            class="user__modal-input"
            v-model="newData.email">
        </div>
        <div v-if="activeModal === 'date'">
            <label class="user__modal-label"
            for="">{{modalInfo.label}}</label>
            <input type="date" 
            class="user__modal-input"
            v-model="newData.bday">
        </div>
        <div v-if="activeModal === 'password'">
            <span class="password-error" v-if="passwordError">Введені паролі відрізняються</span>
            <label class="user__modal-label"
            for="">{{modalInfo.label}}</label>
            <input type="password" 
            class="user__modal-input"
            v-model="newData.password">

            <span class="user__modal-repeatpassword">
            <label class="user__modal-label"
            for="">Підтвердіть пароль</label>
            <input type="password" 
            class="user__modal-input"
            v-model="repeatPassword"
            >
            </span>
        </div>

    </template>
    <div class="modal__footer">
        <YellowButton class="user__btn" @clickBtn="updateUserInfo">Зберегти</YellowButton>
    </div>
    </Modal>
</main>
</template>
<script>
import OutlineButton from "../Buttons/OutlineButton.vue";
import YellowButton from "../Buttons/YellowButton.vue";
import Modal from "../Modals/DefaultModal.vue";
export default{
    data() {
        return {
            existPassword: false,
            user: "",
            newData: {
                email: "",
                bday: "",
                password: ""
            },
            repeatPassword: "",
            passwordError: false,
            modalInfo: {
                title: "",
                label: ""
            },
            modalVisible: false,
            activeModal: ""
        }
    },
    computed: {
        fullname() {
            return `${this.user.last_name} ${this.user.first_name} ${this.user.middle_name}`
        },
        reverseBirthDay() {
            let resultReverseDate;
            if(this.user) {
              const dateArray = this.user.date_birth.split("-");
              resultReverseDate = dateArray.reverse().join("-");
            }
            return resultReverseDate
        },
        chechActiveFields(){
            let info = {};
            if(this.activeModal === "email") {
                info.field = "email";
                info.value = this.newData.email
            } else if(this.activeModal === "date") {
                info.field = "date_birth";
                info.value = this.newData.bday
            } else if(this.activeModal === "password") {
                info.field = "password";
                info.value = this.newData.password
            }
            return info;
        }
    }, 
    watch: {
        user() {
            this.newData.email = this.user.email;
            this.newData.bday = this.user.date_birth;
        }
    },   
    methods: {
        showModal(modalType, title, label) {
            this.modalVisible = true;
            this.activeModal = modalType,
            this.modalInfo.title = title;
            this.modalInfo.label = label;
        },
        
        fetchUserInfo() {
            this.axios.put(`${this.$domain}/users/${this.user.crm_id}/update`, {
                [this.chechActiveFields.field]: this.chechActiveFields.value
            },
            {
                headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}` 
            }})
            .then(response => {
                this.user = response.data.data;
                this.modalVisible = false;
                localStorage.setItem("user", JSON.stringify(response.data.data));
                if(this.activeModal === "password") {
                    this.existPassword = true
                }}
            )
        },
        
        updateUserInfo() {
            if(this.chechActiveFields.field === "password") {
                let samePasswords = this.checkSamePassword();
                if(samePasswords) {
                    this.fetchUserInfo();
                    this.passwordError = false;
                } else {
                    this.passwordError = true;
                    console.log("пароли разные")
                    return
                }
            }
                this.fetchUserInfo()
        },

        checkSetPassword() {
            this.axios.post(`${this.$domain}/checkUser`, {
                phone: JSON.parse(localStorage.getItem("user")).phone
            })
            .then(response => {
                console.log(response)
                if(response.data.result === "success") {
                    this.existPassword = true;
                    localStorage.setItem("password", this.existPassword)
                } else if(response.data.result === "failed") {
                    this.existPassword = false;
                    localStorage.setItem("password", this.existPassword)
                }});
        },

        checkSamePassword() {
            return this.newData.password === this.repeatPassword? true : false;
        },
    },
    components: {
        OutlineButton, YellowButton, Modal
    }, 
    mounted() {
        this.user = JSON.parse(localStorage.getItem("user"));
        this.checkSetPassword()
    },
}

</script>
<style scoped lang="scss">
.user{
    &__title {
        margin: 0 0 15px;
        font-size: 24px;
    }
    &__info {
        display: none;
        display: grid;
        grid-template: 2fr / 1fr 6fr 1fr;
        background-color: #fff;
        color: #000;
        &-col {
            font-weight: 700;
            display: flex;
            align-items: center; 
            padding: 12px 15px;
            &:not(:last-child) {
                border-bottom: 1px solid #d9d9d9;
            }
           
            &.header{
                grid-column: 1/4;
                background-color: #f2f2f2;
                font-size: 13px;
            }
            &.name{
                display: block;
                grid-column: 1/4;
                text-align: center;
                padding-top: 15px;
                padding-bottom: 15px;
            }
            &.title{
                font-weight: 400;
                border-right: 1px solid #d9d9d9;
                & .fas {
                    margin-left: 5px;
                    margin-bottom: 2px;
                }
                & .fa-lock-open {
                    color: #aa0000;
                }
                & .fa-lock {
                    color: #1abd36;
                }
            }
            &.btn {
                display: flex;
                justify-content: center;
                border-left: 1px solid #d9d9d9;
            }
            &.double {
                grid-column: 2/4;
            }
        }
         &-btn{
            transition: .3s linear all;
        }
        &-btn:hover {
            background-color: rgb(233, 231, 231);
            transition: .3s linear all;
        }
    }
    &__btn {
        border-radius: 100px;
    }
    &__modal {
        &-title {
            font-size: 20px;
            margin: 0;
            width: 100%;
            text-align: left;
        }
        &-input {
            display: block;
            width: 100%;
            padding: 10px;
            font-size: 14px;
            border-radius: 6px;
            background-color: #fff;
            border: 1px solid #ccc;
            font-family: "GothamPro";
        }
        &-label {
            display: block;
            font-weight: 700;
            margin-bottom: 5px;
        }
        &-repeatpassword {
            display: block;
            margin-top: 18px;
        }
    }
}
.show {
    display: block;
}
.modal {
    &__footer{
        border-top: 1px solid rgb(233, 236, 239);
        padding: 16px;
        text-align: right;

    }
}
.password-error {
    display: inline-block;
    color: red;
    margin-bottom: 10px;
}

</style>
