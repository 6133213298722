<template>
  <div class="promo">
    <img
      class="promo__img"
      :src="require('@/assets/icons/logo_sheriff.png')"
      alt="Sheriff Logo"
    />
    <h1 class="promo__title">
      Переходь у додаток MySheriff та поринь у світ безпеки!
    </h1>
    <a :href="deeplink" class="promo__deeplink"
      >Перейти у додаток

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12.33 5.20401C12.541 4.99333 12.8269 4.875 13.125 4.875C13.4231 4.875 13.7091 4.99333 13.92 5.20401L20.295 11.579C20.5057 11.7899 20.624 12.0759 20.624 12.374C20.624 12.6721 20.5057 12.9581 20.295 13.169L13.92 19.544C13.7066 19.7426 13.4245 19.8508 13.1331 19.8457C12.8416 19.8407 12.5634 19.7229 12.357 19.517C12.1511 19.3106 12.0333 19.0324 12.0283 18.741C12.0232 18.4495 12.1314 18.1674 12.33 17.954L16.785 13.499L5.62501 13.499C5.32664 13.499 5.0405 13.3805 4.82952 13.1695C4.61854 12.9585 4.50001 12.6724 4.50001 12.374C4.50001 12.0756 4.61854 11.7895 4.82952 11.5785C5.0405 11.3675 5.32664 11.249 5.62501 11.249L16.785 11.249L12.33 6.79401C12.1193 6.58307 12.001 6.29714 12.001 5.99901C12.001 5.70088 12.1193 5.41495 12.33 5.20401Z"
          fill="black"
        />
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  created() {
    if (!Object.keys(this.$route.query).length) {
      this.$router.push("/");
    }
    if (this.$route.query.sos) {
      this.deeplink =
        "https://mysheriffapp.page.link/?link=https%3A%2F%2Fmy.sheriff.com.ua%2F%3Fnavigate%3Dsos_feature&apn=com.sheriff.app&isi=1589945728&ibi=com.sheriff.MySheriff&efr=1&_imcp=1";
      return;
    }
    this.deeplink = atob(this.$route.query.deeplink);
  },

  data() {
    return {
      deeplink: "",
    };
  },
};
</script>

<style scoped lang="scss">
.promo {
  padding: 0 48px;
  min-height: 100vh;
  background-color: #000;
  @media screen and (max-width: 600px) {
    padding: 0 16px;
  }
  &__img {
    display: block;
    margin: 0 auto 128px;
    padding-top: 132px;
  }
  &__title {
    text-align: center;
    margin-bottom: 184px;
    @media screen and (max-width: 600px) {
      margin-bottom: 40px;
    }
  }
  &__deeplink {
    display: flex;
    width: 295px;
    margin: 0 auto;
    height: 56px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    background-color: #fce500;
    border-radius: 48px;
    border: 1px solid transparent;
    text-decoration: none;
    color: var(--color-grey-900, #000);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.32px;
    transition: all 0.4s ease;
    svg * {
      transition: all 0.4s ease;
    }
    &:hover {
      background-color: #121212;
      border: 1px solid #fce500;
      color: #fff;
      svg * {
        fill: #fff;
      }
    }
  }
}
</style>
