<template>
  <main class="objects">
    <div class="objects__title">
      <h1 class="objects__title-value">Мої об'єкти</h1>
    </div>
    <ul class="objects__list" :class="{ 'objects__list--mobile': onMobile }">
      <li
        v-for="project in projects"
        :key="project.crm_id"
        class="objects__list-item"
      >
        <MyObject :object="project" />
      </li>
      <ConnectObject />
    </ul>
  </main>
</template>
<script>
import MyObject from "./MyObject.vue";
import ConnectObject from "./ConnectObject.vue";
export default {
  data() {
    return {
      projects: [],
    };
  },
  mounted() {
    this.projects = localStorage.getItem("projects")
      ? JSON.parse(localStorage.getItem("projects"))
      : [];
  },
  components: {
    MyObject,
    ConnectObject,
  },
};
</script>
<style scoped lang="scss">
.objects {
  &__title {
    padding: 20px 15px;
    text-align: center;
    background-color: rgba(68, 67, 67, 0.65);
    border-radius: 10px;
    margin-bottom: 15px;
    &-value {
      margin: 0;
      line-height: 1;
      font-size: 27px;
    }
  }
  &__list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: grid;
    grid-template: 1fr / 1fr 1fr 1fr;
    gap: 20px;
    &-item {
      margin: 0;
    }
    &--mobile {
      grid-template-columns: repeat(1, 1fr) !important;
    }
    @media screen and (max-width: 1535px) {
      grid-template-columns: repeat(2, 1fr);
    }
    &-link {
      color: #fff;
      text-decoration: none;
    }
  }
}
</style>