<template>
  <li class="object__modal-item">
    <div class="input">
      <input
        class="object__modal-chekbox input-checkbox"
        type="checkbox"
        v-model="techProtectionModel"
        :value="item.title"
        :checked="item.value ? true : false"
        :disabled="item.value ? true : false"
        :id="item.key"
      />
      <label :for="item.key" class="input-label">
        {{ item.title }}
      </label>
      <i
        class="fas fa-grip-lines"
        v-if="item.value === 0"
        @click="visibleProtectionItem = !visibleProtectionItem"
      ></i>
    </div>

    <p class="object__modal-descr" v-if="visibleProtectionItem">
      {{ item.desciption }}
    </p>
  </li>
</template>
<script>
export default {
  props: {
    item: Object,
    value: Array,
  },
  data() {
    return {
      visibleProtectionItem: false,
    };
  },
  computed: {
    techProtectionModel: {
      get() {
        return this.value || this.item.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.object {
  &__modal {
    &-descr {
      border: 1px solid rgba(28, 28, 28, 0.1);
      margin: 3px 20px 10px;
      padding: 5px;
    }
  }
}
.fa-grip-lines {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  // right: 20px;
  color: rgba(28, 28, 28, 0.8);
}

.input {
  position: relative;
  margin-bottom: 2px;
  &-checkbox {
    -webkit-appearance: none;
    appearance: none;
    opacity: 0;
    position: absolute;
    &:checked + .input-label:before {
        opacity: 1;
    }
    &:not(:checked) + .input-label:before {
    opacity: 0;
    }
  }
  &-label {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0;
    &:before {
      position: absolute;
      content: "✔";
      background-repeat: no-repeat;
      color: #1abd36;
      font-weight: 700;
      top: 0px;
      left: 1px;
      transition: background-image 0.1s linear;
      z-index: 10;
    }
    &::after {
      background-color: #fff;
      content: "";
      display: block;
      width: 13px;
      height: 13px;
      border: 1px solid #000;
      background-color: #fff;
      border-radius: 2px;
      position: absolute;
      top: 3px;
      left: 0;
      z-index: 1;
      transition: background-image 0.1s linear;
    }
  }
}
</style>


