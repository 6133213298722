import Vue from "vue";
import Router from "vue-router";
import Auth from "./pages/Auth";
import Main from "./pages/Main";
import Registration from "./pages/Registration";
import AddReferral from "./pages/Referral";
import Affiliate from "./pages/Affiliate";
import CustomerInstruction from "./pages/CustomerInstruction";
import Dashboard from "./components/Dashboard";
import Header from "./components/Header.vue";
import Navbar from "./components/Navbar.vue";
import User from "./components/User";
import Objects from "./components/Objects";
import Object from "./components/Object";
import PayHistory from "./components/PayHistory";
import Referral from "./components/Referral";
import Finance from "./components/Finance";
import ReferrerPayHistory from "./components/Referral/ReferrerPayHistory";
import Faq from "./components/Faq";
import Onboarding from "./components/Onboarding";
import ConnectRequest from "./components/ConnectRequest";
import Billing from "./components/Billing";
import NotFound from "./components/Errors/404.vue";
import SecurityPolicy from "./pages/SheriffPolicy/SecurityPolicy";
import FastReactionPolicy from "./pages/SheriffPolicy/FastReactionPolicy";
import PolicySOS from "./pages/SheriffPolicy/SOSPolicy";
import SuccessPayment from "./components/SuccessPayment";
import PromoServiceSos from "./pages/Promo/Services/Sos";
import Promo from "./pages/Promo";
// import AsssetLinks from "./pages/Assetlinks/";
import PromoServiceScreen from "./pages/Promo/Services/PromoScreen.vue";
import DeleteAccountGoogle from "@/pages/SheriffPolicy/PrivacyDeleteAccount.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "*",
      component: NotFound,
      name: "notfound",
    },
    {
      path: "/",
      component: Auth,
      name: "auth",
      redirect: "/login",
    },
    {
      path: "/login",
      component: Auth,
      name: "login",
    },
    {
      path: "/logout",
      component: Auth,
      name: "logout",
    },
    {
      path: "/registration",
      component: Registration,
      name: "registration",
    },
    {
      path: "/customer-instructions",
      component: CustomerInstruction,
      name: "customer-instruction",
    },
    {
      path: "/promo/services/sos",
      component: PromoServiceSos,
      name: "PromoSos",
    },
    // {
    //   path: "/promo/sos",
    //   component: PromoSos,
    //   name: "Promo-SOS",
    // },
    {
      path: "/promo",
      component: Promo,
      name: "Promo",
    },
    {
      path: "/promo/:service",
      props: true,
      component: PromoServiceScreen,
      name: "PromoServiceScreen",
    },
    {
      path: "/",
      component: Main,
      children: [
        {
          path: "dashboard",
          components: {
            default: Header,
            navbar: Navbar,
            content: Dashboard,
          },
          name: "dashboard",
        },
        {
          path: "objects",
          components: {
            default: Header,
            navbar: Navbar,
            content: Objects,
          },
          name: "objects",
        },
        {
          path: "objects/create",
          components: {
            default: Header,
            navbar: Navbar,
            content: ConnectRequest,
          },
          name: "connectobject",
        },
        {
          path: "object/:id",
          components: {
            default: Header,
            navbar: Navbar,
            content: Object,
          },
          name: "object",
        },
        {
          path: "user",
          components: {
            default: Header,
            navbar: Navbar,
            content: User,
          },
          name: "User",
        },
        {
          path: "payhistory",
          components: {
            default: Header,
            navbar: Navbar,
            content: PayHistory,
          },
          name: "payhistory",
        },
        {
          path: "referral",
          components: {
            default: Header,
            navbar: Navbar,
            content: Referral,
          },
          name: "referral",
        },
        {
          path: "finance",
          components: {
            default: Header,
            navbar: Navbar,
            content: Finance,
          },
          name: "finance",
        },
        {
          path: "referrerpayhistory",
          components: {
            default: Header,
            navbar: Navbar,
            content: ReferrerPayHistory,
          },
          name: "referrerpayhistory",
        },
        {
          path: "faq",
          components: {
            default: Header,
            navbar: Navbar,
            content: Faq,
          },
          name: "faq",
        },
        {
          path: "onboarding",
          components: {
            default: Header,
            navbar: Navbar,
            content: Onboarding,
          },
          name: "onboarding",
        },
        {
          path: "billing",
          components: {
            default: Header,
            navbar: Navbar,
            content: Billing,
          },
          name: "billing",
        },
      ],
      name: "main",
      beforeEnter: (to, from, next) => {
        localStorage.getItem("user") === null
          ? next({ path: "/login" })
          : next();
      },
    },
    {
      path: "/referral/:id",
      component: AddReferral,
      name: "addreferral",
    },
    {
      path: "/affiliate",
      component: Affiliate,
      name: "affiliate",
    },
    {
      path: "/policy",
      component: SecurityPolicy,
      name: "policy",
    },
    {
      path: "/fast-reaction-policy",
      component: FastReactionPolicy,
      name: "fastreactpolicy",
    },
    {
      path: "/sheriff-sos-policy",
      component: PolicySOS,
      name: "Sheriff SOS Policy",
    },
    {
      path: "/privacy-delete-account",
      component: DeleteAccountGoogle,
      name: "DeleteAccountReq",
    },
    {
      path: "/success-payment",
      component: SuccessPayment,
      name: "successPayment",
    },
    // {
    //   path: "/.well-known/assetlinks.json",
    //   component: AsssetLinks,
    //   name: "assetlinks",
    // },
  ],
});
