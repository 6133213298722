<template>
    <AffiliateComponent/>
    
</template>
<script>  
import AffiliateComponent from "../../components/Affiliate"
export default {
    components: { AffiliateComponent }
  

}

</script>