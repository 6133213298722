<template>
  <div id="crcl-preloader"></div>
</template>

<script>
export default {
  name: "CirclePreloader",
};
</script>

<style scoped>
#crcl-preloader {
  height: 25px;
  width: 25px;
  border: 5px solid #545454;
  border-top: 5px solid rgba(252, 229, 0, 1);
  border-radius: 50%;
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>