<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="255"
    height="34"
    viewBox="0 0 255 34"
    fill="none"
  >
    <path
      d="M242.712 19.4903L250.281 29.7328L244.775 33.6949L237.206 23.4524L229.638 33.6949L224.132 29.7328L231.701 19.4903L219.413 15.6254L221.575 9.15217L233.766 13.1143V0.456299H240.647V13.1143L252.838 9.15217L255 15.6254L242.712 19.4903Z"
      fill="#FCE500"
    />
    <path
      d="M23.299 19.4903L30.8679 29.7328L25.364 33.6949L17.7934 23.4524L10.2245 33.6949L4.71885 29.7328L12.2878 19.4903L0 15.6254L2.1623 9.15217L14.3528 13.1143V0.456299H21.2339V13.1143L33.4244 9.15217L35.5867 15.6254L23.299 19.4903Z"
      fill="#FCE500"
    />
    <path
      d="M46.0215 23.6961C46.4557 27.9706 50.8532 28.9197 53.5105 28.9197C56.1192 28.9197 60.7564 28.3974 60.7564 23.6961C60.7564 14.4846 40.6097 23.3632 40.6097 9.54606C40.6097 3.70613 46.117 -0.0938337 53.3646 0.00176289C59.9835 0.0973595 65.2495 3.42105 66.0223 8.35963L59.6448 9.16708C59.1134 5.65391 55.6832 4.98815 53.3646 4.98815C50.9488 4.98815 46.8916 5.27323 46.8916 9.54606C46.8916 17.3816 67.0366 8.92809 67.0366 23.6961C67.0366 28.0645 63.8965 34 53.5088 34C43.6525 34 40.1269 28.6824 39.6909 24.5035L46.0215 23.6961Z"
      fill="#FCE500"
    />
    <path
      d="M90.2262 0.426758H96.5064V33.6671H90.2262V19.7543H75.83V33.6671H69.5498V0.426758H75.83V14.3412H90.2262V0.426758Z"
      fill="#FCE500"
    />
    <path
      d="M123.609 5.88771H105.639V14.3412H121.146V19.7543H105.639V28.2061H123.609V33.6671H99.4053V0.426758H123.609V5.88771Z"
      fill="#FCE500"
    />
    <path
      d="M153.032 33.6671H146.171L140.52 23.3631C140.134 23.4109 139.698 23.4109 139.264 23.4109H132.404V33.6671H126.124V0.426758H139.264C147.477 0.426758 152.597 4.84298 152.597 11.9188C152.597 16.6679 150.279 20.2289 146.269 22.0333L153.032 33.6671ZM132.402 5.74602V18.0916H139.262C143.417 18.0916 145.881 16.05 145.881 11.9188C145.881 7.78769 143.418 5.74602 139.262 5.74602H132.402Z"
      fill="#FCE500"
    />
    <path
      d="M161.825 0.426758H155.545V33.6671H161.825V0.426758Z"
      fill="#FCE500"
    />
    <path
      d="M188.927 5.88771H170.955V14.3412H186.463V19.7543H170.955V33.6671H164.724V0.426758H188.927V5.88771Z"
      fill="#FCE500"
    />
    <path
      d="M215.643 5.88771H197.671V14.3412H213.18V19.7543H197.671V33.6671H191.439V0.426758H215.643V5.88771Z"
      fill="#FCE500"
    />
  </svg>
</template>

<script>
export default {};
</script>
