<template>
  <div>
    <div class="account" @click="showServices = true">
      <div class="account__body">
        <span class="account__body-title">Послуги від Sheriff</span>
        <span class="account__body-text"
          >Обирайте послугу на будь-який випадок</span
        >
      </div>
    </div>
    <Modal @clickBtn="showServices = false" :class="{ show: showServices }">
      <template v-slot:header>
        <h5 class="account__modal-title">Обрати послугу</h5>
      </template>
      <template v-slot:body>
        <form class="account__modal-form">
          <label class="account__modal-label">
            Контактні дані
          </label>
          <div class="account__modal-data">
            <span class="account__modal-name">
              <input
                type="text"
                class="account__modal-input"
                :class="{
                  'account__modal-input--valid':
                    fullName.split(' ').length === 2,
                }"
                placeholder="Ім'я"
                v-model="fullName"
              />
              <i
                v-if="fullName.split(' ').length === 2"
                class="fas fa-check"
              ></i>
            </span>
            <span class="account__modal-phone">
              <input
                type="text"
                class="account__modal-input"
                :class="{
                  'account__modal-input--valid': userInfo.phone.length === 12,
                }"
                v-mask="'38##########'"
                v-model="userInfo.phone"
                placeholder="Телефон"
              />
              <i v-if="userInfo.phone.length === 12" class="fas fa-check"></i>
            </span>
          </div>
          <label class="account__modal-label">
            Послуга
          </label>
          <select class="account__modal-select" v-model="service">
            <option disabled>Оберіть послугу</option>
            <option
              v-for="service in services"
              :key="service.id"
              :value="service"
              >{{ service.title }}</option
            >
          </select>

          <div
            class="account__modal-descr"
            v-if="service !== 'Оберіть послугу'"
          >
            <span>* {{ service.short_description }}</span>
            <b class="account__modal-descr--price">** {{ service.price }}</b>
          </div>
        </form>
        <Button class="account__modal-btn" @clickBtn="orderService"
          >Замовити</Button
        >
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '../Modals/DefaultModal.vue';
import Button from '../Buttons/YellowButton.vue';

export default {
  components: { Modal, Button },
  props: ['user', 'services'],
  created() {
    this.userInfo = { ...this.user };
  },
  data() {
    return {
      userInfo: null,
      showServices: false,
      service: 'Оберіть послугу',
      fullName: `${this.user.last_name} ${this.user.first_name}`,
    };
  },
  methods: {
    orderService() {
      this.axios
        .post(
          `${this.$domain}/services/${this.service.id}`,
          {
            user_id: this.userInfo.crm_id,
            phone: this.userInfo.phone,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.result === 'success') {
            this.showServices = false;
            this.$swal.fire({
              icon: 'success',
              html: `<span style="color: #fff;">Заявка прийнята! <br/> З Вами зв'яжеться наш менеджер</span>`,
            });
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
