export const onMobile = {
  data() {
    return {
      minWidth: 1280,
      minHeight: 720,
      deviceWidth: 0,
      deviceHeight: 0,
      onMobile: false,
    };
  },
  methods: {
    handleResize() {
      this.deviceWidth = window.screen.availWidth;
      this.deviceHeight = window.screen.availHeight;
      // console.log(this.minWidth, this.minHeight, "-", this.deviceWidth, this.deviceHeight)
      this.hideDesktopVersion();
    },
    hideDesktopVersion() {
      const policyRoute = this.$route.path === "/policy";
      const successPaymentRoute = this.$route.path === "/success-payment";
      const fastReactionPolicy = this.$route.path === "/fast-reaction-policy";
      const SOSPolicy = this.$route.path === "/sheriff-sos-policy";
      const PromocodeDeeplinkCampaign = this.$route.path === "/promo";
      const googlePrivacyDeleteAccount =
        this.$route.path === "/privacy-delete-account";
      const PromoService = this.$route.path.startsWith('/promo');
      if (
        policyRoute ||
        successPaymentRoute ||
        fastReactionPolicy ||
        SOSPolicy ||
        PromocodeDeeplinkCampaign ||
        PromoService ||
        googlePrivacyDeleteAccount
      ) {
        this.onMobile = false;
      } else {
        this.deviceWidth < this.minWidth
          ? (this.onMobile = true)
          : (this.onMobile = false);
      }
    },
  },
  // created() {
  //     window.addEventListener('resize', this.handleResize);
  // },
  mounted() {
    this.handleResize();
  },
  // destroyed() {
  //     window.removeEventListener('resize', this.handleResize);
  // },
};
