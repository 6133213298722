<template>
	<main class="object" v-if="objectInfo">
		<h1 class="object__title">
			<span>
				<i class="fas fa-map-marker-alt"></i>
				{{ objectInfo.address }}
			</span>
		</h1>
		<div class="object__tabs">
			<button v-for="(tab, i) in tabs" :key="tab.name" ref="btn" @click="selectedTab(i)" class="object__tab">
				{{ tab }}
			</button>
		</div>

		<InfoAboutObject v-if="active === 0" @techProtection="getTechProtection" @subscriptionStatus="modalVisible.subscription = true" :objectInfo="objectInfo" />
		<AlarmsByProject v-else-if="active === 1" :alarms="alarms" />
		<Modal @clickBtn="modalVisible.subscription = false" :class="{ showModal: modalVisible.subscription }" :top="20" :background="'#121212'" :borderColor="'#000'">
			<template v-slot:header>
				<h5 class="object__modal-title" style="justify-content: center;">Активна підписка</h5>
			</template>
			<template v-slot:body>
				<div class="subscription">
						<div class="card">
							<div class="card__info">
								<span>Абонплата:</span>
								<span class="card__sum">
									<i class="fas fa-hryvnia"></i>
									{{objectInfo.abonplata}}
								</span>
							</div>
							<div class="card__flex">
									<span>Номер картки</span>
									<span>{{objectInfo.cardNumber}}</span>
							</div>
					</div>
					<div class="subscription__info">
						<p class="subscription__info-about">Договір: {{objectInfo.order_id}}</p>
						<p class="subscription__info-about">Дата наступного списання: {{getReversedDate}}</p>
					</div>
				</div>
			</template>
		</Modal>
		<Modal @clickBtn="modalVisible.protection = false" :class="{ showModal: modalVisible.protection }" :top="20">
			<template v-slot:header>
				<h5 class="object__modal-title">Рівень Захищеності</h5>
			</template>
			<template v-slot:body>
				<div class="object__modal__progress">
					<progress class="object__modal-bar" max="100" :value="objectInfo.protection"></progress>
					<div class="object__modal-list">
						<h2 class="object__modal-subtitle">
							Щоб покращити рівень захищеності, потрібно:
						</h2>
						<ul class="object__modal-items">
							<TechProtectionItem 
								v-for="notProtectionItem in notCheckTechProtection"
								:key="notProtectionItem.key"
								:item="notProtectionItem"
								v-model="techProtectionOrder"
							/>
						</ul>
						<button @click="orderTechProtection" class="object__modal-btn">
							<span v-if="!orderProceeding">Замовити</span>
							<CirclePreloader v-else/>
						</button>
					</div>
					<div class="object__modal-list">
						<h2 class="object__modal-subtitle">
							Критерії, яким відповідає ваш об'єкт:
						</h2>
					</div>
					<ul class="object__modal-items">
						<TechProtectionItem 
							v-for="checkProtectionItem in checkTechProtection"
							:key="checkProtectionItem.key"
							:item="checkProtectionItem"
						/>
					</ul>
				</div>
			</template>
		</Modal>
	</main>

	<Spinner v-else />
</template>

<script>
	import InfoAboutObject from "./InfoAboutObject.vue";
	import AlarmsByProject from "./AlarmsByObject.vue";
	import Modal from "../Modals/DefaultModal.vue";
	import Spinner from "../Spinner";
	import CirclePreloader from "@/components/CirclePreloader";
	import TechProtectionItem from "./TechProtectionItem.vue";

	export default {
		components: {
			Spinner, InfoAboutObject, AlarmsByProject, Modal, TechProtectionItem, CirclePreloader
		},
		data() {
			return {
				objectId: null,
				objectInfo: null,
				tabs: ["Інформація про об'єкт", "Історія викликів"],
				alarms: [],
				active: 0,
				modalVisible: {
					protection: false,
					subscription: false,
				},
				techProtectionList: [],
				techProtectionOrder: [],
				orderProceeding: false,
			};
		},
		computed: {
			checkTechProtection () {
				const arr = this.techProtectionList.filter(item => item.value === 1);
				return arr;
			},
			notCheckTechProtection () {
				const arr = this.techProtectionList.filter(item => item.value === 0);
				return arr;
			},
			getReversedDate() {
				return this.objectInfo.subscription_next_payment_date.split('-').reverse().join('-');
			}
		},
		mounted() {
			this.objectId = this.$route.params.id;
			this.objectById(this.objectId);
			this.selectedTab();
			this.getAlarmsHistory()
			//this.levelSecurity();
		},
		updated() {
			this.selectedTab(this.active);
		},
		methods: {
			objectById (objectId) {
				this.axios.get(
					`${this.$domain}/projects/${objectId}`,
					{
						headers: {
							"Authorization": `Bearer ${localStorage.getItem("token")}`
						}
					}
				)
				.then(response => {
					this.objectInfo = response.data.project;
				})
				.catch(e => console.log(e.message));
			},
			selectedTab (idx = 0) {
				this.active = idx;
				const btns = this.$refs.btn;
				btns?.forEach((btn, i) =>
					i === idx ? btn.classList.add("show") : btn.classList.remove("show")
				);
			},
			getAlarmsHistory () {
				const month = new Date().getMonth() + 1;
				const year = new Date().getFullYear()
				const day = new Date().getDate()
				const requestDate = `${year}-${month}-${day}`
				this.axios.get(
					`${this.$domain}/projects/${this.objectId}/alarms/?cdate=2021-09-06&cdateto=${requestDate}`,
					{
						headers: {
							"Authorization": `Bearer ${localStorage.getItem("token")}`
						}
					}
				)
				.then(resp => {
					console.log(resp.data.data);
					this.alarms = resp.data.data;
				})
				.catch(e => console.log(e.message));
			},
			getTechProtection () {
				this.modalVisible.protection = true;

				this.axios.get(
					`${this.$domain}/projects/${this.objectId}/techProtection`,
					{
						headers: {
							"Authorization": `Bearer ${localStorage.getItem("token")}`
						}
					}
				)
				.then(resp => {
					console.log(resp.data.data);
					this.techProtectionList = resp.data.data;
				});
			},
			orderTechProtection() {
				if(!this.techProtectionOrder.length){
					this.$swal({
						icon: 'info',
						allowOutsideClick: true,
						title: '<span style="color: #fce500">Для замовлення оберіть бажаючий рівень захисту</span>',
						confirmButtonText: 'Зрозуміло!'
					});
					return;
				}
				this.orderProceeding = true;
				this.axios.post(`${this.$domain}/projects/${this.objectId}/orderTechProtection`, {
					project_id: this.objectId,
					order: this.techProtectionOrder
				}).then(({data: {result, data: {message}}}) => {
					if(result === 'success') {
					this.modalVisible.protection = this.orderProceeding = false;
					this.$swal({
						icon: 'info',
						allowOutsideClick: true,
						title: `<span style="color: #fce500">${message}</span>`,
						confirmButtonText: 'Зрозуміло!'
					});
					}
				}).catch(() => {
					this.$swal({
						icon: 'error',
						color: 'red',
						title: `<span style="color: #fce500">Щось пішло не так, спробуйте пізніше</span>`,
					});
					this.orderProceeding = false;
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	$dark: rgb(28, 28, 28);

	.object {
		&__title {
			font-size: 24px;
			margin: 0 0 20px;
			padding-bottom: 18px;
			border-bottom: 1px solid #e6e6e6;
			text-align: center;

			& span {
				display: inline-block;
				position: relative;
				padding-left: 30px;
			}
		}
		&__tabs {
			display: flex;
			margin-bottom: 10px;
		}
		&__tab {
			font-family: "GothamPro";
			border: none;
			border-radius: 6px;
			background-color: transparent;
			color: #fff;
			padding: 8px 16px;
			cursor: pointer;

			&:first-child {
				margin-right: 10px;
			}
		}
		&__modal {
			&-title {
				font-size: 20px;
				margin: 0;
			}
			&-subtitle {
				margin: 0 0 5px 20px;
			}
			&-bar {
				margin: 0 auto;
				display: block;
				width: 90%;
				border-radius: 8px;
				padding: 0;
				border: 0;
				text-align: center;
				height: 20px;
				box-shadow: 1px 2px rgb(0 0 0 / 30%) inset, 0 0 0 1px rgb(0 0 0 / 50%);
				overflow: hidden;
				margin-bottom: 20px;
			}
			&-items {
				list-style-type: none;
				margin: 0;
				padding: 0;
			}
			&-btn {
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid #000;
				background-color: #1c1c1c;
				font-size: 1.4rem;
				border-radius: 5px;
				margin: 20px;
				padding: 10px 20px;
				color: #fbe301;
				width: 94%;
				cursor: pointer;
				transition: all 0.5s ease;
			}
		}
	}
	.show {
		background-color: rgb(26, 26, 26);
		color: #f5e41c;
	}
	.showModal {
		display: block;
	}
	progress::-moz-progress-bar {
		background-color: #f5e41c;
		border-radius: 8px;
	}
	progress::-webkit-progress-bar {
		background-color: #000;
	}
	progress::-webkit-progress-value {
		background-color: #f5e41c;
		border-radius: 8px;
	}
	.card {
		margin-bottom: 24px;
		background-image: linear-gradient( #88812e 36%, #ffeb00 146%);
		border-radius: 10px;
		padding: 28px 20px;
		cursor: pointer;
		max-width: 290px;
		width: 100%;
		&__info {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 16px;
			margin-bottom: 40px;
			color: #fff;
		}
		&__flex {
			display: flex;
			flex-direction: column;
			font-size: 16px;
			color: #fce500;
		}
	}

	.subscription {
		display: flex;
		align-items: center;
		flex-direction: column;
		max-width: 350px;
		width: 100%;
		margin: 0 auto;
		&__info {
			color: #fff;
			&-about {
				margin: 0;
				text-align: center;
				font-size: 16px;
				&:first-child {
					margin-bottom: 15px;
				}
			}
		}
	}
</style>