<template>
    <div class="connect__item">
        <div class="connect__content">
            <span class="connect__content-title">Підключення охорони</span>
                <span class="connect__content-icon">
                    <i class="fas fa-home"></i>
                </span>
                <div class="connect__content-date">
                    <i class="far fa-clock"></i>
                    <span class="connect__content-create">Дата створення: {{createdDate}}</span> 
                </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["object"],
    computed: {
        createdDate() {
            const date = this.object.created_at.slice(0, 19).replace("T", " ")
            return date
        }
    }
}
</script>

<style scoped lang="scss">
.connect {
    &__item {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.8) 100%), url("../../assets/img/card-installer.jpg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        border-radius: 6px;
        padding: 100px 15px 15px;
    }
    &__content {
        display: grid;
        grid-template: repeat(2, 1fr) / 37px 1fr;
        gap: 2px 10px;
        &-title {
            font-weight: 600;
            grid-column: 2/3;
            grid-row: 1/2;
        }
        &-icon {
            background: linear-gradient(rgb(131, 62, 245) 0px, rgb(90, 11, 220) 100%);
            padding: 8px;
            border-radius: 6px;
            grid-column: 1/2;
            grid-row: 1/3;
            display: inline-block;
            & i {
                font-size: 18px;
            }
        }
        &-date {
            color:rgb(179, 179, 179);
            grid-row: 2/3;
            grid-column: 2/3;
        }
        &-create {
            margin-left: 5px;

        }
    }
}
</style>