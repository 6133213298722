import { render, staticRenderFns } from "./Sos.vue?vue&type=template&id=63716d69&scoped=true&"
import script from "./Sos.vue?vue&type=script&lang=js&"
export * from "./Sos.vue?vue&type=script&lang=js&"
import style0 from "./Sos.vue?vue&type=style&index=0&id=63716d69&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63716d69",
  null
  
)

export default component.exports