<template>
  <header class="header">
    <div class="header__logo">
      <router-link :to="{ path: '/dashboard' }">
        <img
          class="header__logo-img"
          src="@/assets/icons/logo-main.png"
          alt=""
        />
      </router-link>
    </div>
    <div class="header__user" @click="menuVisible = !menuVisible">
      <div class="header__user-photo">
        <span class="header__user-vip" v-if="user.vip">VIP</span>
      </div>
      <span class="header__user-name"
        >{{ user.first_name }} {{ user.last_name }}
        <i class="fas fa-caret-down"></i>
      </span>

      <div class="header__menu" :class="{ show: menuVisible }">
        <ul class="header__menu-list">
          <li class="header__menu-item" :class="{'header__menu-item--mobile': onMobile}">
            <router-link to="/user" class="header__menu-link"
              >Редагувати особисті дані
              </router-link> 
          </li>
          <li class="header__menu-item"> 
            <span class="header__menu-link" @click="logout">Вихід</span>
        </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      user: "",
      menuVisible: false,
    };
  },
  methods: {
      logout() {
        localStorage.clear();
        this.$router.push({ path: "/login" });
      }
  },
  mounted() {
    this.user = localStorage.getItem("user")!== null ? JSON.parse(localStorage.getItem("user")) : "";
  },
};
</script>


<style scoped lang="scss">
.header {
  padding: 10px 10px;
  background-color: #1a1a1a;
  grid-column: 1/3;
  grid-row: 1/2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__logo {
    width: 113px;
    &-img {
      width: 100%;
    }
  }
  &__user {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    &-name {
        & i {
           position: relative;
           top: 2px;
        }
    }
    &-photo {
    position: relative;
    margin-right: 5px; 
    display: block;  
    min-width: 36px;
    min-height: 36px;
    &:after {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #1abd36;
        bottom: 0;
        right: 3px;
        }
    }
    &-vip {
        display: block;
        border-radius: 4px;
        background-color: rgb(251, 227, 1); 
        color: #000;
        font-weight: 700;
        padding: 4px 8px;
        position: relative;
        top: 8px;
    }
  }
  &__menu {
    display: none;
    width: 200px;
    position: absolute;
    top: 40px;
    right: 0;
    background-color: #fff;
    box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 15%);
    text-align: center;
    border-radius: 8px; 
    z-index: 100;
    &-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    &-item {
    padding: 0 10px;
       &:first-child {
         border-bottom: 1px solid rgb(230, 230, 230); 
      } 
      &--mobile {
        display: none;
      }
    }
    &-link {
      display: block;
      text-decoration: none;
      color: #000;
      line-height: 40px;
      font-size: 13px;
    }
  }
}
.show {
  display: block;
}
</style>