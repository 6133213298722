<template>
    <nav class="nav">
      <ul class="nav__list">
        <li class="nav__list-section">
          <router-link to="/referral" class="nav__link referral">
          <i class="fas fa-star"></i>
          Отримай 500 грн за&nbsp;<span class="click">1&nbsp;клік</span>
          </router-link>
        </li>

        <li class="nav__list-section">
          <span class="nav__list-subheader">Навігація</span>
          <router-link to="/dashboard" class="nav__link main">
          <span class="bg"> <i class="fa fa-th-large"></i></span>
          На головну</router-link>
        </li>

        <li class="nav__list-section">
          <span class="nav__list-subheader">Послуги</span>
          <span class="nav__link signaling" @click="sublistVisible = !sublistVisible">
            <img class="nav__link-img" src="../assets/icons/menu-item/question.png" alt=""
          >Охоронна сигналізація
          <i class="fas fa-caret-down"></i>
          </span>
          <ul class="nav__sublist" :class="{show: sublistVisible}">
            <li class="nav__sublist-item nav__sublist-link" @click="modalVisible = true"> 
           + Підключити об'єкт
            </li>
            <li class="nav__sublist-item">  
              <router-link to="/objects" class="nav__sublist-link">
                Мої об'єкти
             </router-link>
             </li>
          </ul>
        </li>

        <li class="nav__list-section">
          <span class="nav__list-subheader">Оплата</span>
        <router-link to="/billing" class="nav__link finance">
          <img class="nav__link-img" src="../assets/icons/menu-item/billing-bank.png" alt="">
        Сплатити послуги
        </router-link>
        <router-link to="/payhistory" class="nav__link payhistory">
          <img class="nav__link-img" src="../assets/icons/menu-item/billing-check.png" alt="">
        Історія взаєморозрахунків
        </router-link>
        <router-link to="/finance" class="nav__link finance">
          <img class="nav__link-img" src="../assets/icons/menu-item/billing-bank.png" alt="">
        Рахунки на оплату
        </router-link>
        </li>   
        
        <li class="nav__list-section">
        <span class="nav__list-subheader">Мій профіль</span>
       <router-link to="/user" class="nav__link">
        <img class="nav__link-img" src="../assets/icons/menu-item/contacts.png" alt="">
        Редагувати
      </router-link>       
      </li>

      <li class="nav__list-section">
        <span class="nav__list-subheader">FAQ / Часті питання</span>
         <router-link to="/faq" class="nav__link faq">
        <img class="nav__link-img" src="../assets/icons/menu-item/question.png" alt="">
      Часті питання
      </router-link>
      </li>

      <li class="nav__list-section">
        <span class="nav__list-subheader">Комунікації</span>
        <a href="https://t.me/SheriffOnline_bot?start=NTU1OTM" target="_blank" class="nav__link">
          <i class="fab fa-telegram-plane"></i>
          Telegram
        </a>
        <a href="viber://pa?chatURI=ua_sheriff_bot" target="_blank"  class="nav__link">
          <i class="fab fa-viber"></i>
         Viber
        </a>
      </li>
      <li class="nav__copy">
        &copy; 2021 Sheriff All Rights Reserved
      </li>
      </ul>   
      <ConnectModal :modalVisible="modalVisible"
        @hideModal="modalVisible = false"
        :leadInfo="leadInfo"
        :cities="cities"
        :objects="objects"
        @connectNewObject="connectNewObject(leadInfo.city, leadInfo.objectType)"
      />
    </nav>
</template>
<script>
import ConnectModal from "./Objects/ConnectModal.vue";
export default {
  data() {
    return {
     userId: "",
      modalVisible: false, 
      sublistVisible: false,
      leadInfo: {
        name: "",
        phone: "",
        city: "Оберіть місто",
        objectType: "Оберіть тип власності"
        },
      cities: ["Київ", "Рівне", "Мелітополь", "Львів", "Інші"],
      objects: ["Приватна власність", "Комерційна власність", "Інший тип власності"]
    }
  },
  methods: {
        connectNewObject(city, type_object) {
            if(this.leadInfo.city !== "Оберіть місто" && this.leadInfo.objectType !== "Оберіть тип власності") {
                this.axios.post(`${this.$domain}/users/${this.userId}/leads`, {
                    city, type_object
            },
            {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}` 
            }})
            .then(response => {
              if(response.data.result === "success") {
                this.$router.push("/objects/create");
                this.modalVisible = false;
              }})
            .catch(e => console.log(e.message))
            }
        }
  },
  mounted() {
    this.userId = JSON.parse(localStorage.getItem("user"))?.crm_id;
    this.leadInfo.name = JSON.parse(localStorage.getItem("user"))?.first_name;
    this.leadInfo.phone = JSON.parse(localStorage.getItem("user"))?.phone
  },
  components: { ConnectModal}
}
</script>


<style lang="scss">
.nav {
  background-color: #1a1a1a;
  min-height: 93.7vh;
  grid-column: 1/2;
  grid-row: 2/3;
  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    &-section {
      padding-bottom: 15px;
      padding-top: 10px;
      border-bottom: 1px solid rgb(64, 64, 64);
      &:first-child {
        padding-bottom: 10px;
        padding-top: 5px;
      }
    }
    &-subheader {
      display: block;
      color: rgb(128, 128, 128);
      font-size: 15px;
      font-weight: 700;
      padding-left: 15px;
      margin-bottom: 10px;
    }
  }
  &__sublist {
    display: none;
    list-style-type: none;
    padding: 0 0 0 30px;
    margin: 0;
    &-link {
      padding: 5px 15px;
      color: rgb(205, 205, 205);
      text-decoration: none;
    // margin-bottom: 5px;
    line-height: 18px;
    font-size: 13px;
    cursor: pointer;
     &:hover  {
      color: #fff;
    }
    }
  }
  &__link {
    display: flex;  
    align-items: center;
    padding: 5px 11px;
    color: rgb(205, 205, 205);
    text-decoration: none;
    line-height: 18px;
    position: relative;
    font-size: 13px;
    cursor: pointer;
    &:hover  {
      color: #fff;
    }
    &.referral {
      padding-left: 45px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    & .fa-caret-down {
      margin-left: 26px;
    }
    & .fa-star {
        position: absolute;
        color: rgb(255, 149, 0);
        left: 20px;
        font-size: 16px;
    }
    .fa-th-large {
      color:#e8d00f;
      position: absolute;
      top: 4px;
      left: 4px;
      // left: 20px;
      font-size: 18px;
    }   
    & .fa-telegram-plane {
      padding: 4px 5px; 
      font-size: 15px;
      margin-right: 5px;
      border-radius: 6px;
      background-image: linear-gradient(rgb(51, 149, 255) 0px, rgb(0, 110, 230) 100%);
    }
    & .fa-viber {
      padding: 4px 5px; 
      font-size: 15px;
      margin-right: 5px;
      border-radius: 6px;
      background-image: linear-gradient(rgb(173, 56, 255) 0px, rgb(138, 0, 235) 100%)
    }
    & .fa-headset {
      padding: 4px 5px; 
      font-size: 15px;
      margin-right: 5px;
      border-radius: 6px;
      background-image: linear-gradient(rgb(255, 170, 51) 0px, rgb(230, 134, 0) 100%)
    }
    & .click {
        font-style: italic;
    } 
    &-img {
      width: 28px;
      height: 28px;
      margin-right: 5px;
    }
  }
  &__copy {
    font-size: 12px;
    padding: 15px 11px;
  }
}
.bg {
  background-color: #000;
  display: block;
  width: 28px;
  height: 28px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #e8d00f;
  margin-right: 5px;
}
.nav__link.router-link-active {
    background-color: #000;
}
.show {
  display: block;
}
</style>