<template>
  <main class="billing">
    <div class="billing__title">
      <h1 class="billing__title-value">Сплата послуг</h1>
    </div>
    <div class="billing__grid">
      <div class="billing__info">
        <div class="billing__info-header">
          <h3>Довідка</h3>
        </div>
        <div class="billing__info-body">
          <p class="billing__info-text">
            Сплачуйте за послуги швидко та зручно – скористайтеся сервісом
            онлайн оплати.
          </p>
          <p class="billing__info-text">
            Оплату можливо здійснити карткою будь-якого українського банку.
          </p>
          <span class="billing__info-payment">Для сплати</span>
          <ul class="billing__info-list">
            <li>Натисніть «Сплатити онлайн.</li>
            <li>
              На сторінці сервісу онлайн оплати введіть свій фінансовий номер
              телефону.
            </li>
            <li>
              Оберіть потрібний об’єкт, суму платежу та заповніть дані
              банківської карти.
            </li>
            <li>Натисніть «Сплатити» та підтвердіть оплату.</li>
            <li>Кошти будуть зараховані протягом 30 хвилин.</li>
          </ul>
        </div>
      </div>
      <div class="billing__widgets">
        <div class="billing__widgets-balance">
          <span>Баланс</span>
          <span class="billing__widgets-value">{{ user.balance }} грн</span>
          <i class="fas fa-money-bill-wave-alt"></i>
        </div>
        <Payment :phone="user.phone" />
        <Account
          :namelast="user.last_name"
          :name="user.first_name"
          :phone="user.phone"
          :objects="projects"
          @addBill="getBillByProject"
        />
      </div>
    </div>
  </main>
</template>
<script>
import Payment from "../Dashboard/Payment";
import Account from "../Dashboard/Account";
export default {
  data() {
    return {
      user: "",
      projects: [],
      bills: [],
      newBill: {
        id: "",
        name: "",
        path: "",
      },
    };
  },
  methods: {
    getBillByProject(id) {
      this.axios
        .get(`${this.$domain}/projects/${id}/bill`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => this.addNewBill(response.data))
        .catch((e) => console.log(e.message));
    },
    addNewBill(data) {
      this.bills.unshift({
        id: Date.now(),
        filename: data.filename,
        filepath: data.filepath,
      });
      localStorage.setItem("bills", JSON.stringify(this.bills));
      this.$router.push("/finance");
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.projects = JSON.parse(localStorage.getItem("projects"));
    this.bills = JSON.parse(localStorage.getItem("bills"));
  },
  components: { Payment, Account },
};
</script>
<style scoped lang="scss">
.billing {
  &__title {
    padding: 20px 15px;
    text-align: center;
    background-color: rgba(68, 67, 67, 0.65);
    border-radius: 10px;
    margin-bottom: 15px;
    &-value {
      margin: 0;
      line-height: 1;
      font-size: 27px;
    }
  }
  &__grid {
    display: flex;
    justify-content: space-between;
  }
  &__info {
    width: 49.5%;
    border-radius: 6px;
    background-color: #fff;
    min-height: 225px;
    height: 100%;
    &-header {
      background-color: rgb(26, 26, 26);
      color: #fff;
      border-radius: 6px 6px 0 0;
      text-align: center;
      padding: 12px 15px 15px;
      & h3 {
        margin: 0;
      }
    }
    &-body {
      padding: 15px;
      color: #000;
    }
    &-text {
      margin: 0;
      text-align: center;
      line-height: 1.3;
    }
    &-payment {
      font-weight: 700;
      display: inline-block;
      margin-top: 15px;
      margin-bottom: 5px;
      padding-left: 15px;
    }
    &-list {
      margin: 0;
      padding-left: 20px;
    }
  }
  &__widgets {
    width: 49.5%;
    background-color: #fff;
    border-radius: 6px;
    padding: 15px 15px 0;
    &-balance {
      padding: 15px;
      background-color: rgb(117, 175, 38);
      margin-bottom: 15px;
      border-radius: 6px;
      display: grid;
      grid-template: 14px 1fr / 1fr 1fr;
      row-gap: 5px;
      & .fa-money-bill-wave-alt {
        color: rgba(255, 255, 255, 0.5);
        font-size: 56px;
        grid-row: 1/3;
        grid-column: 2/3;
        justify-self: end;
      }
    }
    &-value {
      font-size: 28px;
      font-weight: 700;
    }
  }
}
</style>
