<template>
  <div class="objects" v-if="projects.length > 0">
    <div class="objects__inner">
      <router-link to="/objects" class="objects__title">
        Мої об'єкти ({{ projects.length }})
        <i class="fas fa-angle-right"></i>
      </router-link>
      <ul class="objects__list">
        <li class="object" v-for="project in projects" :key="project.order_id">
          <router-link
            :to="{ name: 'object', params: { id: project.contract_docnumber } }"
            class="object__info"
          >
            <div class="object__address">
              <span class="object__number"
                >Об'єкт № {{ project.pult_number }}</span
              >
              <span class="object__street">{{ project.address }}</span>
            </div>
            <button class="object__btn">Переглянути</button>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: ["projects"],
  mounted() {
    // console.log(this.projects)
  },
};
</script>
<style scoped lang="scss">
.objects {
  padding: 15px 15px 25px;
  background-color: #fff;
  border-radius: 6px;
  color: #000;
  margin-bottom: 15px;
  &__title {
    display: block;
    margin: 0 0 20px;
    color: #000;
    text-decoration: none;
    font-weight: 700;
    position: relative;
    & .fa-angle-right {
      position: absolute;
      color: #e6e6e6;
      top: -2px;
      right: 0;
      font-size: 18px;
    }
  }
  &__img {
    width: 36px;
    & img {
      max-width: 100%;
    }
  }
  &__list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
}
.object {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: #000;
    @media screen and (max-width: 1280px) {
      flex-direction: column;
      height: 88px;
      
    }
  }
  &__address {
    padding-left: 45px;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      background-image: url("../../assets/icons/menu-item/object.png");
      background-size: 36px 36px;
      background-repeat: no-repeat;
      width: 35px;
      height: 36px;
      top: calc(50% - 18px);
      left: 0;
    }
  }
  &__number {
    display: block;
    font-size: 12px;
  }
  &__street {
    font-weight: 700;
  }
  &__btn {
    padding: 5px;
    border: 1px solid #007aff;
    background-color: #fff;
    color: #007aff;
    border-radius: 3px;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>